<template>
    <div>
        <Header/>
        <div class="inner">
            <div class="box">
                <p class="title">VD Studio</p>
                <div class="form">
                    <p class="write-title">이메일</p>
                    <input class = "write" type="text" v-model="email" placeholder="vd@gmail.com">
                </div>
                <div>
                    <p class="write-title">비밀번호 <span>(8자리 이상, 영문+숫자 조합)</span></p>
                    <input class="write" type="password" v-model="password" placeholder="********"/>
                </div>
                <div class="wrapper">
                    <router-link to="#" class="forget" @click="openModal">비밀번호를 잊으셨나요?</router-link>
                    <ModalrestPassword v-if="isModalOpen" @closeModal="closeModal" :isModalOpen="isModalOpen"/>
                    <button class="loginBtn" @click="login" :disabled="loading">
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        로그인
                    </button>
                </div>
                <div class="container">
                    <hr><span>또는</span><hr>
                </div>
                <div class="move">
                    아직 회원이 아니신가요? &nbsp;
                    <router-link to="/signup" style="color: #0041D6;">1분만에 회원가입</router-link>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import Header from '../commons/Header.vue';
import Footer from '../commons/Footer.vue';
import ModalrestPassword from './ModalrestPassword.vue';
import { useRouter } from 'vue-router';

export default {
    name: 'Login',
    components: {
        Header,
        Footer,
        ModalrestPassword,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const email = ref('');
        const password = ref('');
        const isModalOpen = ref(false);
        const loading = ref(false);

        const openModal = () => {
            isModalOpen.value = true;
            document.body.style.overflow = 'hidden'; // 비밀번호 재설정창이 열릴 때 페이지 스크롤 방지
        };
        const closeModal = ()=>{
            isModalOpen.value = false;
            document.body.style.overflow = '';
        }

        const login = async () => {
            loading.value = true;
            try {
                    await store.dispatch('signin', {
                        email: email.value,
                        password: password.value,
                });

                if (!store.state.error) {
                    router.push('/');
                } else {
                    alert(store.state.error);
                }
            } catch (error) {
                // 예외 상황이 발생했을 때 처리
                alert('로그인 요청 중 문제가 발생했습니다.');
                console.error(error);
            } finally{
                loading.value = false;
            }
        };
        

        return {
            email,
            password,
            isModalOpen,
            openModal,
            closeModal,
            login,
            loading,
        };
    }
}
</script>

<style scoped>
.inner{
    position: relative;
    width: 100%;
    height : 900px;
}
.box{
    width: 450px;
    height: 600px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    margin-top : 100px;
    position : relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.title{
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
.form{
    margin: 0 auto;
}
.write{
    width: 326px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    margin-bottom: 20px;
    padding-left: 10px;
}
.write::placeholder{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-40%);
}
.write-title{
    margin: 0;
}
.write-title span{
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.loginBtn{
    width: 326px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--vdcolor);;
    color: white;
    border: none;
}
.move{
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
}
.container hr{
    width: 138px;
    height: 1px;
    background-color: #C8C8C8;
    margin:0 10px;
}
.container span {
    margin: 0 5px;
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.wrapper{
    position: relative;
    margin-top: 30px;
}

.forget{
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    position: absolute;
    top: -18px;
    right: 0;
}
</style>