import { createStore } from 'vuex';
import axios from 'axios';

function deleteCookie(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

const store = createStore({
    state() {
        return {
            user: null,
            isAuthenticated: false,
            error: null,
        };
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
            state.isAuthenticated = true;
        },
        SET_ERROR(state, error) {
            state.error = error;
        },
        LOGOUT(state) {
            state.user = null;
            state.isAuthenticated = false;
        },
    },
    actions: {
        async signupAndSignin({ commit }, userDetails) {
            try {
                const signupResponse = await axios.post('/api/signup', userDetails, { withCredentials: true });
                if (signupResponse.status === 200) {
                    const signinResponse = await axios.post('/api/signin', {
                        email: userDetails.email,
                        password: userDetails.password,
                    }, { withCredentials: true });
                    if (signinResponse.status === 200) {
                        commit('SET_USER', signinResponse.data.user);
                        commit('SET_ERROR', null);
                        return signupResponse.data; // 회원가입 성공 메시지 반환
                    }
                }
            } catch (err) {
                if (err.response) {
                    commit('SET_ERROR', err.response.data.error);
                    throw new Error(err.response.data.error);
                } else {
                    commit('SET_ERROR', '회원가입 및 로그인 요청 중 문제가 발생했습니다.');
                    throw new Error('회원가입 및 로그인 요청 중 문제가 발생했습니다.');
                }
            }
        },
        async signin({ commit }, credentials) {
            try {
                const response = await axios.post('/api/signin', credentials, { withCredentials: true });
                if (response.status === 200) {
                    commit('SET_USER', response.data.user);
                    commit('SET_ERROR', null); // 성공 시 에러 초기화
                }
            } catch (err) {
                if (err.response) {
                    commit('SET_ERROR', err.response.data.error);
                } else {
                    commit('SET_ERROR', '로그인 요청 중 문제가 발생했습니다.');
                }
            }
        },
        async logout({ commit }) {
            try {
                const response = await axios.post('/api/logout', {}, { withCredentials: true });
                if (response.status === 200) {
                    commit('LOGOUT');
                    deleteCookie('connect.sid'); // 세션 쿠키 삭제
                }
            } catch (err) {
                commit('SET_ERROR', '로그아웃 요청 중 문제가 발생했습니다.');
            }
        },
        async checkAuth({ commit }) {
            try {
                const response = await axios.get('/api/check-auth', { withCredentials: true });
                if (response.status === 200 && response.data.user) {
                    commit('SET_USER', response.data.user);
                } else {
                    commit('LOGOUT');
                }
            } catch (err) {
                console.log('인증 상태 확인 중 오류 발생: ', err);
                commit('LOGOUT');
            }
        }
    },
    getters: {
        isAuthenticated: (state) => state.isAuthenticated,
        user: (state) => state.user,
        error: (state) => state.error,
    },
});

export default store;