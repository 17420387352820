export const extractTimeAsNumber = () => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  // 시간을 밀리초 단위로 변환하여 합산
  const timeAsNumber =
    hours * 3600000 + minutes * 60000 + seconds * 1000 + milliseconds;

  return timeAsNumber;
};

export const date2YYYYMMDD = (isoString) => {
  const date = new Date(isoString);

  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const imageSrc2Name = (str) => {
  const split = str.split("/");
  const last = split[split.length - 1];
  const name = last.slice(9, last.length);

  try {
    return decodeURIComponent(name);
  } catch (e) {
    console.error('Invalid URI component:', name);
    return name; // 오류가 발생하면 원본 이름을 반환하거나 적절한 기본값을 반환합니다.
  }
};
