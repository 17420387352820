<template>
  <div class="layout">
    <div class="header">
      <router-link class="logo" to="/">VD studio</router-link>
      <button class="exitBtn" @click="showModal = true">나가기</button>
    </div>

    <div class="contents">
      <div class="main">
        <p class="title">작성한 내용을 다시 <b>한 번 더</b> 확인해주세요</p>
        <div class="form">
          <div
            style="
              display: flex;
              flex-direction: column;
              gap: 40px;
              width: 100%;
            "
          >
            <div class="box">
              <p class="box-title">원하는 영상 스타일</p>
              <div class="form-list">
                <div class="form-item">
                  <span class="name">제작 목적</span>
                  <span
                    class="value"
                    :class="!form_data.purpose && 'disabled'"
                    >{{ form_data.purpose || "필수 항목을 입력해주세요" }}</span
                  >

                  <img
                    src="@/assets/icons/estimate/pencil.png"
                    @click="router.push('/estimate/step1')"
                  />
                </div>
                <div class="form-item">
                  <span class="name">영상 스타일</span>
                  <span
                    class="value"
                    :class="!form_data.style.length && 'disabled'"
                    >{{
                      form_data.style.join(", ") || "필수 항목을 입력해주세요"
                    }}</span
                  >

                  <img
                    src="@/assets/icons/estimate/pencil.png"
                    @click="router.push('/estimate/step2')"
                  />
                </div>
                <div class="form-item">
                  <span class="name">분야</span>
                  <span class="value" :class="!form_data.field && 'disabled'">{{
                    form_data.field || "필수 항목을 입력해주세요"
                  }}</span>

                  <img
                    src="@/assets/icons/estimate/pencil.png"
                    @click="router.push('/estimate/step3')"
                  />
                </div>
                <div class="form-item">
                  <span class="name">작업 범위</span>
                  <span
                    class="value"
                    :class="
                      !form_data.work_scope?.length &&
                      form_data.work_scope !== null &&
                      'disabled'
                    "
                    >{{
                      form_data.work_scope === null
                        ? "필요한 작업 없어요"
                        : form_data.work_scope?.join(", ") ||
                          "필수 항목을 입력해주세요"
                    }}</span
                  >

                  <img
                    src="@/assets/icons/estimate/pencil.png"
                    @click="router.push('/estimate/step4')"
                  />
                </div>
              </div>
            </div>

            <div class="box">
              <p class="box-title">의뢰 조건</p>
              <div class="form-list">
                <div class="form-item">
                  <span class="name">희망 납품 일자</span>
                  <span
                    class="value"
                    :class="!form_data.deadline && 'disabled'"
                    >{{
                      deadline_list.includes(form_data.deadline)
                        ? form_data.deadline
                        : form_data.deadline
                        ? date2YYYYMMDD(form_data.deadline)
                        : "필수 항목을 입력해주세요"
                    }}</span
                  >

                  <img
                    src="@/assets/icons/estimate/pencil.png"
                    @click="router.push('/estimate/step5')"
                  />
                </div>
                <div class="form-item">
                  <span class="name">편수</span>
                  <span
                    class="value"
                    :class="!form_data.episode_count && 'disabled'"
                    >{{
                      `${form_data.episode_count}편` ||
                      "필수 항목을 입력해주세요"
                    }}</span
                  >

                  <img
                    src="@/assets/icons/estimate/pencil.png"
                    @click="router.push('/estimate/step5')"
                  />
                </div>
                <div class="form-item">
                  <span class="name">영상 길이</span>
                  <span
                    class="value"
                    :class="!form_data.video_length && 'disabled'"
                    >{{
                      form_data.video_length || "필수 항목을 입력해주세요"
                    }}</span
                  >

                  <img
                    src="@/assets/icons/estimate/pencil.png"
                    @click="router.push('/estimate/step5')"
                  />
                </div>
                <div class="form-item">
                  <span class="name">예산 범위</span>
                  <span
                    class="value"
                    :class="!form_data.budget && 'disabled'"
                    >{{ form_data.budget || "필수 항목을 입력해주세요" }}</span
                  >

                  <img
                    src="@/assets/icons/estimate/pencil.png"
                    @click="router.push('/estimate/step6')"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="box">
            <p class="box-title">상세 정보</p>
            <div class="form-list">
              <div class="form-item">
                <span class="name">프로젝트 제목</span>
                <span
                  class="value"
                  :class="!form_data.project_title && 'disabled'"
                  >{{
                    form_data.project_title || "필수 항목을 입력해주세요"
                  }}</span
                >

                <img
                  src="@/assets/icons/estimate/pencil.png"
                  @click="router.push('/estimate/step7')"
                />
              </div>
              <div class="form-item">
                <span class="name">회사명</span>
                <span
                  class="value"
                  :class="!form_data.company_name && 'disabled'"
                  >{{
                    form_data.company_name || "필수 항목을 입력해주세요"
                  }}</span
                >

                <img
                  src="@/assets/icons/estimate/pencil.png"
                  @click="router.push('/estimate/step7')"
                />
              </div>
              <div class="form-item">
                <span class="name">참고 영상</span>
                <span
                  class="value"
                  :class="!form_data.reference.length && 'disabled'"
                  >{{
                    form_data.reference.join("\n") || "필수 항목을 입력해주세요"
                  }}</span
                >

                <img
                  src="@/assets/icons/estimate/pencil.png"
                  @click="router.push('/estimate/step7')"
                />
              </div>
              <div class="form-item">
                <span class="name">상세 내용</span>
                <span class="value detail" :class="!form_data.detail && 'disabled'">{{
                  form_data.detail || "상세 내용이 없습니다"
                }}</span>

                <img
                  src="@/assets/icons/estimate/pencil.png"
                  @click="router.push('/estimate/step7')"
                />
              </div>
              <div class="form-item">
                <span class="name">파일 첨부</span>
                <span class="value file-link" :class="!form_data.file && 'disabled'">{{
                  form_data.file
                    ? imageSrc2Name(form_data.file)
                    : "첨부된 파일이 없습니다"
                }}</span>

                <img
                  src="@/assets/icons/estimate/pencil.png"
                  @click="router.push('/estimate/step7')"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="action-box">
          <span
            class="goback"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              gap: 12px;
            "
            @click="$router.go(-1)"
          >
            <img
              src="@/assets/icons/estimate/arrow-back.png"
              style="width: 24px; height: 24px"
            />
            뒤로가기</span
          >
          <button
            class="submit"
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              gap: 12px;
              margin: 20px;
            "
            @click="submit"
          >
            견적 요청하기
            <img
              src="@/assets/icons/estimate/arrow-back-white.png"
              style="width: 24px; height: 24px"
            />
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-wrap" v-if="showModal">
    <div class="modal-container">
      <div style="padding: 32px 32px">
        <p class="modal-title">정말 그만하시겠어요?</p>
        <p class="modal-text">
          로그인/가입 완료까지 거의 다 왔어요!<br />
          가입을 완료하고 평균 4팀의 비교 건적을 받아보세요.
        </p>
      </div>
      <div class="modal-btn">
        <button @click="showModal = false">계속 진행하기</button>
        <button @click="confirmExit">그만하기</button>
      </div>
    </div>
  </div>
  <CheckSignup v-if="checkSign" @close="submitClose" @closed="checkSign = false;"/>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
//-modules
import axios from "axios";
import { useToast } from "vue-toast-notification";
import { imageSrc2Name, date2YYYYMMDD } from "@/utils/format";
//-options
import { deadline_list } from "@/utils/estimate-options";

import "vue-toast-notification/dist/theme-sugar.css";

import CheckSignup from "./CheckSignup.vue";

const store = useStore();
const toast = useToast();
const router = useRouter();

const showModal = ref(false);
const checkSign = ref(false);
const form_data = reactive({
  purpose: undefined,
  style: [],
  field: undefined,
  work_scope: [],
  deadline: undefined,
  episode_count: undefined,
  video_length: undefined,
  budget: undefined,
  project_title: undefined,
  company_sort: undefined,
  company_name: undefined,
  reference: [],
  detail: undefined,
  file: undefined,
});

onMounted(() => {
  const savedFormData = localStorage.getItem("form_data");
  if (savedFormData) {
    const parsedData = JSON.parse(savedFormData);
    Object.assign(form_data, parsedData);
  }
});

const submitClose = async()=>{
  checkSign.value = false;
  await submit();
};

const deleteFile = async (url) => {
  if(!form_data.file){
    return;
  }

  try{
    await axios.delete(`/api/s3/delete-attachment?url=${url}`);
  }catch(error){
    console.error('파일을 삭제하던 중 에러가 발생했습니다 : ', error);
  }
};

const confirmExit = () => {
  try{
    if(localStorage.getItem("form_data")){
      if(form_data.file){
        const fileUrl = form_data.file.split('/').slice(3).join('/');
        deleteFile(fileUrl);
      }
      localStorage.removeItem("form_data");
      showModal.value = false;
      router.push("/");
    }else{
      router.push("/");
    }
  }catch(error){
    console.error('저장된 내용이 없습니다.', error);
  }
};

const submit = async () => {
  const finish1 = !!form_data.purpose;
  const finish2 = !!form_data.style.length;
  const finish3 = !!form_data.field;
  const finish4 =
    !!form_data.work_scope?.length || form_data.work_scope === null;
  const finish5 = !!form_data.video_length && !!form_data.deadline;
  const finish6 = !!form_data.budget;
  const finish7 =
    !!form_data.project_title &&
    !!form_data.company_sort &&
    !!form_data.company_name;

  if (
    !finish1 ||
    !finish2 ||
    !finish3 ||
    !finish4 ||
    !finish5 ||
    !finish6 ||
    !finish7
  ) {
    toast.open({
      type: "error",
      position: "top",
      message: "필수 항목을 모두 입력한 후 요청할 수 있어요",
    });

    return false;
  }

  const body = {
    purpose: form_data.purpose,
    style: form_data.style,
    field: form_data.field,
    work_scope: form_data.work_scope,
    deadline: deadline_list.includes(form_data.deadline)
      ? form_data.deadline
      : form_data.deadline
      ? date2YYYYMMDD(form_data.deadline)
      : undefined,
    episode_count: `${form_data.episode_count}편`,
    video_length: form_data.video_length,
    budget: form_data.budget,
    project_title: form_data.project_title,
    company_sort: form_data.company_sort,
    company_name: form_data.company_name,
    reference: form_data.reference,
    detail: form_data.detail,
    file: form_data.file,
  };

  if(!store.getters.user){ //로그인이 되어 있지 않다면
    checkSign.value = true;
    return false;
  }else{ // 로그인이 되어 있다면 
    let user_id = store.getters.user?.user_id;

    if(!user_id){
      await store.dispatch('checkAuth');
      const loadUser = store.getters.user;
      if(!loadUser){
        console.error('사용자 정보가 없습니다.');
        return false;
      }
      user_id = loadUser._id;
      console.log(loadUser)
    }

    let res;
    try{
      res = await axios.post(`/api/user/${user_id}/add-project`, body);
    }catch(error){
      res = error.response;
    }
    // 응답 객체가 정의가 되어 있다면
    if(res){
      const { status, data } = res;

      if(status !== 200){
        return console.error(data);
      }
      localStorage.removeItem("form_data");
      router.push(`/estimate/complete/${user_id}`);
    }else{
      console.error('응답 객체가 정의되지 않았습니다.');
    }
  }
};
</script>

<style scoped>
.logo {
  font-weight: 900;
  font-size: 36px;
  color: black;
  padding: 15px;
  text-decoration: none;
}
/* layout */
.layout {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  border-bottom: 1px solid #c8c8c8;
}
.contents {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}
.sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-width: 160px;
  max-width: 240px;
  gap: 20px;
  padding: 24px 12px;

  border-top: 1px solid #c8c8c8;
  border-right: 1px solid #c8c8c8;
}
.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 52px;
  background: #d1e7ff3b;
}

/* button */
.exitBtn {
  width: 200px;
  height: 40px; /* 높이 조정 */

  border: none;
  background-color: var(--vdcolor); /* 나가기 버튼의 배경색 설정 */
  color: white; /* 나가기 버튼의 텍스트 색상 설정 */
  border-radius: 99px; /* 버튼의 테두리를 둥글게 만들기 */
  cursor: pointer; /* 커서를 포인터로 변경하여 클릭 가능하다는 표시 */

  font-size: 17px;
}

/* contents */
.title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
}
.form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
}
.box {
  width: 100%;
  background: white;
  border-radius: 20px;
  padding: 20px 32px;
}
.box-title {
  font-size: 20px;
  font-weight: 600;
  color: #0041d6;
  margin-bottom: 20px;
}
.form-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.form-item {
  display: flex;
  flex-direction: row;
}
.form-item span:first-child {
  width: 160px;
  min-width: 160px;
  font-size: 16px;
}
.form-item span:nth-child(2) {
  flex-grow: 1;
  font-size: 16px;
  color: #000000;
  white-space: pre-line;
}
.form-item span:nth-child(2).disabled {
  color: #c8c8c8;
}
.form-item img {
  width: 14px;
  height: 14px;
  margin-top: 4px;
  cursor: pointer;
  margin-left: 5px;
}

/* action */
.action-box {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}
.action-box .goback {
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}
.action-box .submit {
  width: 200px;
  height: 44px; /* 높이 조정 */

  border: none;
  background-color: var(--vdcolor); /* 나가기 버튼의 배경색 설정 */
  color: white; /* 나가기 버튼의 텍스트 색상 설정 */
  border-radius: 10px; /* 버튼의 테두리를 둥글게 만들기 */
  cursor: pointer; /* 커서를 포인터로 변경하여 클릭 가능하다는 표시 */

  font-size: 16px;
  font-weight: 600;
}

/* modal */
.modal-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
/* modal or popup */
.modal-container {
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}
.modal-btn {
  display: flex;
  justify-content: space-between;
}
.modal-btn button {
  flex-grow: 1;
  width: 50%;
  height: 50px;
  font-size: 14px;

  border: none;
  cursor: pointer;
}
.modal-btn button:first-child {
  background-color: #d9d9d9;
}
.modal-btn button:last-child {
  background-color: var(--vdcolor);
  color: white;
}
.modal-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 24px;
}
.modal-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 120%;
  color: #4d4d4d;
  margin-bottom: 0px;
}

.v-toast__item .v-toast__text {
  padding: 0px 12px !important;
}

.form-item .detail,
.form-item .file-link{
  display: inline-block;
  max-width: 100vw;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}
</style>

