export const purpose_list = [
  {
    image: require("@/assets/icons/estimate/company.png"),
    name: "기업 · 단체 홍보",
  },
  {
    image: require("@/assets/icons/estimate/app.png"),
    name: "앱 · 서비스 홍보",
  },
  {
    image: require("@/assets/icons/estimate/ads.png"),
    name: "제품 광고",
  },
  {
    image: require("@/assets/icons/estimate/education.png"),
    name: "강의 · VOD",
  },
  {
    image: require("@/assets/icons/estimate/staff.png"),
    name: "기업 행사",
  },
  {
    image: require("@/assets/icons/estimate/notes.png"),
    name: "사내 교육",
  },
  {
    image: require("@/assets/icons/estimate/link.png"),
    name: "앱 · 웹 튜토리얼",
  },
  {
    image: require("@/assets/icons/estimate/youtube.png"),
    name: "유튜브 제작",
  },
  {
    image: require("@/assets/icons/estimate/etc.png"),
    name: "기타",
  },
];
export const style_list = [
  {
    image: require("@/assets/imgs/estimate/image0.png"),
    name: "제품/모델 촬영",
  },
  {
    image: require("@/assets/imgs/estimate/image1.jpg"),
    name: "배우 연기",
  },
  {
    image: require("@/assets/imgs/estimate/image2.jpg"),
    name: "공간/매장 촬영",
  },
  {
    image: require("@/assets/imgs/estimate/image3.jpg"),
    name: "인터뷰",
  },
  {
    image: require("@/assets/imgs/estimate/image4.jpg"),
    name: "브랜드 필름",
  },
  {
    image: require("@/assets/imgs/estimate/image5.jpg"),
    name: "현장 스케치",
  },
  {
    image: require("@/assets/imgs/estimate/image6.jpg"),
    name: "캐릭터/인포그래픽",
  },
  {
    image: require("@/assets/imgs/estimate/image7.jpg"),
    name: "3D 그래픽",
  },
  {
    image: require("@/assets/icons/estimate/etc.png"),
    name: "기타",
  },
];
export const field_list = [
  ["IT/정보통신", "교육", "공간/건축", "제약/의료", "게임", "공공/행정"],
  ["문화/예술", "뷰티", "식품/음료", "리빙/생활용품", "기타", "패션/레저"],
];
export const work_list = [
  ["일반 촬영", "드론 촬영"],
  ["모델 섭외", "성우 섭외"],
  ["자막 (영어)", "자막 (한국어)"],
  ["촬영 장소 섭외"],
];
export const video_length_list = ["30초~1분", "1~3분", "3~5분"];
export const deadline_list = ["2주 이내", "1달 이내", "2달 이내"];
export const budget_list = [
  "100만원 미만",
  "100~300만원",
  "300~500만원",
  "500~700만원",
  "700~1천만원",
  "1천~2천만원",
  "2천~3천만원",
  "3천~4천만원",
];
export const company_sort_list = [
  "대기업",
  "중소기업",
  "스타트업",
  "공공기관",
  "재단/단체",
  "자영업",
  "개인용 영상",
];
