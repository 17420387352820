<template>
  <Header />
  <div class="Portfolio">
    <div class="banner">
      <div class="banner__video">
        <iframe
          v-if="videoSrc"
          :src="videoSrc"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="Vimeo Video">
      </iframe>
      </div>
    </div>
    <div class="info">
      <div class="info__wrap">
        <div class="info__left-area">
          <ul class="info__tab">
            <li class="info__menu" :class="{'active': item.isActive}" v-for="(item, index) in tabMenu" :key="index"><button type="button" @click="moveToSection(item, index)">{{ item.title }}</button></li>
          </ul>
          <div ref="videoInfo" class="info__content">
            <h2 class="info__title">영상 소개</h2>
            <div class="info__content-area">
              <h3 class="info__subtitle">{{ portfolio.video_title }}</h3>
              <dl class="info__dl">
                <dt>클라이언트</dt>
                <dd>{{ portfolio.client }}</dd>
              </dl>
              <dl class="info__dl">
                <dt>영상 길이</dt>
                <dd>{{ portfolio.video_length }}</dd>
              </dl>
              <dl class="info__dl">
                <dt>제작 일자</dt>
                <dd>{{ portfolio.production_date }}</dd>
              </dl>
              <p class="info__desc">{{ portfolio.introduction_content }}</p>
            </div>
          </div>
          <div ref="process" class="info__content">
            <h2 class="info__title">영상 제작 과정</h2>
            <div class="info__content-area">
              <h3 class="info__subtitle">이 영상, <br />어떻게 제작했나요?</h3>
              <h4 class="process__title">제작 과정</h4>
              <div class="indicator">
                <div class="indicator_bar">
                  <span class="indicator__point"></span>
                  <span class="indicator__point"></span>
                  <span class="indicator__point"></span>
                </div>
              </div>
              <div class="process__progress">
                <div class="progress__col">
                  <div class="step">
                    <strong class="step__title">기획</strong>
                    <ul class="step__list">
                      <li v-for="(item, index) in filteredPlanningSteps" :key="index">
                        <img :class="item.iconClass" :src="item.iconSrc" alt="icon">
                        <span class="step__text">{{ item.text }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="progress__col">
                  <div class="step">
                    <strong class="step__title">촬영/디자인</strong>
                    <ul class="step__list">
                      <li v-for="(item, index) in filteredShootingSteps" :key="index">
                        <img :class="item.iconClass" :src="item.iconSrc" alt="icon">
                        <span class="step__text">{{ item.text }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="progress__col">
                  <div class="step">
                    <strong class="step__title">편집</strong>
                    <ul class="step__list">
                      <li v-for="(item, index) in filteredEditingSteps" :key="index">
                        <img :class="item.iconClass" :src="item.iconSrc" alt="icon">
                        <span class="step__text">{{ item.text }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div ref="client" class="info__content">
            <h2 class="info__title">클라이언트 정보</h2>
            <div class="info__content-area">
              <dl class="info__dl">
                <dt>기업 정보</dt>
                <dd>{{ portfolio.client_corporate_information }}</dd>
              </dl>
              <dl class="info__dl">
                <dt>주요사업</dt>
                <dd>{{ portfolio.client_major_bussiness }}</dd>
              </dl>
              <p class="info__desc">{{ portfolio.client_about }}</p>
            </div>
          </div>
        </div>
        <div class="info__right-area">
          <div class="inquire">
            <dl>
              <dt>제작 정보</dt>
              <dd>{{ portfolio.production_information }}</dd>
            </dl>
            <dl>
              <dt>제작 기간</dt>
              <dd>{{ portfolio.production_period }}</dd>
            </dl>
            <div class="inquire__btn-wrap">
              <button class="inquire__btn" type="button">찜하기</button>
              <button class="inquire__btn" type="button" @click="linkCopy">링크 복사</button>
            </div>
            <button class="inquire__btn inquire__btn--full" type="button" @click="$router.push('/estimate')">견적 문의하러 가기</button>
          </div>
        </div>
      </div>
    </div>
    <div class="recommend">
      <div class="recommend__wrap">
        <h2 class="info__subtitle">이런 영상은 어때요?</h2>
        <div class="video">
          <div class="video__item">
            <div class="video__mov">
              <video controls width="100%" height="100%"></video>
            </div>
            <strong class="video__title">영상 이름</strong>
            <div class="tag">
              <span class="tag__item">태그1</span>
              <span class="tag__item">태그2</span>
              <span class="tag__item">태그3</span>
            </div>
            <div class="video__company">회사명</div>
          </div>
          <div class="video__item">
            <div class="video__mov">
              <video controls width="100%" height="100%"></video>
            </div>
            <strong class="video__title">영상 이름</strong>
            <div class="tag">
              <span class="tag__item">태그1</span>
              <span class="tag__item">태그2</span>
              <span class="tag__item">태그3</span>
            </div>
            <div class="video__company">회사명</div>
          </div>
          <div class="video__item">
            <div class="video__mov">
              <video controls width="100%" height="100%"></video>
            </div>
            <strong class="video__title">영상 이름</strong>
            <div class="tag">
              <span class="tag__item">태그1</span>
              <span class="tag__item">태그2</span>
              <span class="tag__item">태그3</span>
            </div>
            <div class="video__company">회사명</div>
          </div>
          <div class="video__item">
            <div class="video__mov">
              <video controls width="100%" height="100%"></video>
            </div>
            <strong class="video__title">영상 이름</strong>
            <div class="tag">
              <span class="tag__item">태그1</span>
              <span class="tag__item">태그2</span>
              <span class="tag__item">태그3</span>
            </div>
            <div class="video__company">회사명</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer/>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import axios from "axios";
import Header from "../commons/Header.vue";
import Footer from "../commons/Footer.vue";

const route = useRoute();
const toast = useToast();

const videoId = ref('');
const portfolio = reactive({
    category: '',
    client: '',
    client_about: '',
    client_corporate_information: '',
    client_major_bussiness: '',
    introduction_content: '',
    procedure_1: [],
    procedure_2: [],
    procedure_3: [],
    production_date: '',
    production_information: '',
    production_period: '',
    tag: [],
    thumbnail: '',
    video_length: '',
    video_title: '',
    vimeo_link: '',
    _id: ''
});

// 비디오 길이 형식 변환
const formatVideoLength = (length) => {
  const parts = length.split(':').map(part => parseInt(part, 10));
  let result = '';

  if (parts.length === 3) {
    result = `${parts[0] > 0 ? parts[0] + '시간 ' : ''}${parts[1] > 0 ? parts[1] + '분 ' : ''}${parts[2]}초`;
  } else if (parts.length === 2) {
    result = `${parts[0] > 0 ? parts[0] + '분 ' : ''}${parts[1]}초`;
  } else if (parts.length === 1) {
    result = `${parts[0]}초`;
  }
  return result.trim();
};

// 비디오 제작 일자 형식 변환
const formatProductionDate = (date) => {
  const [year, month] = date.split('.').map(part => part.trim());
  return `${year}년 ${parseInt(month, 10)}월`;
};

const planningSteps = [
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_idea.svg'), text: '아이디어 제안' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_scene.svg'), text: '장면 구성' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_actor.svg'), text: '배우 섭외' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_model.svg'), text: '모델 섭외' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_mic.svg'), text: '성우 섭외' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_spot.svg'), text: '장소 섭외' }
];

const shootingSteps = [
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_video.svg'), text: '현장 촬영' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_drone.svg'), text: '드론 촬영' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_character.svg'), text: '캐릭터/그래픽 디자인' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_3d.svg'), text: '3D 모델링' }
];

const editingSteps = [
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_edit.svg'), text: '종합 편집' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_motion.svg'), text: '모션그래픽' },
  { iconClass: 'step__icon', iconSrc: require('@/assets/svgs/portfolio/ico_effect.svg'), text: '3D/특수효과' }
];


const filteredPlanningSteps = computed(() => {
  return planningSteps.filter((_, index) => portfolio.procedure_1.includes(index));
});

const filteredShootingSteps = computed(() => {
  return shootingSteps.filter((_, index) => portfolio.procedure_2.includes(index));
});

const filteredEditingSteps = computed(() => {
  return editingSteps.filter((_, index) => portfolio.procedure_3.includes(index));
});

const videoSrc = ref('');
// 비메오 링크 그대로 넣을 수 없어서 embedURL로 형식 변환
const formatVimeoEmbedUrl = (vimeoUrl) => {
  const vimeoId = vimeoUrl.split('/')[3].split('?')[0];
  return `https://player.vimeo.com/video/${vimeoId}?badge=0&autoplay=1&loop=1&muted=1&autopause=0&player_id=0&app_id=58479`;
};

const linkCopy = async()=>{
  try{
    const currentUrl = window.location.href;
    await navigator.clipboard.writeText(currentUrl);
    toast.open({
      message : '링크가 복사되었습니다!',
      type : 'info',
      position : 'top'
    });
  }catch(error){
    console.error('url 복사에 실패했습니다 : ', error);
    toast.open({
      message : '링크 복사에 실패했습니다',
      type : 'error',
      position : 'top'
    });
  }
};


onMounted( async()=>{
  try{
    videoId.value = route.params.video_id;
    const { data } = await axios.get(`/api/get/portfolio-detail/${videoId.value}`);
    data.video_length = formatVideoLength(data.video_length);
    data.production_date = formatProductionDate(data.production_date);
    videoSrc.value = formatVimeoEmbedUrl(data.vimeo_link);
    Object.assign(portfolio, data);
  }catch(error){
    console.error('포트폴리오 상세 정보를 가져오지 못했습니다. :', error);
  }
});




const videoInfo = ref(null);
const process = ref(null);
const client = ref(null);

const tabMenu = reactive([
  {
    section: 'videoInfo',
    title: '영상 소개',
    isActive: false
  },
  {
    section: 'process',
    title: '영상 제작 과정',
    isActive: false
  },
  {
    section: 'client',
    title: '제작 요청 클라이언트',
    isActive: false
  }
])
const moveToSection = (item, index) => {
  tabMenu.forEach(item => item.isActive = false);
  tabMenu[index].isActive = true;
  if (item.section === 'videoInfo') {
    videoInfo.value.scrollIntoView()
  }
  if (item.section === 'process') {
    process.value.scrollIntoView()
  }
  if (item.section === 'client') {
    client.value.scrollIntoView()
  }
}
</script>

<style scoped>
.Portfolio {
  width: 100%;
}
.Portfolio .banner {
  width: 100%;
  height: 600px;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Portfolio .banner__video {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Portfolio .banner__video iframe {
  width: 100%;
  height: 100%;
}
.Portfolio .info__wrap {
  display: flex;
  justify-content: space-between;
  gap: 100px;
  padding: 60px;
}
.Portfolio .info__tab {
  display: flex;
  width: 648px;
  border-bottom: 1px solid #000;
}
.Portfolio .info__menu button {
  padding: 18px 40px;
  font-size: 20px;
  color: #000;
}
.Portfolio .info__menu.active button {
  font-weight: 600;
}
.Portfolio .info__menu a {
  font-weight: 600;
  font-size: 20px;
}
.Portfolio .info__content {
  margin: 20px 0 70px;
  padding: 0 30px;
}
.Portfolio .info__title {
  width: 180px;
  height: 50px;
  margin-bottom: 30px;
  border: 5px solid #000;
  text-align: center;
  line-height: 40px;
  font-size: 22px;
  color: #000;
}
.Portfolio .info__content-area {
  padding-left: 8px;
}
.Portfolio .info__subtitle {
  display: block;
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 600;
  color: #000;
}
.Portfolio .info__dl {
  display: flex;
  margin-top: 8px;
}
.Portfolio .info__dl dt {
  width: 120px;
  font-size: 16px;
  color: #808080;
}
.Portfolio .info__dl dd {
  font-size: 16px;
  color: #000;
}
.Portfolio .info__desc {
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.3;
  color: #000;
}
.Portfolio .process__title {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
.Portfolio .indicator_bar {
  display: flex;
  align-items: center;
  gap: 32%;
  width: 100%;
  height: 2px;
  margin: 20px 0 10px;
  border-radius: 5px;
  background: #d0d0d0;
}
.Portfolio .indicator__point {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ddd;
}
.Portfolio .process__progress {
  display: flex;
  width: 100%;
}
.Portfolio .progress__col {
  width: 33.3333%;
}
.Portfolio .step__title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 0 20px;
  color: #000;
}
.Portfolio .step__list{
  width: fit-content;
}
.Portfolio .step__list li {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 20px;
  color: #000;
}
.Portfolio .step__list li + li {
  margin-top: 20px;
}
.Portfolio .step__icon {
  width: 18px;
  height: 18px;
}
.Portfolio .step__text {
  height: 20px;
  line-height: 20px;
  font-size: 16px;
}

.Portfolio .inquire {
  position: sticky;
  top: 30px;
  width: 380px;
  padding: 28px;
  border-radius: 20px;
  background: #F2F2F2;
  box-shadow: 0 4px 4px 0 #00000040;
}
.Portfolio .inquire dl {
  display: flex;
  flex-wrap: nowrap;
}
.Portfolio .inquire dl + dl {
  margin-top: 12px;
}
.Portfolio .inquire dt {
  width: 80px;
  flex-shrink: 0;
}
.Portfolio .inquire dd {
  flex-grow: 1;
  margin-left: 16px;
  word-break: break-word;
}

.Portfolio .inquire__btn-wrap {
  display: flex;
  gap: 12px;
  padding-bottom: 18px;
  margin: 26px 0 18px;
  border-bottom: 1px solid #000;
}
.Portfolio .inquire__btn {
  flex: 1;
  height: 36px;
  font-size: 16px;
  color: #000;
  background: #D9D9D9;
}
.Portfolio .inquire__btn--full {
  width: 100%;
  height: 45px;
}
.Portfolio .recommend__wrap {
  padding: 0 26px 130px;
}
.Portfolio .video {
  display: flex;
  gap: 34px;
}
.Portfolio .video__item {
  flex: 1;
}
.Portfolio .video__mov {
  position: relative;
  padding-top: 56.25%;
  height: 56.25%;
  background: #ddd;
}
.Portfolio .video__mov video {
  position: absolute;
  top: 0;
  left: 0;
}
.Portfolio .video__title {
  display: block;
  margin: 8px 0;
  font-weight: 800;
  font-size: 15px;
  color: #1a1a1a;
}
.Portfolio .tag {
  display: flex;
  align-items: center;
  gap: 6px;
  padding-bottom: 8px;
  margin-bottom: 5px;
  border-bottom: 1px solid #D9D9D9;
}
.Portfolio .tag__item {
  padding: 5px 18px;
  border: 1px solid #808080;
  border-radius: 5px;
  font-size: 12px;
  color: #808080;
}
.Portfolio .video__company {
  font-weight: 400;
  font-size: 12px;
  color: #4C4949;
}
ul, li {
  padding: 0;
  margin: 0;
}
</style>
