<template>
  <div class="layout">
    <div class="header">
      <router-link class="logo" to="/">VD studio</router-link>
      <button class="exitBtn" @click="showModal = true">나가기</button>
    </div>

    <div class="contents">
      <!-- Sidebar Render -->
      <div class="sidebar">
        <div
          v-for="step in steps"
          :key="step.number"
          class="step-box"
          :class="step_number == step.number ? 'active' : ''"
          @click="goStep(step.number)"
        >
          <span>{{ step.number }}</span>
          <span>{{ step.name }}</span>
          <img
            src="@/assets/icons/estimate/check.svg"
            height="20px"
            :style="!step.finish && 'display: none'"
          />
        </div>

        <button class="step-lastitem" @click="goFinal()">
          <span>작성 내용 확인</span>
        </button>
      </div>

      <div class="main">
        <!-- Progressbar Render -->
        <div class="progress">
          <div class="progress-active" :style="{ width: progressWidth }" />
        </div>

        <!-- Main Contetns Render -->
        <div class="form-body" v-if="step_number === 1">
          <p class="form-title">
            영상을 제작하는 <b>목적</b>이 무엇인가요?
            <span class="required">*</span>
          </p>
          <p class="form-subtitle"></p>

          <div class="form-contents">
            <div class="select-box" style="max-width: 560px">
              <div
                v-for="item in purpose_list"
                :key="item.name"
                class="select-item"
                :class="form_data.purpose == item.name ? 'active' : ''"
                @click="selectPurpose(item.name)"
              >
                <img :src="item.image" />
                <span>{{ item.name }}</span>
              </div>
            </div>

            <button class="form-button" @click="goNext()">확인</button>
          </div>
        </div>

        <div class="form-body" v-if="step_number === 2">
          <p class="form-title">
            <b>어떤 스타일</b>의 영상인가요?
            <span class="required">*</span>
          </p>
          <p class="form-subtitle">최대 2개까지 선택할 수 있어요.</p>

          <div class="form-contents">
            <div class="select-box" style="max-width: 560px">
              <div
                v-for="item in style_list"
                :key="item.name"
                class="select-item-image"
                :class="form_data.style.includes(item.name) ? 'active' : ''"
                @click="selectStyle(item.name)"
              >
                <span>{{ item.name }}</span>
                <img :src="item.image" />
              </div>
            </div>

            <button class="form-button" @click="goNext()">확인</button>
          </div>
        </div>

        <div class="form-body" v-if="step_number === 3">
          <p class="form-title">
            <b>어떤 분야</b>의 영상인가요?
            <span class="required">*</span>
          </p>
          <p class="form-subtitle">
            구체적으로 알려주시면 영상 제작에 많은 도움이 됩니다!
          </p>

          <div class="form-contents">
            <div style="display: flex; flex-direction: row; gap: 40px">
              <div>
                <p class="select-subtitle">기술/공공</p>
                <div class="select-box" style="max-width: 300px">
                  <div
                    v-for="name in field_list[0]"
                    :key="name"
                    class="select-item-text"
                    :class="form_data.field === name ? 'active' : ''"
                    @click="setField(name)"
                  >
                    <span>{{ name }}</span>
                  </div>
                </div>
              </div>

              <div>
                <p class="select-subtitle">생활/의식주</p>
                <div class="select-box" style="max-width: 300px">
                  <div
                    v-for="name in field_list[1]"
                    :key="name"
                    class="select-item-text"
                    :class="form_data.field === name ? 'active' : ''"
                    @click="setField(name)"
                  >
                    <span>{{ name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <button class="form-button" @click="goNext()">확인</button>
          </div>
        </div>

        <div class="form-body" v-if="step_number === 4">
          <p class="form-title">
            <b>필요한 작업</b>이 있다면 모두 선택해 주세요.
          </p>
          <p class="form-subtitle">
            상세하고 정확한 견적을 받기 위해 필요합니다.
          </p>

          <div class="form-contents">
            <div
              style="
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 40px;
              "
            >
              <div style="width: calc(50% - 20px)">
                <p class="select-subtitle">촬영</p>
                <div class="select-box" style="margin-bottom: 0px">
                  <div
                    v-for="name in work_list[0]"
                    :key="name"
                    class="select-item-text"
                    :class="
                      form_data.work_scope?.includes(name) ? 'active' : ''
                    "
                    @click="setWorkScope('photo', name)"
                  >
                    <span>{{ name }}</span>
                  </div>
                </div>
              </div>

              <div style="width: calc(50% - 20px)">
                <p class="select-subtitle">섭외</p>
                <div class="select-box" style="margin-bottom: 0px">
                  <div
                    v-for="name in work_list[1]"
                    :key="name"
                    class="select-item-text"
                    :class="
                      form_data.work_scope?.includes(name) ? 'active' : ''
                    "
                    @click="setWorkScope('model', name)"
                  >
                    <span>{{ name }}</span>
                  </div>
                </div>
              </div>

              <div style="width: calc(50% - 20px)">
                <p class="select-subtitle">자막</p>
                <div class="select-box" style="margin-bottom: 0px">
                  <div
                    v-for="name in work_list[2]"
                    :key="name"
                    class="select-item-text"
                    :class="
                      form_data.work_scope?.includes(name) ? 'active' : ''
                    "
                    @click="setWorkScope('text', name)"
                  >
                    <span>{{ name }}</span>
                  </div>
                </div>
              </div>

              <div style="width: calc(50% - 20px)">
                <p class="select-subtitle">장소</p>
                <div class="select-box" style="margin-bottom: 0px">
                  <div
                    v-for="name in work_list[3]"
                    :key="name"
                    class="select-item-text"
                    :class="
                      form_data.work_scope?.includes(name) ? 'active' : ''
                    "
                    @click="setWorkScope('loca', name)"
                  >
                    <span>{{ name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-max-width" style="margin-top: 60px">
              <button
                class="select-item-text"
                style="
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  gap: 8px;
                  max-width: 400px;
                  margin-bottom: 80px;
                  background-color: white;
                "
                :class="form_data.work_scope === null ? 'active' : ''"
                @click="setWorkScope()"
              >
                <img
                  src="@/assets/icons/estimate/block.png"
                  style="width: 20px; height: 20px"
                />
                필요한 작업이 없어요
              </button>

              <button
                class="form-button"
                style="max-width: 400px"
                @click="goNext()"
              >
                확인
              </button>
            </div>
          </div>
        </div>

        <div class="form-body" v-if="step_number === 5">
          <p class="form-title"><b>일정 및 제작 조건</b>을 입력해주세요</p>
          <p class="form-subtitle"></p>

          <div class="form-contents" style="width: 100%; max-width: 480px">
            <div>
              <p class="form-label">
                제작 편수 <span class="required">*</span>
              </p>

              <div class="input-number-box" style="margin-bottom: 32px">
                <div
                  class="number-icon"
                  @click="
                    form_data.episode_count = Math.max(
                      1,
                      form_data.episode_count - 1
                    )
                  "
                >
                  <img src="@/assets/icons/estimate/minus.png" />
                </div>

                <input
                  type="number"
                  class="input"
                  v-model="form_data.episode_count"
                  @input="validateNumber"
                  min="1"
                  placeholder="1"
                />
                <!-- <div class="text">{{ form_data.episode_count }}편</div> -->

                <div class="number-icon" @click="form_data.episode_count += 1">
                  <img src="@/assets/icons/estimate/plus.png" />
                </div>
              </div>
            </div>

            <p class="form-label">영상 길이 <span class="required">*</span></p>
            <div
              class="select-box"
              style="max-width: 560px; margin-bottom: 10px"
            >
              <div
                v-for="name in video_length_list"
                :key="name"
                class="select-item-text2"
                :class="form_data.video_length === name ? 'active' : ''"
                @click="setVideoLength(name)"
              >
                <span>{{ name }}</span>
              </div>
            </div>
            <button
              class="button"
              style="margin-bottom: 32px"
              @click="
                form_data.video_length = '';
                videoInput = true;
              "
              v-if="!videoInput"
            >
              <img
                src="@/assets/icons/estimate/pencil.png"
                style="width: 20px; height: 20px; margin-right: 8px"
              />직접입력
            </button>
            <input
              v-if="videoInput"
              v-model="form_data.video_length"
              style="margin-bottom: 32px"
              placeholder="예 ) 30분~60분"
              class="input"
            />

            <p class="form-label">
              희망 납품 일자 <span class="required">*</span>
            </p>
            <div
              class="select-box"
              style="max-width: 560px; margin-bottom: 10px"
            >
              <div
                v-for="name in deadline_list"
                :key="name"
                class="select-item-text2"
                :class="form_data.deadline === name ? 'active' : ''"
                @click="setDeadline(name)"
              >
                <span>{{ name }}</span>
              </div>
            </div>
            <button class="button" @click="deadlineInput = !deadlineInput">
              <img
                src="@/assets/icons/estimate/calendar.png"
                style="width: 20px; height: 20px; margin-right: 8px"
              />
              {{
                !deadline_list.includes(form_data.deadline) &&
                form_data.deadline
                  ? date2YYYYMMDD(form_data.deadline)
                  : "날짜 직접 선택"
              }}
            </button>

            <div v-if="deadlineInput">
              <div style="display: flex; flex-direction: column;">
                <VDatePicker v-model="tempDeadline" @update:model-value="fixDeadline" style="min-width: 144px; max-width: 80vw; width: 100%;" />
              </div>
            </div>

            <button
              class="form-button"
              style="margin-top: 32px"
              @click="goNext()"
            >
              확인
            </button>
          </div>
        </div>

        <div class="form-body" v-if="step_number === 6">
          <p class="form-title">대략적인 <b>제작 예산</b>을 알려주세요
            <span class="required">*</span>
          </p>
          <div class="form-contents" style="max-width: 540px">
            <div class="select-box" style="margin-bottom: 10px">
              <div
                v-for="name in budget_list"
                :key="name"
                class="select-item-text"
                :class="form_data.budget === name ? 'active' : ''"
                @click="setBudget(name)"
              >
                <span>{{ name }}</span>
              </div>
            </div>

            <button
              v-if="!budgetInput"
              class="button"
              style="margin-bottom: 60px"
              @click="
                form_data.budget = '0~0';
                budgetInput = true;
              "
            >
              <img
                src="@/assets/icons/estimate/pencil.png"
                style="width: 20px; height: 20px; margin-right: 8px"
              />직접입력
            </button>
            <div
              v-if="budgetInput"
              style="
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin-bottom: 60px;
              "
            >
              <div class="budget-container">
                <div class="budget-item">
                  <span class="label">최소</span>
                  <input
                    v-model="budgetSelf[0]"
                    type="number"
                    class="budget-input"
                    @input="validateNumber"
                    min="0"
                    placeholder="0"
                  />
                  <span class="unit">만원</span>
                </div>
                <div class="budget-item">
                  <span class="label">최대</span>
                  <input
                    v-model="budgetSelf[1]"
                    type="number"
                    class="budget-input"
                    @input="validateNumber"
                    min="0"
                    placeholder="0"
                  />
                  <span class="unit">만원</span>
                </div>
              </div>
            </div>

            <button class="form-button" @click="goNext()">확인</button>
          </div>
        </div>

        <div class="form-body" v-if="step_number === 7">
          <p class="form-title">
            마지막 질문입니다!<br />
            <b>프로젝트의 상세 정보</b>를 입력해주세요
          </p>
          <p class="form-subtitle"></p>

          <div class="form-contents" style="width: 100%; max-width: 800px">
            <div
              style="
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 40px;
                min-width: 740px;
                max-width: 800px;
                margin-bottom: 80px;
              "
            >
              <div style="width: calc(50% - 20px)">
                <p class="form-label" style="font-weight: 600">
                  프로젝트 제목 <span class="required">*</span>
                </p>
                <input
                  class="input"
                  maxlength="30"
                  v-model="form_data.project_title"
                  placeholder="예) VD studio 광고 영상"
                />
                <p
                  style="
                    font-size: 12px;
                    color: #808080;
                    text-align: end;
                    margin-top: 4px;
                    margin-bottom: 12px;
                  "
                >
                  {{ form_data.project_title?.length || 0 }}/30
                </p>

                <p class="form-label" style="font-weight: 600">
                  소속 기업 명 <span class="required">*</span>
                </p>
                <div style="display: flex; flex-direction: row; gap: 10px">
                  <v-select
                    class="custom-select"
                    v-model="form_data.company_sort"
                    :reduce="(option) => option"
                    :options="company_sort_list"
                    aria-placeholder="분류 선택"
                    placeholder="분류 선택"
                  >
                  </v-select>
                  
                  <input
                    maxlength="30"
                    v-model="form_data.company_name"
                    placeholder="예) 주식회사 트루빈스"
                    class="input"
                  />
                </div>
                <p
                  style="
                    font-size: 12px;
                    color: #808080;
                    text-align: end;
                    margin-top: 4px;
                    margin-bottom: 12px;
                  "
                >
                  {{ form_data.company_name?.length || 0 }}/30
                </p>

                <p class="form-label" style="font-weight: 600">
                  참고 영상 링크
                  <span style="font-size: 12px; color: #808080"
                    >입력하면 <b>더 정확한 견적과 제안</b>을 받을 수
                    있습니다.</span
                  >
                </p>
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 10px;
                    gap: 8px;
                  "
                  v-for="(val, index) in form_data.reference"
                  :key="index"
                >
                  <input
                    v-model="form_data.reference[index]"
                    class="input"
                    placeholder="https://www.youtube.com/@vdproduction2829"
                  />

                  <button
                    v-if="index > 0"
                    @click="
                      form_data.reference = form_data.reference.filter(
                        (_val, i) => i !== index
                      )
                    "
                    class="button"
                    style="
                      width: 46px;
                      height: 46px;
                      border: 1px solid #0041d6;
                      font-size: 14px;
                    "
                  >
                    <img
                      src="@/assets/icons/estimate/minus.svg"
                      style="width: 12px; height: 12px"
                    />
                  </button>
                </div>
                <button
                  @click="
                    if (form_data.reference[form_data.reference.length - 1])
                      form_data.reference = [...form_data.reference, undefined];
                  "
                  class="button"
                  :class="
                    !form_data.reference[form_data.reference.length - 1] &&
                    'disabled'
                  "
                  style="
                    width: 100px;
                    border: 1px solid #0041d6;
                    height: 40px;
                    font-size: 14px;
                  "
                >
                  <img
                    src="@/assets/icons/estimate/plus.svg"
                    style="width: 12px; height: 12px"
                  />
                  추가 입력
                </button>
              </div>

              <div style="width: calc(50% - 20px)">
                <p class="form-label" style="font-weight: 600">
                  상세 의뢰 내용
                </p>
                <textarea
                  v-model="form_data.detail"
                  class="input"
                  placeholder="견적과 함께 제안 받고 싶은 내용이 있다면 작성해주세요."
                  rows="10"
                />

                <button
                  @click="triggerFileInput"
                  class="button"
                  style="
                    width: 100px;
                    background: #0041d620;
                    height: 40px;
                    font-size: 14px;
                  "
                >
                  <img
                    src="@/assets/icons/estimate/file.png"
                    style="width: 16px; height: 16px"
                  />
                  파일 첨부
                </button>
                <input type="file" ref="fileInput" @change="onFileChange" style="display: none;" />
                <p
                  v-if="form_data.file"
                  style="font-size: 12px; font-weight: 500; margin-top: 8px"
                >
                  업로드한 파일 :
                  <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <a
                  v-if="!loading"
                    :href="form_data.file"
                    target="_blank"
                    class="file-link"
                    >{{ imageSrc2Name(form_data.file) }}</a
                  >
                </p>
                <input
                  type="file"
                  ref="fileInput"
                  @change="onFileChange"
                  style="display: none"
                />
              </div>
            </div>

            <button class="form-button" @click="goNext()">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-wrap" v-if="showModal">
    <div class="modal-container">
      <div style="padding: 32px 32px">
        <p class="modal-title">작성중인 내용을 중단하고 나가시겠어요?</p>
        <p class="modal-text">
          매칭 요청서 작성을 마무리하고<br />
          평균 4건의 견적을 무료로 받아보세요.
        </p>
      </div>
      <div class="modal-btn">
        <button @click="showModal = false">계속 진행하기</button>
        <button @click="confirmExit">그만하기</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, watch, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

//-components
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//-modules
import {
  date2YYYYMMDD,
  imageSrc2Name,
} from "@/utils/format";
//-options
import {
  purpose_list,
  style_list,
  field_list,
  work_list,
  video_length_list,
  deadline_list,
  budget_list,
  company_sort_list,
} from "@/utils/estimate-options";

import { useStore } from 'vuex';
import axios from "axios";

const store = useStore();
const user = computed(() => store.getters.user );


const route = useRoute();
const router = useRouter();

// ** Value
const fileInput = ref(null);
const videoInput = ref(false);
const budgetInput = ref(false);
const deadlineInput = ref(false);

const showModal = ref(false);
const budgetSelf = ref([0, 0]);

const form_data = reactive({
  purpose: undefined,
  style: [],
  field: undefined,
  work_scope: [],
  deadline: undefined,
  episode_count: 1,
  video_length: undefined,
  budget: undefined,
  project_title: undefined,
  company_sort: "",
  company_name: undefined,
  reference: [undefined],
  detail: undefined,
  file: undefined,
});

const selectPurpose = (purpose) => {
  form_data.purpose = purpose;
};

const selectStyle = (value) => {
  const exist = form_data.style.includes(value);

  if (exist) {
    form_data.style = form_data.style.filter((val) => val !== value);
  }else if (form_data.style.length < 2){
    form_data.style = [...form_data.style, value];
  }else {
    form_data.style = [form_data.style[1], value];
  }
};

const setField = (value) => {
  form_data.field = value;
};

const setWorkScope = (type, value) => {
  if (!type) {
    return (form_data.work_scope = null);
  }

  if (form_data.work_scope === null) {
    form_data.work_scope = [];
  }

  const exist = form_data.work_scope.includes(value);
  if (exist){
    form_data.work_scope = form_data.work_scope.filter((val) => val !== value);
  }else {
    form_data.work_scope = [...form_data.work_scope, value];
  }
};

const setVideoLength = (value) => {
  form_data.video_length = value;
};

const setDeadline = (value) => {
  form_data.deadline = value;
};

const tempDeadline = ref(null);

const fixDeadline = () => {
  form_data.deadline = new Date(tempDeadline.value.getTime() - tempDeadline.value.getTimezoneOffset() * 60000).toISOString().slice(0, 10);
};


const setBudget = (value) => {
  form_data.budget = value;
  budgetInput.value = false;
};

const validateNumber = (event) => {
  form_data.episode_count = parseInt(
    event.target.value.replace(/\D/g, "") || 1
  );
};


const previousFileUrl = ref('');
const loading = ref(false);

//-File Input
const triggerFileInput = () => {
  fileInput.value.click();
};

const onFileChange = async (event) => {
  const file = event.target.files[0];
  if(file){
    uploadFile(file);
  }
};

const uploadFile = async(file)=>{
  loading.value = true;
  
  let userId = 'none';
  if(user.value && user.value._id){    
    userId = user.value._id;
  }

  if(previousFileUrl.value){
    await deleteFile(previousFileUrl.value);
  }

  const formData = new FormData();
  formData.append('file', file);
  
  try{
    const res = await axios.post(`/api/${userId}/upload-attachment`, formData);
    previousFileUrl.value = res.data.key;
    form_data.file = res.data.url;
  }catch(error){
    console.error("파일 업로드에 실패했습니다 :", error);
  }finally{
    loading.value = false;
  }
};


const deleteFile = async (url) => {
  if(!form_data.file){
    return;
  }
  try{
    await axios.delete(`/api/s3/delete-attachment?url=${url}`);
  }catch(error){
    console.error('파일을 삭제하던 중 에러가 발생했습니다 : ', error);
  }
};

// ** Steps
const steps = reactive([
  { number: 1, name: "제작 목적", finish: false },
  { number: 2, name: "영상 스타일", finish: false },
  { number: 3, name: "분야", finish: false },
  { number: 4, name: "작업 범위", finish: false },
  { number: 5, name: "일정 및 제작 조건", finish: false },
  { number: 6, name: "예산 범위", finish: false },
  { number: 7, name: "상세 정보", finish: false },
]);

const step_number = computed(() =>
  parseInt(route.params.step.replace("step", ""))
);
const progressWidth = computed(() => {
  const percentage =
    (steps.filter((obj) => obj.finish).length / steps.length) * 100;
  return `${percentage}%`;
});

// ** form
watch(
  () => form_data,
  (newFormData) => {
    localStorage.setItem("form_data", JSON.stringify(newFormData));
    steps[0].finish = !!form_data.purpose;
    steps[1].finish = !!form_data.style.length;
    steps[2].finish = !!form_data.field;
    steps[3].finish =
      !!form_data.work_scope?.length || form_data.work_scope === null;
    steps[4].finish = !!form_data.video_length && !!form_data.deadline;
    steps[5].finish = !!form_data.budget;
    steps[6].finish =
      !!form_data.project_title &&
      !!form_data.company_sort &&
      !!form_data.company_name;
  },
  { deep: true }
);
watch(
  () => budgetSelf,
  (data) => {
    form_data.budget = `${data.value[0]}만원~${data.value[1]}만원`;
  },
  { deep: true }
);

onMounted(() => {
  const savedFormData = localStorage.getItem("form_data");
  if (savedFormData) {
    const parsedData = JSON.parse(savedFormData);
    Object.assign(form_data, parsedData);
  }
});

// ** actions
const goNext = () => {
  if (step_number.value === 7) return goFinal();
  router.push(`/estimate/step${step_number.value + 1}`);
};
const goStep = (num) => router.push(`/estimate/step${num}`);
const goFinal = () => router.push("/estimate/final");

const confirmExit = () => {
  try{
    if(localStorage.getItem("form_data")){
      if(form_data.file){
        const fileUrl = form_data.file.split('/').slice(3).join('/');
        deleteFile(fileUrl);
      }
      localStorage.removeItem("form_data");
      showModal.value = false;
      router.push("/");
    }else{
      router.push("/");
    }
  }catch(error){
    console.error('저장된 내용이 없습니다.', error);
  }
};
</script>

<style src="./EstimateStep.css" scoped></style>

