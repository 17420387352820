// src/review.js
export const reviews = [
    {
        title: "Review 1",
        content: "This is the content of review 1.",
        author: "Author 1"
    },
    {
        title: "Review 2",
        content: "This is the content of review 2.",
        author: "Author 2"
    },
    {
        title: "Review 3",
        content: "This is the content of review 3.",
        author: "Author 3"
    },
    {
        title: "Review 4",
        content: "This is the content of review 14.",
        author: "Author 14"
    },
    {
        title: "영상 의뢰가 처음인 저에게 구세주 같았아요",
        content: "영상 의뢰가 처음이라 어떻게 업체를 찾아 컨택하고, 의뢰할지 막막했는데, 영상 제작에 필요한 모든 과정을 대행해 주니까 너무 편리했고 구세주 같았어요.",
        author: "트루빈스"
    },
    {
        title: "Review 6",
        content: "This is the content of review 2.",
        author: "Author 2"
    },
    {
        title: "Review 7",
        content: "This is the content of review 3.",
        author: "Author 3"
    },
    {
        title: "Review 8",
        content: "This is the content of review 14.",
        author: "Author 14"
    },
    {
        title: "Review 9",
        content: "This is the content of review 1.",
        author: "Author 1"
    },
    {
        title: "Review 10",
        content: "This is the content of review 2.",
        author: "Author 2"
    },
    {
        title: "Review 11",
        content: "This is the content of review 3.",
        author: "Author 3"
    },
    {
        title: "Review 12",
        content: "This is the content of review 14.",
        author: "Author 14"
    },
    {
        title: "Review 13",
        content: "This is the content of review 3.",
        author: "Author 3"
    },
    {
        title: "Review 14",
        content: "This is the content of review 14.",
        author: "Author 14"
    }
];
