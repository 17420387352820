<template>
    <div class="Project">
        <header class="header">
            <div class="left">
                <h3 class="header__logo">VD Studio</h3>
                <div class="header__title">{{ projectContents.project_title }}</div>
            </div>
            <div class="box">
                <router-link class="header__btn" :to="`/${userId}/project-list`">나가기</router-link>
            </div>
        </header>
        <div class="main">
            <div class="main__wrap">
                <nav class="nav">
                    <a class="nav__home" href="#">🏠 프로젝트 홈</a>
                    <a class="nav__menu" href="#">📄 견적 비교</a>
                </nav>
                <div class="main__content">
                <h2 class="main__title">프로젝트 홈</h2>
                <div class="content">
                    <div class="progress">
                        <strong class="progress__title" v-if="projectContents.status == 0">곧 문의에 맞는 견적서를 제공해드릴게요</strong>
                        <strong class="progress__title" v-if="projectContents.status == 1">문의하신 건에 대한 견적이 도착했습니다!<br>견적을 확인해주세요!<br>
                            <router-link to="#" class="custom-link">견적 비교 바로 가기
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10" fill="none">
                                    <path d="M19.7978 4.43796L16.0642 0.704367C15.7958 0.433586 15.3587 0.431633 15.0879 0.700031C14.8171 0.96843 14.8152 1.4055 15.0836 1.67632C15.085 1.67777 15.0864 1.67925 15.0879 1.6807L17.643 4.23585H0.690352C0.309063 4.23581 0 4.54488 0 4.92613C0 5.30738 0.309063 5.61648 0.690352 5.61648H17.643L15.0879 8.17163C14.8162 8.43913 14.8129 8.8762 15.0803 9.14788C15.3478 9.41956 15.7849 9.42296 16.0566 9.1555C16.0591 9.153 16.0616 9.15046 16.0642 9.14788L19.7978 5.41429C20.0674 5.14468 20.0674 4.70757 19.7978 4.43796Z" fill="#0041D6"/>
                                </svg>
                            </router-link>
                        </strong>
                        <div class="indicator">
                            <div class="indicator__bar">
                                <em :class="['indicator__point', { active: projectContents.status === 0 }]">
                                    <span class="indicator__text">컨설턴트 상담</span>
                                </em>
                                <em :class="['indicator__point', { active: projectContents.status === 1 }]">
                                    <span class="indicator__text">견적 제안 확인</span>
                                </em>
                                <em :class="['indicator__point', { active: projectContents.status === 2 }]">
                                    <span class="indicator__text">미팅 진행</span>
                                </em>
                                <em :class="['indicator__point', { active: projectContents.status === 3 }]">
                                    <span class="indicator__text">계약 완료</span>
                                </em>
                            </div>
                        </div>
                    </div>
                    <h3 class="content__title">내 요청서</h3>
                    <div class="content__box request">
                    <div class="request__left">
                        <div class="request__content">
                        <h4 class="request__title">원하는 영상 스타일</h4>
                        <div class="request__form">
                            <div class="request__form-title">제작 목적</div>
                            <div class="request__desc">{{ projectContents.purpose }}</div>
                        </div>

                        <div class="request__form">
                            <div class="request__form-title">영상 스타일</div>
                            <div class="request__desc">{{ Array.isArray(projectContents.style) ? projectContents.style.join(', ') : "작성한 내용이 없습니다." }}</div>
                        </div>

                        <div class="request__form">
                            <div class="request__form-title">분야</div>
                            <div class="request__desc">{{ projectContents.field }}</div>
                        </div>
                        <div class="request__form">
                            <div class="request__form-title">작업 범위</div>
                            <div class="request__desc">
                                {{ Array.isArray(projectContents.work_scope) && projectContents.work_scope.length ? projectContents.work_scope.join(', ') : "작성한 내용이 없습니다." }}
                            </div>
                        </div>

                        </div>
                        <div class="request__content">
                        <h4 class="request__title">의뢰 조건</h4>
                        <div class="request__form">
                            <div class="request__form-title">희망 납품 일자</div>
                            <div class="request__desc">{{ projectContents.deadline }}</div>
                        </div>
                        <div class="request__form">
                            <div class="request__form-title">편수</div>
                            <div class="request__desc">{{ projectContents.episode_count }}</div>
                        </div>
                        <div class="request__form">
                            <div class="request__form-title">영상 길이</div>
                            <div class="request__desc">{{ projectContents.video_length }}</div>
                        </div>
                        <div class="request__form">
                            <div class="request__form-title">예산 범위</div>
                            <div class="request__desc">{{ projectContents.budget }}</div>
                        </div>
                        </div>
                    </div>
                    <div class="request__right">
                        <div class="request__content">
                        <h4 class="request__title">상세 정보</h4>
                        <div class="request__form">
                            <div class="request__form-title">프로젝트 제목</div>
                            <div class="request__desc">{{ projectContents.project_title }}</div>
                        </div>
                        <div class="request__form">
                            <div class="request__form-title">회사명</div>
                            <div class="request__desc">{{ projectContents.company_sort }} / {{ projectContents.company_name }}</div>
                        </div>
                        <div class="request__form">
                            <div class="request__form-title">참고 링크 영상</div>
                            <div class="request__desc" v-if="projectContents.reference && projectContents.reference.length > 1">
                                <div v-for="(link, index) in projectContents.reference" :key="index">
                                    <a :href="link" target="_blank">{{ link }}</a>
                                </div>
                            </div>
                            <div class="request__desc" v-else>
                                참고 링크 영상이 없습니다.
                            </div>
                        </div>
                        <div class="request__form">
                            <div class="request__form-title">상세 의뢰 내용</div>
                            <div class="request__desc">{{ projectContents.detail || "상세 의뢰 내용이 존재하지 않습니다." }}</div>
                        </div>
                        <div class="request__form">
                            <div class="request__form-title">파일 첨부</div>
                            <div class="request__desc file-link">
                                <a v-if="projectContents.file" :href="projectContents.file" :download="getFileName(projectContents.file)">
                                    {{ getFileName(projectContents.file) }}
                                </a>
                                <span v-else>첨부된 파일이 존재하지 않습니다.</span>
                            </div>
                        </div>

                        </div>
                    </div>
                    </div>
                </div>
                <div class="content flex">
                    <div class="question">
                        <h3 class="content__title">자주 묻는 질문</h3>
                        <ul class="content__box question__list">
                            <li class="question__item" v-for="(item, index) in questionList" :key="index">
                                <button class="question__title" :class="{'open': item.isOpen}" type="button" @click="onClickQuestion(index)">{{ item.title }}</button>
                                <div class="question__content" :class="{'open': item.isOpen}">
                                    <span class="question__text">{{ item.content }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="recommend">
                    <div class="recommend__title-wrap">
                        <h3 class="content__title">{{ user.username }}님을 위한 콘텐츠</h3>
                        <a class="recommend__more" href="#">더보기</a>
                    </div>
                    <div class="content__box recommend__box">
                        <div class="recommend__content">
                        <img src="#" alt="content">
                        <div class="recommend__info">
                            <div class="recommend__theme">테마</div>
                            <div class="recommend__title">제목</div>
                        </div>
                        </div>
                        <div class="recommend__content">
                        <img src="#" alt="content">
                        <div class="recommend__info">
                            <div class="recommend__theme">테마</div>
                            <div class="recommend__title">제목</div>
                        </div>
                        </div>
                        <div class="recommend__content">
                        <img src="#" alt="content">
                        <div class="recommend__info">
                            <div class="recommend__theme">테마</div>
                            <div class="recommend__title">제목</div>
                        </div>
                        </div>
                        <div class="recommend__content">
                        <img src="#" alt="content">
                        <div class="recommend__info">
                            <div class="recommend__theme">테마</div>
                            <div class="recommend__title">제목</div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, onMounted, ref, computed } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { useStore } from "vuex";

const route = useRoute();
const store = useStore();
const user = computed(()=> store.getters.user);

const projectId = ref('');
const userId = ref('');
const projectContents = reactive({
    budget: '',
    company_name: '',
    company_sort: '',
    created_at: '',
    deadline: '',
    detail: '',
    episode_count: '',
    field: '',
    file: '',
    project_title: '',
    purpose: '',
    reference: [],
    status: 0,
    style: [],
    user_id: '',
    video_length: '',
    work_scope: [],
    _id: ''
});

onMounted(async()=> {
    try{
        projectId.value = route.params.project_id;
        userId.value = route.params.user_id;
        const { data } = await axios.get(`/api/user/${userId.value}/detail-project/${projectId.value}`);
        Object.assign(projectContents, data);
    }catch(error){
        console.error('프로젝트 내용을 가져오지 못했습니다. : ', error);
    }
});

const getFileName = (url) => {
    const fileName = decodeURIComponent(url.split('/').pop());
    return fileName.replace(/^\d+_/, '');
};

const onClickQuestion = (index) =>  {
    questionList[index].isOpen = !questionList[index].isOpen
}

const questionList = reactive([
{
    title: '영상을 만들고 싶은데 꼭 내용이 구체적이여야 할까요?',
    content: '내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다.내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다.내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다.내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다.내용입니다. 내용입니다. 내용입니다. 내용입니다.',
    isOpen: false
},
{
    title: '영상을 만들고 싶은데 꼭 내용이 구체적이여야 할까요?',
    content: '내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. ',
    isOpen: false
},
{
    title: '영상을 만들고 싶은데 꼭 내용이 구체적이여야 할까요?',
    content: '내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. ',
    isOpen: false
},
{
    title: '영상을 만들고 싶은데 꼭 내용이 구체적이여야 할까요?',
    content: '내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. ',
    isOpen: false
},
{
    title: '영상을 만들고 싶은데 꼭 내용이 구체적이여야 할까요?',
    content: '내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. ',
    isOpen: false
},
{
    title: '영상을 만들고 싶은데 꼭 내용이 구체적이여야 할까요?',
    content: '내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. ',
    isOpen: false
},
{
    title: '영상을 만들고 싶은데 꼭 내용이 구체적이여야 할까요?',
    content: '내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. 내용입니다. '
}
])
</script>

<style scoped>
.Project {
  width: 100%;
  min-width: 1200px;
}
.Project .header {
    background-color: black;
    height: 68px;
    width: 100%;
    display: flex;
    justify-content: space-between; /* 요소를 양 끝으로 배치 */
    align-items: center; /* 요소를 수직 가운데로 정렬 */
    padding: 0 50px;
    box-sizing: border-box;
}

.Project .header .left {
    display: flex;
    align-items: center; /* 요소를 수직 가운데로 정렬 */
}

.Project .header__logo {
    color: #FFF;
    font-weight: 800;
    margin: 0; /* 여백 제거 */
}

.Project .header__title {
    color: #FFF;
    font-size: 17px;
    font-weight: 500;
    padding-left: 20px;
    text-decoration: none;
}

.Project .box {
    display: flex;
    align-items: center; /* 수직 가운데 정렬 */
    justify-content: center; /* 수평 가운데 정렬 */
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 10px;
    width: 156px;
    height: 36px;
    text-align: center;
}

.Project .header__btn {
    text-decoration: none;
    color: #FFF;
    font-size: 16px;
}

.Project .main {
    width: 100%;
    transform: scale(0.9); /* 90% 축소 */
    transform-origin: top left; /* 축소 기준점 설정 */
}

.Project .main__wrap {
    display: flex;
    padding: 0 50px;
}

.Project .nav {
    display: block;
    padding: 40px 40px 40px 0;
}

.Project .nav a {
    display: block;
    width: 176px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #000;
    background: #fff;
}

.Project .nav__home {
    border: 1px solid #000;
    font-weight: 700;
}

.Project .nav__menu {
    margin-top: 10px;
}

.Project .main__content {
    width: 100%;
    padding: 50px 0;
}

.Project .main__title {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 20px;
    color: #000;
}

.Project .content {
    padding: 24px;
    background: #8DBFEB33;
}

.Project .content + .Project .content {
    margin-top: 24px;
}

.Project .progress {
    display: block;
    width: 100%;
    height: fit-content;
    padding: 48px;
    margin-bottom: 50px;
    background: #fff;
}

.Project .progress__title {
    display: block;
    margin-bottom: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #000;
}
.Project .custom-link{
    position: relative;
    font-size: 17px;
    font-weight: 400;
}
.Project .custom-link::after{
    content:"";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 1.5px;
    background-color: var(--vdcolor);
}
.Project .indicator{
    margin: 20px;
}
.Project .indicator__bar {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 18px;
    border-radius: 5px;
    background: #D9D9D9;
}

.Project .indicator__point {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #0041D666;
}

.Project .indicator__point.active {
    background: url('@/assets/svgs/user/indicator_active.svg') no-repeat center center;
}

.Project .indicator__text {
    position: absolute;
    bottom: -26px;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    white-space: nowrap;
}

.Project .content__title {
    margin-bottom: 12px;
    font-size: 17px;
    font-weight: 600;
    color: #000;
}

.Project .content__box {
    height: auto;
    padding: 24px;
    background: #fff;
}

.Project .request {
    display: flex;
    gap: 50px;
}

.Project .request__left {
    width: 50%;
}

.Project .request__content + .request__content {
    margin-top: 30px;
}

.Project .request__title {
    margin-bottom: 24px;
    font-size: 20px;
    font-weight: 600;
    color: #0041D6;
}

.Project .request__form {
    display: flex;
    align-items: center;
}

.Project .request__form-title {
    width: 150px;
    font-size: 16px;
    color: #000;
}

.Project .request__form + .request__form {
    margin-top: 16px;
}

.Project .request__desc {
    width: 360px;
    font-size: 16px;
    color: black;
}

.Project .content.flex {
    display: flex;
    gap: 24px;
}

.Project .question {
    width: 50%;
}

.Project .question__list {
    padding: 42px 24px;
}

.Project .question__item {
    border-bottom: 1px solid #C8C8C8;
}

.Project .question__item:first-child {
    border-top: 1px solid #C8C8C8;
}

.Project .question__title {
    position: relative;
    display: block;
    width: 100%;
    padding: 16px 30px 16px 0;
    text-align: left;
    font-size: 16px;
    color: #000;
}

.Project .question__title:after {
    content: '';
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: url('@/assets/svgs/user/arrow_down.svg') no-repeat center/contain;
}

.Project .question__title.open:after {
    transform: rotate(180deg);
}

.Project .question__content {
    overflow: hidden;
    max-height: 0;
    font-size: 16px;
    color: #000;
    transition: max-height 0.4s linear;
}

.Project .question__text {
    display: block;
    padding: 16px 0;
}

.Project .question__content.open {
    max-height: 500px;
    transition: max-height 0.4s linear;
}

.Project .recommend {
    width: 50%;
}

.Project .recommend__title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Project .recommend__more {
    font-size: 16px;
    color: #A6A6A6;
}

.Project .recommend__content {
    display: flex;
    gap: 12px;
}

.Project .recommend__content + .recommend__content {
    margin-top: 12px;
}

.Project .recommend__content img {
    width: 138px;
    height: 100px;
    background: #ddd;
}

.Project .recommend__theme {
    margin-bottom: 8px;
    font-size: 12px;
    color: #808080;
}

a {
    text-decoration: none;
}
.file-link{
    display: inline-block;
    max-width: 324px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
}
@media (max-width: 890px) {
    .Project .header {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 10px 20px;
    }
    .Project .header .left {
        width: 100%;
        justify-content: space-between;
    }
    .Project .header__logo {
        margin-bottom: 10px;
    }
    .Project .header__title {
        margin-left: 0;
        margin-bottom: 10px;
    }
    .Project .box {
        width: 100%;
        margin-top: 10px;
    }
    .Project .header__btn {
        width: 100%;
        height: auto;
        line-height: normal;
        text-align: center;
        margin-top: 10px;
    }
    .Project .main__wrap {
        flex-direction: column;
        padding: 0 20px;
    }
    .Project .nav {
        width: 100%;
        padding: 20px;
    }
    .Project .nav a {
        width: 100%;
        margin-bottom: 10px;
    }
    .Project .main__content {
        padding: 20px 0;
    }
    .Project .request {
        flex-direction: column;
        gap: 20px;
    }
    .Project .request__left, .Project .request__right {
        width: 100%;
    }
    .Project .content.flex {
        flex-direction: column;
        gap: 20px;
    }
    .Project .question, .Project .recommend {
        width: 100%;
    }
    .Project .question__list {
        padding: 20px;
    }
    .Project .recommend__title-wrap {
        flex-direction: column;
        align-items: flex-start;
    }
    .Project .recommend__more {
        margin-top: 10px;
    }
    .Project .recommend__content {
        flex-direction: column;
        align-items: flex-start;
    }
    .Project .recommend__content img {
        width: 100%;
        height: auto;
    }
}


</style>
