<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        id="Capa_1"
        viewBox="0 0 512 512"
        xml:space="preserve"
        width="20"
        height="20"
    >
        <g>
            <g id="object_19_">
                <g>
                    <path
                        d="M506.823,243.482l-95.58-95.58c-6.871-6.932-18.06-6.982-24.993-0.111c-6.932,6.871-6.982,18.06-0.111,24.993
                        c0.037,0.037,0.074,0.075,0.112,0.112l65.411,65.412H17.673C7.912,238.307,0,246.219,0,255.979
                        c0,9.76,7.912,17.673,17.673,17.673h433.989l-65.411,65.412c-6.955,6.848-7.042,18.037-0.195,24.992
                        c6.848,6.955,18.037,7.042,24.992,0.195c0.065-0.064,0.13-0.129,0.195-0.195l95.58-95.58
                        C513.725,261.574,513.725,250.384,506.823,243.482z"
                        fill="#FFFFFF"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: "Arrow",
    };
</script>

<style scoped>

</style>
