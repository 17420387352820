<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
  setup(){
  }
};
</script>

<style>
.inner{
  font-family: 'Roboto', sans-serif;
  font-size : 17px;
}
:root {
  --vdcolor : #0041D6;
  --vdhovercolor : #4B47FF;
}

.vd-btn{
  width: 265px;
  height: 65px;
  flex-shrink: 0;
  border-radius: 30px;
  background: linear-gradient(90deg, var(--vdhovercolor) 0%, #68FFDB 100%);
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  position: sticky;
  border: none;
}
@keyframes slideUp {
  from {
    transform: translateY(20%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.7s ease-out;
}

.slide-up-enter, .slide-up-leave-to {
  transform: translateY(20%);
  opacity: 0;
}

.slide-up-visible {
  animation: slideUp 0.4s ease-out forwards;
}



</style>