<template>
    <div class="AdminRequestDetail" v-if="projectData && userData">
        <div v-if="loadingExit" class="loading-overlay">
            <div class="spinner"></div>
        </div>
        <div class="detail__wrap">
            <button @click="exit" class="detail__back-btn"></button>
            <div class="content">
                <h2 class="content__title">요청서</h2>
                <div class="content__box">
                    <div class="content__col">
                        <div class="info">
                            <h3 class="info__title">원하는 영상 스타일</h3>
                            <dl class="info__dl">
                                <dt>카테고리</dt>
                                <dd>{{ projectData.purpose }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>분야</dt>
                                <dd>{{ projectData.field }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>연출 방식</dt>
                                <dd>{{ Array.isArray(projectData.style) ? projectData.style.join(', ') : "작성한 내용이 없습니다." }}</dd>
                            </dl>
                        </div>
                        <div class="info">
                            <h3 class="info__title">의뢰 조건</h3>
                            <dl class="info__dl">
                                <dt>편수</dt>
                                <dd>{{ projectData.episode_count }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>영상 길이</dt>
                                <dd>{{ projectData.video_length }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>납품 일자</dt>
                                <dd>{{ projectData.deadline }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>작업 범위 </dt>
                                <dd>{{ Array.isArray(projectData.work_scope) && projectData.work_scope.length ? projectData.work_scope.join(', ') : "작성한 내용이 없습니다." }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>예산</dt>
                                <dd>{{ projectData.budget }}</dd>
                            </dl>
                        </div>
                        <div class="info">
                            <h3 class="info__title">클라이언트 정보 </h3>
                            <dl class="info__dl">
                                <dt>담당자 명</dt>
                                <dd>{{ userData.username }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>연락처</dt>
                                <dd>{{ userData.phoneNumber }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>이메일</dt>
                                <dd>{{ userData.email }}</dd>
                            </dl>
                        </div>
                    </div>
                    <div class="content__col">
                        <div class="info">
                            <h3 class="info__title">상세 내용</h3>
                            <dl class="info__dl">
                                <dt>프로젝트 제목</dt>
                                <dd>{{ projectData.project_title }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>회사명</dt>
                                <dd>{{ projectData.company_sort }} / {{ projectData.company_name }}</dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>참고 영상</dt>
                                <dd v-if="projectData.reference && projectData.reference.length > 1">
                                    <div v-for="(link, index) in projectData.reference" :key="index">
                                        <a :href="link" target="_blank" class="file-link">{{ link }}</a>
                                    </div>
                                </dd>
                                <dd v-else>
                                    참고 링크 영상이 없습니다.
                                </dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>파일 첨부</dt>
                                <dd v-if="projectData.file">
                                    <a :href="projectData.file" :download="getFileName(projectData.file)" class="file-link">
                                        {{ getFileName(projectData.file) }}
                                    </a>
                                </dd>
                                <dd v-else>
                                    첨부된 파일이 존재하지 않습니다.
                                </dd>
                            </dl>
                            <dl class="info__dl">
                                <dt>상세 의뢰 내용</dt>
                                <dd>{{ projectData.detail }}</dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="select">
                    <button class="select__btn" @click="onClickSelect">{{ selectedItem }}</button>
                    <ul class="select__options-list" v-if="isOpenSelectOption">
                        <li class="select__options" v-for="(item, index) in options" :key="index">
                            <button @click="onClickOption(item)">{{ item.text }}</button>
                        </li>
                    </ul>
                </div>
                <textarea v-model="selectedText" class="form__textarea" placeholder="여기에 내용을 적어주세요"></textarea>
                <div class="content__row">
                    <button class="form__btn" type="button" @click="triggerOutline">
                        <span v-if="outlineLoading" class="estimate-spinner"></span>
                        영상 제작 개요안 첨부
                    </button>
                    <input type="file" ref="outline" @change="onOutlineChange" style="display: none;"/>
                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <a v-if="outlineUrl" :href="outlineName" class="file-link">{{ getFileName(outlineName) }}</a>
                </div>

                <div class="estimate-row" v-for="(estimate, index) in estimates" :key="index">
                    <input type="text" class="estimate-input-count" v-model="estimate.proposal_count" placeholder="ex ) 1안">
                    <div class="estimate-price-wrapper">
                        <input type="number" class="estimate-input" v-model="estimate.price" placeholder="금액 입력">
                        <span>만원</span>
                    </div>
                    <input type="text" class="estimate-input-detail" v-model="estimate.details" placeholder="ex) 200분: 소스/인포그래픽 + 믹싱"/>
                    <input type="file" ref="estimate" class="estimate-file-input" @change="(event) => onEstimateFileChange(event, index)">
                    <button type="button" class="remove-btn" @click="removeEstimate(index)">-</button>
                </div>

                <button class="form__btn add" type="button" @click="addEstimate">
                    <i class="icon"></i>
                    <span>견적서 추가</span>
                </button>

                <button class="form__btn save" @click="saveData">저장</button>
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const projectData = ref({});
const userData = ref({});
const userId = ref('');
const projectId = ref('');

const loading = ref(false);
const outline = ref(null);
const outlineUrl = ref('');
const outlineName = ref(''); // 저장해서 넘겨줘야 하는 값
const outlineLoading = ref(false);

const estimates = ref([]); // 견적서 저장하는 배열
const estimateLoading = ref(false);

const loadingExit = ref(false);

const selectedItem = ref('직접 작성');
const selectedText = ref('');
const options = [
    {
        value: 0,
        text: '직접 작성'
    },
    {
        value: 1,
        text: '견적서/개요안 등록 안내'
    },
    {
        value: 2,
        text: '진행 불가 안내'
    }
]

const isOpenSelectOption = ref(false);
const onClickSelect = () => {
    isOpenSelectOption.value = !isOpenSelectOption.value
}

const onClickOption = (item) => {
    selectedItem.value = item.text;
    isOpenSelectOption.value = false;
    if (item.value === 1) {
        selectedText.value = `
        안녕하세요! VD스튜디오에 문의해주셔서 감사합니다.

        해당 요청 사항에 대한 영상 제작 개요안과 견적서를 송부해드립니다.
        여유되실 때 확인 부탁드립니다.

        사전에 필요한 사항이 있으시면 메일로도 한 번 더 연락주시기 바랍니다.

        감사합니다.
        `;
    } else if (item.value === 2) {
        selectedText.value = `
        안녕하세요! VD스튜디오에 문의해주셔서 감사합니다.
        
        해당 요청 사항에 대한 문의 검토 결과, 요청하신 견적과 일정으로는 제작이 어려울 것 같습니다.
        
        추가로 궁금하신 사항이나, 전달해주실 말씀이 있다면 전화 부탁드립니다.
        
        감사합니다.
        `;
    } else {
        selectedText.value = '';
    }
}

const fetchPojectContent = async () => {
    try {
        projectId.value = route.params.project_id;
        const { data: project } = await axios.get(`/api/admin/estimate/detail-project/${projectId.value}`);
        projectData.value = project;
        userId.value = projectData.value.user_id;
        const { data: user } = await axios.get(`/api/admin/estimate/get/user-info/${userId.value}`);
        userData.value = user;
    } catch (error) {
        console.error('프로젝트 상세 내용을 가져오는 도중 오류가 발생했습니다. :', error);
    }
}

const getFileName = (url) => {
    const fileName = decodeURIComponent(url.split('/').pop());
    return fileName.replace(/^\d+_/, '');
};

const triggerOutline = () => {
    outlineLoading.value = true;
    outline.value.click();
    outline.value.addEventListener('blur', onOutlineBlur);
};

const onOutlineBlur = () => {
    outlineLoading.value = false;
    outline.value.removeEventListener('blur', onOutlineBlur); // 이벤트 리스너 제거
};

const onOutlineChange = async (event) => {
    outlineLoading.value = false; // 파일이 선택된 경우 로딩 상태 종료
    const file = event.target.files[0];
    if (file) {
        await uploadFile(file);
    }
};


const uploadFile = async (file) => {
    loading.value = true;

    if (outlineUrl.value) {
        await deleteFile(outlineUrl.value);
    }
    const formData = new FormData();
    formData.append('file', file);

    try {
        const res = await axios.post(`/api/admin/estimate/comment/${projectId.value}/${userId.value}/add-production-outline`, formData);
        outlineUrl.value = res.data.key;
        outlineName.value = res.data.url;
    } catch (error) {
        console.error("영상 첨부에 실패했습니다 : ", error);
    } finally {
        loading.value = false;
    }
}

const deleteFile = async (url) => {
    try {
        await axios.delete(`/api/admin/estimate/s3/delete-outline?url=${url}`);
        outlineUrl.value = '';
        outlineName.value = '';
    } catch (error) {
        console.error('파일을 삭제하던 중 에러가 발생했습니다 : ', error);
    }
}

// 견적서 첨부 관련
const addEstimate = ()=>{
    estimates.value.push({
        proposal_count : '', // 몇 안인지 ex) 1안, 1-1안
        price : '',
        details : '',
        estimateName : ''
    })
};
const removeEstimate = async (index) => {
    const estimate = estimates.value[index];
    if(estimate.estimateName){
        await deleteEstimateFile(estimate.estimateName);
    }

    estimates.value.splice(index, 1);
};

const onEstimateFileChange = async(event, index) =>{
    estimateLoading.value = true;

    if(estimates.value[index].estimateName){
        await deleteEstimateFile(estimates.value[index].estimateName);
    }

    const file = event.target.files[0];
    if(file){
        const formData = new FormData();
        formData.append('file', file);
        try{
            const res = await axios.post(`/api/admin/estimate/comment/${projectId.value}/${userId.value}/add-estimate`, formData);
            estimates.value[index].estimateName = res.data.url; // 이거 url로 줘서 백엔드에서 key로 바꿔야함.
        }catch(error){
            console.error('견적서 파일 업로드에 실패했습니다 : ', error);
        } finally{
            estimateLoading.value = false;
        }
    }
}


const deleteEstimateFile = async (url)=>{
    try{
        await axios.delete(`/api/admin/estimate/s3/delete-estimate?url=${url}`);
    }catch(error){
        console.log('견적서 파일 삭제에 실패했습니다: ', error);
    }
}

const saveData = async () =>{
    try {
        // status 값을 0에서 1로 변경
        const updatedStatus = projectData.value.status === 0 ? 1 : projectData.value.status;

        const payload = {
            project_id: projectId.value,
            user_id: userId.value,
            reply: selectedText.value,
            video_outline: outlineName.value,
            estimate: estimates.value.map(estimate => ({
                proposal_count: estimate.proposal_count,
                price: estimate.price,
                details: estimate.details,
                estimate_name: estimate.estimateName
            })),
            status : updatedStatus
        };

        await axios.post(`/api/admin/estimate/project-comment/${projectId.value}/${userId.value}`, payload);
        alert('저장이 완료되었습니다.');
        router.push('/admin/inquirydetail');
    } catch (error) {
        console.error('저장 중 오류가 발생했습니다: ', error);
        alert('저장 중 오류가 발생했습니다.');
    }
};


const exit = async () => {
    loadingExit.value = true;
    try {
        if (outlineUrl.value) {
            await deleteFile(outlineUrl.value);
        }
        // 모든 estimateName을 가진 항목 삭제
        const deletePromises = estimates.value
            .filter(estimate => estimate.estimateName)
            .map(estimate => deleteEstimateFile(estimate.estimateName));
        
        await Promise.all(deletePromises);
    } catch (error) {
        console.error('파일을 삭제하는 도중 오류가 발생했습니다: ', error);
    } finally {
        loadingExit.value = false;
        router.go(-1);
    }
}

onMounted(fetchPojectContent);
</script>

<style scoped>
.AdminRequestDetail .detail__wrap {
    padding: 28px 40px;
}
.AdminRequestDetail .detail__back-btn {
    display: block;
    width: 50px;
    height: 50px;
    margin-bottom: 36px;
    background: url('@/assets/svgs/admin/back_btn.svg') no-repeat center center;
}
.AdminRequestDetail .content {
    width: 100%;
    padding: 24px;
    border-radius: 5px;
    background: #D9D9D980;

    display: flex;
    flex-direction: column;
}
.AdminRequestDetail .content + .content {
    margin-top: 30px;
}
.AdminRequestDetail .content__title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
}
.AdminRequestDetail .content__box {
    display: flex;
    padding: 20px 24px 30px;
    background: #fff;
}
.AdminRequestDetail .content__col {
    width: 50%;
}
.AdminRequestDetail .info + .info {
    margin-top: 30px;
}
.AdminRequestDetail .info__title {
    margin-bottom: 18px;
    font-size: 18px;
    font-weight: 600;
    color: var(--vdcolor);
}
.AdminRequestDetail .info__dl {
    display: flex;
}
.AdminRequestDetail .info__dl + .info__dl {
    margin-top: 15px;
}
.AdminRequestDetail .info__dl dt {
    flex-shrink: 0;
    width: 140px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
.AdminRequestDetail .info__dl dd {
    font-size: 16px;
    line-height: 20px;
    color: black;
}
.AdminRequestDetail .select {
    position: relative;
}
.AdminRequestDetail .select__btn {
    display: block;
    width: 100%;
    padding: 14px 24px;
    border: 0;
    border-radius: 10px;
    margin-bottom: 16px;
    text-align: left;
    font-size: 17px;
    color: #000;
    background-image: url('@/assets/svgs/admin/arrow_down.svg');
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: right 20px top 10px;
}
.AdminRequestDetail .select__options-list {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 12px 16px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 4px 4px 0 #00000040;
}
.AdminRequestDetail .select__options + .select__options {
    border-top: 1px solid #b3b3b3;
}
.AdminRequestDetail .select__options button {
    width: 100%;
    padding: 12px;
    text-align: left;
    font-size: 16px;
    color: #000;
}
.AdminRequestDetail .select__options button:hover {
    background: #f8f8f8;
}
.AdminRequestDetail .form__textarea {
    width: 100%;
    height: 396px;
    padding: 24px;
    border: 0;
    border-radius: 5px;
    font-size: 17px;
    background: #fff;
    white-space: pre-wrap;
}
.AdminRequestDetail .form__textarea::placeholder {
    font-size: 17px;
    color: #808080;
}
.AdminRequestDetail .form__btn {
    display: block;
    width: 260px;
    height: 50px;
    margin-top: 18px;
    border-radius: 5px;
    background: var(--vdcolor);
    font-weight: 600;
    font-size: 17px;
    color: #fff;
}
.AdminRequestDetail .form__btn.add {
    display: flex;
    align-items: center;
    padding-left: 56px;
    gap: 12px;
}
.AdminRequestDetail .form__btn.save {
    align-self: flex-end;
}

.AdminRequestDetail .form__btn.add .icon {
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
}
.AdminRequestDetail .form__btn.add .icon:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 17px;
    height: 17px;
    background: url('@/assets/svgs/admin/plus_icon.svg') center;
}
.AdminRequestDetail .content__row {
    display: flex;
    align-items: center; /* 수직으로 가운데 정렬 */
    gap: 20px; /* 버튼과 링크 사이의 간격 */
}

.AdminRequestDetail .estimate-row {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
}

.AdminRequestDetail .estimate-input {
    width: 100px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.AdminRequestDetail .estimate-input-count {
    width: 70px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.AdminRequestDetail .estimate-input-detail {
    width: 400px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.AdminRequestDetail .estimate-price-wrapper {
    display: flex;
    align-items: center;
    gap: 10px; /* 입력 필드와 '만원' 사이의 간격 */
}
.AdminRequestDetail .estimate-price-wrapper span{
    white-space: nowrap; /* 줄 바꿈 방지 */
}

.AdminRequestDetail .estimate-file-input {
    /* display: none; */
}

.AdminRequestDetail .file-link {
    margin-left: 10px;
    display: inline-block;
    max-width: 100vw;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
}

.AdminRequestDetail .remove-btn {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}
/* 로딩 오버레이 */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid white;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

.estimate-spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 3px solid rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    border-top-color: #ffffff;
    animation: spin 1s linear infinite;
    margin-left: 10px;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



@media (max-width: 1272px) {
    .AdminRequestDetail .detail__wrap {
        padding: 20px 30px;
    }
    .AdminRequestDetail .content__box {
        flex-direction: column;
        padding: 15px 20px;
    }
    .AdminRequestDetail .content__col {
        width: 100%;
    }
    .AdminRequestDetail .info__dl dt, .AdminRequestDetail .info__dl dd {
        font-size: 14px;
    }
    .AdminRequestDetail .select__btn {
        background-size: 25px;
        background-position: right 15px top 7px;
        font-size: 15px;
    }
    .AdminRequestDetail .select__options button {
        font-size: 14px;
    }
    .AdminRequestDetail .form__textarea {
        height: 300px;
        padding: 20px;
        font-size: 15px;
    }
    .AdminRequestDetail .form__textarea::placeholder {
        font-size: 17px;
    }
    .AdminRequestDetail .form__btn {
        width: 220px;
        height: 45px;
        font-size: 15px;
    }
    .AdminRequestDetail .form__btn.add {
        padding-left: 48px;
        gap: 10px;
    }
    .AdminRequestDetail .form__btn.add .icon {
        width: 18px;
        height: 18px;
    }
    .AdminRequestDetail .form__btn.add .icon:after {
        width: 15px;
        height: 15px;
    }
}

</style>
