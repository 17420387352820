<template>
    <div class="PostList">
        <div class="title">
            <h4>포스트 리스트</h4>
            <button @click="router.push('/admin/postlist/register')">포스트 게시하기</button>
        </div>
        <div class="tabs">
            <button
            v-for="theme in themes"
            :key="theme"
            @click="selectTheme(theme)"
            :class="{ active : selectedTheme === theme }">
            {{ theme }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';

const router = useRouter();

const themes = [ '전체 포스트', '영상 제작 가이드', 'PD노트', 'TIP', 'VD제작기'];
const selectedTheme = ref('전체 포스트');

const selectTheme = (theme)=>{
    selectedTheme.value = theme;
}
onMounted(()=>{
    selectTheme;
});

</script>

<style scoped>
.PostList{
    margin: 30px;
}
.PostList .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.PostList .title h4 {
    color: #000;
    font-size: 24px;
    font-weight: 600;
}

.PostList .title button {
    width: 190px;
    height: 42px;
    border-radius: 20px;
    background: var(--vdcolor);
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}
.PostList .title button:hover {
    background: var(--vdhovercolor);
}
.tabs {
    display: flex;
    align-items: center;
    margin: 20px 0;
}
.tabs button {
    margin: 5px 10px;
    padding: 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 17px;
    font-weight: 400;
    position: relative;
    transition: color 0.3s ease;
    outline: none;
}
.tabs button::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -5px;
    width: 0;
    height: 2px;
    background-color: black;
    transition: width 0.3s ease, left 0.3s ease;
}

.tabs button.active {
    color: black;
    font-weight: 700;
}
.tabs button.active::after {
    width: 100%;
    left: 0;
}
</style>
