<template>
    <div class="inner">
        <div class="top">
            <h4>포트폴리오 리스트</h4>
            <div class="controls">
                <div class="toggle-search">
                    <button>최근 등록순</button>
                    <div class="search-box">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <path d="M24.7628 23.6399L18.3082 17.2884C19.9984 15.452 21.037 13.0234 21.037 10.3509C21.0362 4.63387 16.3273 0 10.5181 0C4.70891 0 0 4.63387 0 10.3509C0 16.0678 4.70891 20.7017 10.5181 20.7017C13.0281 20.7017 15.3301 19.8335 17.1384 18.3902L23.618 24.7667C23.9338 25.0777 24.4463 25.0777 24.7621 24.7667C25.0785 24.4557 25.0785 23.9509 24.7628 23.6399ZM10.5181 19.1092C5.60289 19.1092 1.61836 15.1879 1.61836 10.3509C1.61836 5.51376 5.60289 1.59254 10.5181 1.59254C15.4333 1.59254 19.4178 5.51376 19.4178 10.3509C19.4178 15.1879 15.4333 19.1092 10.5181 19.1092Z" fill="black"/>
                        </svg>
                        <input type="text" placeholder="검색" v-model="searchQuery">
                    </div>
                </div>
                <div class="box">
                    <router-link to="/admin/videomanage/register" class="register">영상 등록하기</router-link>
                </div>
            </div>
        </div>
        <div>
            <div class="tabs">
                <button v-for="category in categories" :key="category" @click="selectCategory(category)"
                        :class="{ active: selectedCategory === category }">
                    {{ category }}
                </button>
            </div>
            <div v-if="selectedCategory" class="filter">
                <div class="tag-buttons">
                    <button 
                    v-for="tag in tags" 
                    :key="tag" 
                    @click="toggleTag(tag)"
                    :class="{ active: selectedTags.includes(tag) }">
                    {{ tag }}
                    </button>
                </div>
            </div>
            <div class="video-list">
                <template v-if="videos.length > 0">
                    <div v-for="video in videos" :key="video._id" class="video-item">
                        <router-link :to="`/admin/videomanage/show/${video._id}`" class="a">
                            <div class="image-wrap">
                                <img :src="video.thumbnail" alt="thumbnail" class="thumbnail"/>
                                <div class="video-length">{{ video.video_length }}</div>
                            </div>
                            <div class="video-info">
                                <h3>{{ video.video_title }}</h3>
                                <div class="tags">
                                    <span v-for="tag in video.tag" :key="tag" class="tag">{{ tag }}</span>
                                </div>
                                <hr>
                                <p>{{ video.client }}</p>
                            </div>
                        </router-link>
                        <div>
                            <router-link :to="`/admin/videomanage/show/${video._id}`" class="edit">수정</router-link>
                            <button @click="deleteVideo(video._id, video.thumbnail)" class="delete">삭제</button>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <p class="none">아직 등록된 영상이 없습니다!</p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
    name: "VideoManage",
    setup() {
        const categories = ['전체', '교육', '홍보', '광고/바이럴', '유튜브', '기타'];
        const tags = [
        '2D 모션 그래픽', '3D 모션 그래픽', '인포그래픽', '실사', 'VR', '인터뷰',
        '라이브', '드라마타이즈', '스톡', '웹예능', '스케치'
        ];

        const selectedCategory = ref('전체');
        const selectedTags = ref([]);

        const videos = ref([]);

        const fetchVideos = async () => {
            try {
                const { data } = await axios.get('/api/admin/video/filter-video', {
                    params: {
                        category: selectedCategory.value === '전체' ? '' : selectedCategory.value,
                        tags: selectedTags.value.length === 0 ? '' : selectedTags.value.join(',')
                    }
                });
                videos.value = data;
            } catch (error) {
                console.error('비디오를 찾는 중 오류가 발생했습니다. :', error);
            }
        };

        const selectCategory = (category) => {
            selectedCategory.value = category;
            if (category === '전체') {
                selectedTags.value = [];
            } else {
                selectedTags.value = [];
            }
            fetchVideos();
        };

        const toggleTag = (tag) => {
            if (selectedTags.value.includes(tag)) {
                selectedTags.value = selectedTags.value.filter(t => t !== tag);
            } else {
                selectedTags.value.push(tag);
            }
            fetchVideos();
        };

        
        const deleteVideo = async(videoId, thumbnailUrl) =>{
            try{
                await axios.delete(`/api/admin/video/delete-portfolio/${videoId}?thumbnailUrl=${encodeURIComponent(thumbnailUrl)}`);
                fetchVideos(); // 비디오 목록 갱신
            }catch (error){
                console.error('썸네일 삭제 중 오류가 발생했습니다 : ', error);
            }
        };
        onMounted(fetchVideos);

        return {
            categories,
            tags,
            selectedCategory,
            selectedTags,
            videos,
            selectCategory,
            toggleTag,
            deleteVideo
        };
    },
};
</script>

<style scoped>
.a{
    text-decoration: none;
}
.inner {
    padding: 50px;
}
.top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.top h4 {
    margin-left: 65px;
    font-size: 24px;
    font-weight: 800;
}
.toggle-search {
    display: flex;
    align-items: center;
    margin-bottom: 38px;
}

.toggle-search button {
    width: 132px;
    height: 48px;
    margin-right: 28px;
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #F4F4F4;
    color: black;
    background-color: transparent;
}

.search-box {
    position: relative;
}

.search-box input {
    width: 300px;
    height: 42px;
    border-radius: 30px;
    border: 2px solid #000;
    padding: 5px 60px;
    padding-right: 30px;
}

.search-box svg{
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #999;
}
.box{
    width: fit-content;
    
}
.register{
    align-self: flex-start;
    border-radius: 20px;
    background: var(--vdcolor);
    color: white;
    padding: 10px 55px;
    text-decoration: none;
    margin-left: 256px;
}

.tabs button {
    margin: 5px;
    padding: 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    transition: background-color 0.3s ease;
    width: 121px;
    height: 56px;
}
.tabs button.active {
    background-color: #F9F9F9;;
    color: black;
    font-weight: 700;
    border-radius: 5px;
}
.filter {
    margin: 10px 0;
}
.tag-buttons button {
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    width: 150px;
    height: 38px;
    border-radius: 10px;
    border: 1px solid #F9F9F9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: transparent;
    transition: border 0.3s ease;
}
.tag-buttons .active {
    border: 1px solid black;
    font-weight: 600;
    transition-duration: 0.2s;
}
.video-list {
    display: flex;
    flex-wrap: wrap;
}
.video-item {
    margin: 45px 10px;
    padding: 10px;
    /* 썸네일 가로 크기보다 5더 큼 */
    width: 293px;
    /* 썸네일 세로 길이보다 128 */
    height: 290px;
}
.image-wrap{
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}
/* 16:9 비율 이미지 크기*/
.thumbnail {
    width: 304px;
    height: 171px;
    transition-duration: 0.2s;
}
.thumbnail:hover{
    transform: scale(1.2, 1.2);
    transition-duration: 0.4s;
}
.video-info {
    margin-top: 10px;
}
.video-info h3 {
    height: 38px;
    color: #1A1A1A;
    font-size: 17px;
    font-weight: 600;
}
.video-length {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 14px;
    z-index: 1; /* 이미지 위에 동영상 시간 올리기 */
    pointer-events: none; /* 이미지가 움직여도 움직이지 않게 하기 */
}
.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px; /* Space between tags */
    margin-top: 5px;
}

.tag {
    background-color: transparent;
    color: #333;
    padding: 4px 9px;
    border-radius: 5px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #808080;
}
.video-info hr{
    margin: 10px 0;
}
.video-info p{
    color: #4C4949;
    font-size: 14px;
    font-weight: 400;
}
.none{
    margin:40px;
    font-weight: 600;
    font-size: 17px;
    
}
.edit{
    padding: 4px 25px;
    text-decoration: none;
    border-radius: 5px;
    background: var(--vdcolor);
    color: white;
    border: none;
    margin-right: 10px;
}
.delete{
    width: 80px;
    height: 30px;
    border-radius: 5px;
    background: red;
    color: white;
    border: none;
}
/* @media (max-width: 1374px) {
    .inner {
        min-width: 1374px;
        overflow-x: auto;
    }
    .header, .controls, .toggle-search, .register, .tabs, .filter, .tag-buttons, .video-list, .video-item, .thumbnail, .video-info {
        min-width: auto;
    }
} */

</style>
