<template>
    <div class="AdminDashboard">
        <div class="dashboard__wrap">
        <div class="dashboard__col">
            <div class="step">
                <img class="step__img" src="@/assets/svgs/admin/step1.svg" alt="icon">
                <span class="step__title">미확인</span>
                <span class="step__count">{{ unidentifiedsCount }}</span>
            </div>
            <div class="dashboard__content">
                <div class="dashboard__item" v-for="unidentified in unidentifieds" :key="unidentified._id" @click="goDetail(unidentified._id)">
                    <div class="item__title-area">
                        <strong class="item__title">{{ unidentified.project_title }}</strong>
                        <span class="item__company">{{ unidentified.company_name}}</span>
                    </div>
                    <div class="item__date">{{ unidentified.created_at }}</div>
                </div>
            </div>
        </div>
        <div class="dashboard__col">
            <div class="step">
                <img class="step__img" src="@/assets/svgs/admin/step2.svg" alt="icon">
                <span class="step__title">견적 전송 완료</span>
                <span class="step__count">{{ quotationsCount }}</span>
            </div>
            <div class="dashboard__content">
                <div class="dashboard__item" v-for="quotation in quotations" :key="quotation._id" @click="updateDetail(quotation._id)">
                    <div class="item__title-area">
                        <strong class="item__title">{{ quotation.project_title }}</strong>
                        <span class="item__company">{{ quotation.company_name}}</span>
                    </div>
                    <div class="item__date">{{ quotation.created_at }}</div>
                </div>
            </div>
            
        </div>
        <div class="dashboard__col">
            <div class="step">
            <img class="step__img" src="@/assets/svgs/admin/step3.svg" alt="icon">
            <span class="step__title">미팅 진행</span>
            <span class="step__count">5</span>
            </div>
            <div class="dashboard__content">
            <div class="dashboard__item">
                <div class="item__title-area">
                <strong class="item__title">프로젝트</strong>
                <span class="item__company">회사명</span>
                </div>
                <div class="item__date">2024.06.01</div>
            </div>
            <div class="dashboard__item">
                <div class="item__title-area">
                <strong class="item__title">프로젝트</strong>
                <span class="item__company">회사명</span>
                </div>
                <div class="item__date">2024.06.01</div>
            </div>
            <div class="dashboard__item">
                <div class="item__title-area">
                <strong class="item__title">프로젝트</strong>
                <span class="item__company">회사명</span>
                </div>
                <div class="item__date">2024.06.01</div>
            </div>
            </div>
        </div>
        <div class="dashboard__col">
            <div class="step">
            <img class="step__img" src="@/assets/svgs/admin/step4.svg" alt="icon">
            <span class="step__title">계약 완료</span>
            <span class="step__count">5</span>
            </div>
            <div class="dashboard__content">
            <div class="dashboard__item">
                <div class="item__title-area">
                <strong class="item__title">프로젝트</strong>
                <span class="item__company">회사명</span>
                </div>
                <div class="item__date">2024.06.01</div>
            </div>
            <div class="dashboard__item">
                <div class="item__title-area">
                <strong class="item__title">프로젝트</strong>
                <span class="item__company">회사명</span>
                </div>
                <div class="item__date">2024.06.01</div>
            </div>
            <div class="dashboard__item">
                <div class="item__title-area">
                <strong class="item__title">프로젝트</strong>
                <span class="item__company">회사명</span>
                </div>
                <div class="item__date">2024.06.01</div>
            </div>
            <div class="dashboard__item">
                <div class="item__title-area">
                <strong class="item__title">프로젝트</strong>
                <span class="item__company">회사명</span>
                </div>
                <div class="item__date">2024.06.01</div>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

const router = useRouter();

// 견적 미확인
const unidentifieds = ref([]);
const unidentifiedsCount = ref(0);

// 견적 전송 완료
const quotations = ref([]);
const quotationsCount = ref(0);


onMounted(async () => {
    try {
        const { data: unidentified } = await axios.get('/api/admin/estimate/show-unidentified');
        unidentifieds.value = unidentified;
        if(unidentifieds.value.message !== "해당하는 프로젝트가 없습니다."){
            unidentifiedsCount.value = unidentifieds.value.length;
        }

        const { data : quotation } = await axios.get('/api/admin/estimate/send-quotation');
        quotations.value = quotation;
        if(quotations.value.message !== "해당 프로젝트를 찾을 수가 없습니다."){
            quotationsCount.value = quotations.value.length;
        }


    } catch (error) {
        console.error('미확인 프로젝트를 찾는 도중 오류가 발생했습니다. :', error);
    }
});


const goDetail = (projectId) => {
    router.push(`/admin/inquirydetail/${projectId}/show`);
}
const updateDetail = (projectId) =>{
    router.push(`/admin/inquirydetail/${projectId}/comment-show`);
}
</script>



<style scoped>
.AdminDashboard {
display: flex;
min-height: calc(100vh - 68px);
}
.dashboard__wrap {
display: flex;
gap: 40px;
width: 100%;
padding: 41px 41px 54px 41px;
}
.dashboard__col {
display: flex;
flex-direction: column;
width: 100%;
}
.step {
display: flex;
align-items: center;
width: 100%;
height: 70px;
padding: 12px 22px 12px 16px;
border-radius: 20px;
background: #0041D61A;
}
.step__img {
width: 40px;
height: 40px;
margin-right: 12px;
}
.step__title {
font-size: 17px;
font-weight: 600;
color: #000;
}
.step__count {
margin-left: auto;
font-size: 17px;
font-weight: 600;
color: #000;
}
.dashboard__content {
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 10px;
    margin-top: 16px;
    background: #D9D9D9;
}
.dashboard__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    padding: 12px 16px;
    border-radius: 10px;
    font-size: 14px;
    color: #000;
    background: #fff;
    cursor: pointer;
}
.dashboard__item + .dashboard__item {
margin-top: 12px;
}
.item__title-area {
display: flex;
justify-content: space-between;
align-items: center;
}
.item__date {
text-align: right;
}

@media (max-width: 1316px) {
    .dashboard__wrap {
        flex-direction: column;
        gap: 20px;
    }
    .dashboard__col {
        width: 100%;
    }
    .step {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        padding: 16px;
    }
    .step__img {
        margin-bottom: 8px;
    }
    .step__title, .step__count {
        font-size: 15px;
    }
    .dashboard__content {
        padding: 12px;
    }
    .dashboard__item {
        flex-direction: row;
        align-items: center;
        height: auto;
        padding: 8px;
    }
    .item__title-area {
        flex-direction: column;
        align-items: flex-start;
    }
    .item__date {
        margin-top: 4px;
        text-align: left;
    }
}

</style>
