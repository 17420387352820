<template>
    <svg
        id="Capa_1"
        height="32"
        viewBox="0 0 512 512"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >

        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" x2="512" y1="256" y2="256">
        <stop offset="0" stop-color="#9dd6fc" />
        <stop offset=".0056" stop-color="#9dd6fc" />
        <stop offset="1" stop-color="#d17ff7" />
        </linearGradient>
        <g>
            <g>
                <g>
                    <path
                        d="m412 320h-156-156c-55.14 0-100 44.86-100 100v72c0 11.046 8.954 20 20 20h472c11.046 0 20-8.954 20-20v-72c0-55.14-44.86-100-100-100zm60 152h-40v-52c0-11.046-8.954-20-20-20s-20 8.954-20 20v52h-272v-52c0-11.046-8.954-20-20-20s-20 8.954-20 20v52h-40v-52c0-33.084 26.916-60 60-60h312c33.084 0 60 26.916 60 60zm-96-272v-140c0-33.084-26.916-60-60-60h-200c-11.046 0-20 8.954-20 20v40c0 26.073 16.722 48.304 40 56.56v83.44c0 66.058 53.724 120 120 120 66.168 0 120-53.832 120-120zm-40 0c0 44.112-35.888 80-80 80-29.546 0-55.383-16.112-69.237-40h49.237c11.046 0 20-8.954 20-20s-8.954-20-20-20h-60v-80h160zm0-120h-180c-11.028 0-20-8.972-20-20v-20h180c11.028 0 20 8.972 20 20z"
                        fill="url(#SVGID_1_)"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'UserImg',
    };
</script>

<style scoped>
svg{
    cursor: pointer;
}
</style>
