<template>
    <div>
        <Header/>
            블로그 입니다.
        <Footer/>
    </div>
</template>

<script>
import Header from '../commons/Header.vue';
import Footer from '../commons/Footer.vue';

export default {
    name : 'Blog',
    components :{
        Header,
        Footer,
    }

}
</script>

<style scoped>

</style>