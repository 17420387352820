<template>
    <footer>
        <div class="inner">
            <div class="context">
                <div class="box">
                    <h6>고객센터</h6>
                    <p>평일 9:00 - 17:00 (주말, 공휴일 제외) <br><br>
                    vd@crossd.org</p>
                    <button>1:1 문의하기</button>
                </div>
                
                <div class="list">
                    <ul>
                        <p>VD</p>
                        <li>서비스 소개</li>
                        <li>채용</li>
                        <li>블로그</li>
                    </ul>
                    <ul>
                        <p>포트폴리오</p>
                        <li>교육 영상</li>
                        <li>홍보 영상</li>
                        <li>광고/바이럴 영상</li>
                        <li>유튜브 영상</li>
                    </ul>
                    <ul>
                        <p>고객안내</p>
                        <li>도움말</li>
                        <li>공지사항</li>
                    </ul>
                </div>

                <div class="text">
                    <a href="#">이용 약관</a>
                    <a href="#">개인정보 처리방침</a>
                    <a href="#">제휴/협력 문의</a>
                    <p>(주)트루빈스 | 대표 정현희 | 서울특별시 강남구 봉은사로 417 구구빌딩 3F(선정릉역) | 연락처 : 02-6959-9657 | 사업자등록번호 : 129-86-75086 <br>
                    Copyrights © {{ currentYear }} 트루빈스 INC. ALL RIGHTS RESERVED.</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { ref } from 'vue';

export default {
    name : 'Footer',
    setup(){
        const currentYear = ref(new Date().getFullYear());

        // 매년 현재 연도를 업데이트하는 computed 속성
        const updateYear = () => {
            currentYear.value = new Date().getFullYear();
        };

        // 1월 1일마다 연도 업데이트
        setInterval(updateYear, 1000 * 60 * 60 * 24); // 매년 업데이트

        return {
            currentYear
        };
    }

}
</script>

<style scoped>
.inner{
    width: 100%;
    height: 372px;
    background: #000;
}
.context{
    position: relative;
    height: 100%;
    width: 100%;
    min-width: 920px;
}
.box {
    position: absolute;
    top: 37px;
    left: 45px;
}
.box h6{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
}
.box p{
    color: #B3B3B3;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
}
.box button{
    width: 326px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #F9F9F9;
    background-color: black;
    color: #F9F9F9;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
}
.list {
    position: absolute;
    top: 37px;
    right: 45px;
    display: flex;
}
.text{
    position: absolute;
    bottom: 37px;
    left: 45px;
}
.list p{
    color: #FFF;
    font-size: 16px;
    font-weight: 700;
}
.list ul{
    margin-right: 40px;
}
.list li{
    color: #B3B3B3;
    font-size: 16px;
    line-height: 30px;
    list-style-type: none;
    font-weight: 200;
}
.text a{
    color: #999;
    font-size: 12px;
    font-weight: 400;
    margin-right: 40px;
    text-decoration: none;
}
.text p{
    color: #808080;
    font-size: 10px;
    font-weight: 400;
}

</style>