<template>
    <div class="sidebar">
        <ul>
            <li>
                <router-link to="/admin" exact-active-class="active-link" exact>
                <img src="@/assets/imgs/dashboard.png" alt="Dashboard Icon">
                <span>홈</span>
                </router-link>
            </li>
            <hr>
            <li class="side">
                <p>견적 문의</p>
                <router-link to="/admin/inquirydetail" active-class="active-link">
                <span>문의 내역 확인</span>
                </router-link>
            </li>
            <li class="side"> 
                <p>포트폴리오</p>
                <router-link to="/admin/videomanage" active-class="active-link">
                <span>포트폴리오 영상 관리</span>
                </router-link>
            </li>
            <li class="side">
                <p>블로그</p>
                <router-link to="/admin/postlist" active-class="active-link">
                <span>포스트 관리</span>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Sidebar",
    };
</script>

<style scoped>
.sidebar {
    width: 278px;
    min-height: 100vh;
    border-right: 1px solid black;
}

ul {
    list-style-type: none;
    padding: 16px;
}

li img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}
li span{
    font-size: 17px;
    font-weight: 600;
}
.active-link {
    border: 1px solid rgba(101, 227, 255, 0.15);
    background: rgba(101, 227, 255, 0.15);
    border-radius: 5px;
    width: 241px;
    height: 45px;
    
}
hr {
    border: none;
    border-top: 1px solid black;
    margin: 8px 0;
}
li p{
    color: #808080;
    font-size: 15px;
    font-weight: 600;
    padding-top: 20px;
}
.side{
    padding-bottom: 80px;
}
.side span{
    padding-left: 20px;
}
</style>
