import { createWebHistory, createRouter } from "vue-router";
import store from './store';

import Home from "./components/Home.vue";
import Estimate from "./components/format/Estimate.vue";
import EstimateStep from "./components/format/EstimateStep.vue";
import EstimateFinal from "./components/format/EstimateFinal.vue";
import Portfolio from "./components/portfolios/Portfolio.vue";
import Blog from "./components/blogs/Blog.vue";
import Login from "./components/login/Login.vue";
import SignUp from './components/login/SignUp.vue';
import Dashboard from './components/admin/Dashboard.vue';
import InquiryDetail from './components/admin/InquiryDetail.vue';
import VideoManage from './components/admin/VideoManage.vue';
import PostList from './components/admin/post/PostList.vue';
import AdminHome from './components/admin/AdminHome.vue';
import VideoRegister from './components/admin/VideoRegister.vue';
import VideoEdit from './components/admin/VideoEdit.vue';
import VideoShow from './components/admin/VideoShow.vue';
import DetailShow from './components/admin/Inquiry/DetailShow.vue';
import PortfolioDetail from './components/portfolios/PortfolioDetail.vue';
import ProjectDetail from './components/user/ProjectDetail.vue';

const routes = [
    // 로고에 붙는 메인 페이지로 돌아가기
    {
        path : "/",
        component : Home,
    },
    // 견적받기 첫 스텝 (총 9개)
    {
        path : "/estimate",
        component : Estimate,
    },
    {
        path: "/estimate/:step",
        component: EstimateStep,
    },
    {
        path: "/estimate/final",
        component: EstimateFinal,
    },
    {
        path : "/estimate/complete/:user_id",
        component : () => import('./components/format/EstimateComplete.vue')
    },
    // 포트폴리오 보러가기 페이지
    {
        path : "/portfolio",
        component : Portfolio,
    },
    {
        path : "/portfolio/:video_id",
        component : PortfolioDetail,
    },
    // 블로그 페이지
    {
        path : "/blog",
        component : Blog,
    },
    // 블로그 포스트 디테일하게 확인하기
    {
        path : "/blog/:post_id",
        component : () => import('./components/blogs/BlogDetail.vue')
    },
    // 로그인 페이지
    {
        path : "/login",
        component : Login,
    },
    // 회원가입 페이지
    {
        path : "/signup",
        component : SignUp
    },
    // 관리자 페이지
    {
        path : "/admin",
        component : AdminHome,
        meta: { requiresAdmin: true },
        children:[
            {
                path : "",
                component : Dashboard
            },
            {
                path : "inquirydetail",
                children :[
                    {
                        path : "",
                        component : InquiryDetail
                    },
                    {
                        path : ":project_id/show", // 이거 댓글 입력하면서 견적서 같이 자세히 볼 수 있는 화면임
                        component : DetailShow
                    },
                    {
                        path : ":project_id/comment-show",
                        component : () => import('./components/admin/Inquiry/CommentShow.vue')
                    },
                    {
                        path : ":project_id/:comment_id/comment-update",
                        component : () => import('./components/admin/Inquiry/CommentUpdate.vue')
                    }
                ]
            },
            {
                path : "videomanage",
                component : VideoManage,
            },
            {
                path : "postlist",
                component : PostList
            }
        ]
    },
    // 관리자 페이지 포트폴리오 등록 화면
    {
        path : "/admin/videomanage/register",
        component : VideoRegister,
        meta: { requiresAdmin: true }
    },
    // 관리자 페이지 포트폴리오 수정 화면
    {
        path : "/admin/videomanage/edit/:video_id",
        component : VideoEdit,
        meta: { requiresAdmin: true }
    },
    // 관리자 페이지 한개의 포트폴리오 자세한 정보 확인 화면
    {
        path : "/admin/videomanage/show/:video_id",
        component : VideoShow,
        meta: { requiresAdmin: true }
    },
    // 관리자 페이지 포스트 관리
    {
        path : "/admin/postlist/register",
        component : () => import('./components/admin/post/PostRegister.vue'),
        meta: { requiresAdmin: true }
    },
    // 유저가 자기가 제출한 견적서의 자세한 내용을 볼 수 있는 화면
    {
        path : "/:user_id/project-detail/:project_id",
        component : ProjectDetail,
        meta: { requiresAuth: true }
    },
    // 견적 비교 페이지
    {
        path : "/:user_id/project-detail/:project_id/compare",
        component : () => import('./components/user/CompareEstimate.vue'),
        meta: { requiresAuth: true }
    },
    // 내가 문의한 프로젝트 보는 화면
    {
        path : "/:user_id/project-list",
        component : () => import('./components/user/ProjectList.vue'),
        meta: { requiresAuth: true }
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated;
    const user = store.getters.user;

    if (to.path.startsWith('/estimate/complete') && from.path !== '/estimate/final') {
        // /estimate/final에서 오지 않은 경우 /estimate/complete으로 접근을 막음
        next({path : from.fullPath});
        return;
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!isAuthenticated) {
            next({ path: '/login', query: { redirect: to.fullPath } });
            return;
        }
    }

    if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (isAuthenticated && user && user.role === 'admin') {
            next();
        } else {
            next({ path: '/', query: { redirect: to.fullPath } });
        }
    } else {
        next();
    }
});


export default router;
