<template>
    <div class="reset">
        <div class="box">
            <img src="@/assets/imgs/closeButton.png" alt="Close Button" @click="$emit('closeModal')"/>
            <h4>비밀번호 재설정</h4>
            <p>VD에 가입한 이메일 주소를 입력해주세요. <br> 비밀번호 초기화를 위한 인증번호를 보내드려요.</p>
            <div class="form">
                <div class="write-title">이메일</div>
                <input class="write" type="text" placeholder="vd@example.com" style="width: 239px; margin-right: 12px;">
                <button>전송</button>
            </div>

            <div>
                <div class="write-title">인증 번호 입력</div>
                <input class="write" type="text" placeholder="인증 번호를 입력하세요">
            </div>


            <div>
                <div class="write-title">새로운 비밀번호</div>
                <input class="write" type="text" placeholder="******">
            </div>


            <div>
                <div class="write-title">새로운 비밀번호 확인</div>
                <input class="write" type="text" placeholder="******">
            </div>
            <button>확인</button>
        </div>
    </div>
</template>

<script>
export default {
    name : 'ModalrestPassword',
    props:{
        isModalOpen:{
            type : Boolean,
            required : true,
        }
    },
    setup(){

    }
}
</script>

<style scoped>
.reset{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 투명한 검은 배경 */
    z-index: 1000; /* 다른 요소 위에 보이도록 */
    display: flex;
    justify-content: center;
    align-items: center;
}
.box{
    width: 400px;
    height: 646px;
    border-radius: 5px;
    background: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden; /* 스크롤 막기 */
}
.box img{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}
.box h4{
    font-weight: 900;
    font-size: 18px;
}
.box p{
    color: #808080;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.box button{
    width: 325px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #EEE;
    border: none;
}
.form{
    margin: 0 auto;
}
.form button{
    width: 74px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background: rgba(217, 217, 217, 0.40);
    border: none;
}
.write{
    width: 326px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    margin-bottom: 20px;
    padding-left: 10px;
}
.write::placeholder{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-40%);
    font-size: 14px;
}
.write-title{
    margin: 0;
    font-size: 16px;
    
}
</style>