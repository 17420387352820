<template>
    <div>
        <Header/>
            <div class="inner">
                <div class="box">
                    <p class="title">VD Studio</p>
                    <div class="form">
                        <p class="write-title">이름</p>
                        <input class = "write" type="text" v-model="username" placeholder="이름을 입력해주세요." maxlength="15">
                    </div>
                    <div>
                        <p class="write-title">연락처</p>
                        <input class = "write" type="text" v-model="phoneNumber" maxlength="13" @input="applyFormat" placeholder="010-0000-0000">
                    </div>
                    <div>
                        <p class="write-title">이메일 <span style="color : #0041D6">영상 제작 관련 중요한 메일을 받을 주소에요.</span></p>
                        <input class="write" type="email" v-model="email" @input="validateEmail" :class="{ 'invalid-input': invalidEmail && email !== '' }" placeholder="vd@example.com"/>
                        <p v-if="invalidEmail && email !== ''" style="color: red;">이메일 형식이 올바르지 않습니다!</p>
                    </div>
                    <div>
                        <p class="write-title">비밀번호 <span>(8자리 이상, 영문+숫자 조합)</span></p>
                        <input class="write" type="password" v-model="password" placeholder="********" @input="validatePassword" :class="{ 'invalid-input': invalidPassword }"/>
                        <p v-if="invalidPassword" style="color : red;">비밀번호 형식이 올바르지 않습니다!</p>
                    </div>
                    <div>
                    <p class="write-title">비밀번호 확인</p>
                        <input class="write" type="password" v-model="checkPassword" placeholder="********" @input="checkSamePassword" :class="{ 'invalid-input': ischeckPassword }"/>
                        <p v-if="ischeckPassword" style="color : red;">비밀번호가 일치하지 않습니다!</p>
                    </div>

                    <div class="wrapper">
                        <button class="loginBtn" @click="signupButton">계속</button>
                    </div>

                    <div class="container">
                        <hr><span>또는</span><hr>
                    </div>
                    <div class="move">
                        이미 두둠 계정이 있으신가요? &nbsp;
                        <router-link to="/login" style="color: #0041D6;">로그인</router-link>
                    </div>
                </div>
            </div>
        <Footer/>
    </div>
</template>

<script>
import { ref } from 'vue';
import Header from '../commons/Header.vue';
import Footer from '../commons/Footer.vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'SignUP',
    components: {
        Header,
        Footer,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const username = ref('');
        const phoneNumber = ref('');
        const email = ref('');
        const password = ref('');
        const checkPassword = ref('');

        // 형식 검사 boolean
        const invalidEmail = ref(false);
        const invalidPassword = ref(false);
        const ischeckPassword = ref(false);

        // 연락처 형식 검사
        const applyFormat = (event) => {
            let value = event.target.value;
            // 사용자가 입력한 값에서 숫자만 추출
            let numberOnly = value.replace(/\D/g, '');
            // 숫자를 포맷에 맞게 조정
            let formattedNumber = '';
            for (let i = 0; i < numberOnly.length; i++) {
                if (i === 3 || i === 7) {
                    formattedNumber += '-';
                }
                formattedNumber += numberOnly[i];
            }

            // 최대 길이 제한을 넘지 않도록
            event.target.value = formattedNumber.substring(0, 13);
            // phoneNumber 상태 업데이트
            phoneNumber.value = formattedNumber;
        };

        // 이메일 형식 검사
        const validateEmail = () => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            invalidEmail.value = !emailRegex.test(email.value);
        };

        // 비밀번호 형식 검사
        const validatePassword = () => {
            const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
            invalidPassword.value = !passwordRegex.test(password.value);
        };

        // 비밀번호 동일 확인
        const checkSamePassword = () => {
            ischeckPassword.value = password.value !== checkPassword.value;
        };

        const signupButton = async () => {
            if (!invalidEmail.value && !invalidPassword.value && !ischeckPassword.value && username.value !== '' && phoneNumber.value !== '' && email.value !== '' && password.value !== '') {
                try {
                    const res = await store.dispatch('signupAndSignin', {
                        username: username.value,
                        phoneNumber: phoneNumber.value,
                        email: email.value,
                        password: password.value,
                    });
                    if (res) {
                        alert(res.message);
                        router.push('/');
                    }
                } catch (error) {
                    if (error.response && error.response.data && error.response.data.error) {
                        alert(error.response.data.error);
                    } else {
                        alert('서버와 통신 중 오류가 발생했습니다.');
                    }
                }
            } else {
                alert('올바른 정보 기입이 필요합니다.');
            }
        };

        return {
            username,
            phoneNumber,
            email,
            password,
            checkPassword,
            invalidEmail,
            invalidPassword,
            ischeckPassword,
            applyFormat,
            validateEmail,
            validatePassword,
            checkSamePassword,
            signupButton
        };
    }
};
</script>


<style scoped>
.inner{
    position: relative;
    width: 100%;
    height : 900px;
}
.box{
    width: 450px;
    height: 830px;
    flex-shrink: 0;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0 auto;
    margin-top : 100px;
    position : relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.title{
    color: #000;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}
.form{
    margin: 0 auto;
}
.write{
    width: 326px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    margin-bottom: 20px;
    padding-left: 10px;
}
.write::placeholder{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-40%);
}
.write-title{
    margin: 0;
}
.write-title span{
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.loginBtn{
    width: 326px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--vdcolor);
    color: white;
    border: none;
}
.move{
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
}
.container hr{
    width: 138px;
    height: 1px;
    background-color: #C8C8C8;
    margin:0 10px;
}
.write-title span {
    margin: 0 5px;
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.wrapper{
    position: relative;
    margin-top: 30px;
}

.forget{
    color: #808080;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    position: absolute;
    top: -18px;
    right: 0;
}
.invalid-input {
  border: 2px solid red; /* 유효하지 않은 이메일 형식일 때 빨간색 테두리를 추가합니다. */
}
</style>