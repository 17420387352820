<template>
    <div class="check-signup">
        <div class="box">
            <div class="request">
                <div class="top">
                    <svg @click="$emit('closed')" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
                        <g clip-path="url(#clip0_138_175)">
                            <path d="M0.608044 18.2583L6.923 24.7344C7.37696 25.2041 8.11622 25.2075 8.57428 24.7419C9.03228 24.2764 9.03558 23.5182 8.58161 23.0485C8.57917 23.046 8.57672 23.0434 8.57421 23.0409L4.25252 18.6089L32.9261 18.6089C33.571 18.609 34.0938 18.0729 34.0938 17.4116C34.0938 16.7503 33.571 16.2142 32.9261 16.2142L4.25252 16.2142L8.57421 11.7822C9.03373 11.3182 9.03948 10.5601 8.5871 10.0888C8.13465 9.61759 7.3954 9.6117 6.93588 10.0756C6.93159 10.08 6.92729 10.0844 6.923 10.0888L0.608044 16.5649C0.152027 17.0325 0.152027 17.7907 0.608044 18.2583Z" fill="black"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_138_175">
                                <rect width="33.8278" height="34.6907" fill="white" transform="matrix(-1 0 0 -1 34.0938 34.7559)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <h4>견적 요청을 위해 <br><span style="color: var(--vdcolor);">회원가입 및 로그인</span>이 필요해요</h4>
                    <p>가입 후 실시간 지원 현황을 확인하실 수 있어요.</p>
                </div>
                <div class="bottom" v-if="!loginCheck">
                    <div class="form">
                        <p class="write-title">이름</p>
                        <input class = "write" type="text" v-model="username" placeholder="이름을 입력해주세요." maxlength="15">
                    </div>
                    <div>
                        <p class="write-title">연락처</p>
                        <input class = "write" type="text" v-model="phoneNumber" maxlength="13" @input="applyFormat" placeholder="010-0000-0000">
                    </div>
                    <div>
                        <p class="write-title">이메일 <span style="color : #0041D6">영상 제작 관련 중요한 메일을 받을 주소에요.</span></p>
                        <input class="write" type="email" v-model="email" @input="validateEmail" :class="{ 'invalid-input': invalidEmail && email !== '' }" placeholder="vd@example.com"/>
                        <p v-if="invalidEmail && email !== ''" style="color: red;">이메일 형식이 올바르지 않습니다!</p>
                    </div>
                    <div>
                        <p class="write-title">비밀번호 <span>(8자리 이상, 영문+숫자 조합)</span></p>
                        <input class="write" type="password" v-model="password" placeholder="********" @input="validatePassword" :class="{ 'invalid-input': invalidPassword }"/>
                        <p v-if="invalidPassword" style="color : red;">비밀번호 형식이 올바르지 않습니다!</p>
                    </div>
                    <div>
                    <p class="write-title">비밀번호 확인</p>
                        <input class="write" type="password" v-model="checkPassword" placeholder="********" @input="checkSamePassword" :class="{ 'invalid-input': ischeckPassword }"/>
                        <p v-if="ischeckPassword" style="color : red;">비밀번호가 일치하지 않습니다!</p>
                    </div>

                    <div class="wrapper">
                        <button class="loginBtn" @click="signupButton">계속</button>
                    </div>

                    <div class="container">
                        <hr><span>또는</span><hr>
                    </div>
                    <div class="move">
                        이미 두둠 계정이 있으신가요? &nbsp;
                        <div class="change" @click="loginCheck = true" style="color: #0041D6; cursor: pointer;">로그인</div>
                    </div>
                </div>
                <div class="bottom" v-else>
                    <div class="form">
                        <p class="write-title">이메일</p>
                        <input class = "write" type="text" v-model="email" placeholder="vd@gmail.com">
                    </div>
                    <div>
                        <p class="write-title">비밀번호 <span>(8자리 이상, 영문+숫자 조합)</span></p>
                        <input class="write" type="password" v-model="password" placeholder="********"/>
                    </div>
                    <div class="wrapper">
                        <button class="loginBtn" @click="login" :disabled="loading">
                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            로그인
                        </button>
                    </div>
                    <div class="container">
                        <hr><span>또는</span><hr>
                    </div>
                    <div class="move">
                        아직 회원이 아니신가요? &nbsp;
                        <div class="change" @click="loginCheck = false" style="color: #0041D6; cursor: pointer;">1분만에 회원가입</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineEmits } from 'vue';
import { useStore } from 'vuex';

onMounted(() => {
    document.body.style.overflow = 'hidden';
});

onBeforeUnmount(() => {
    document.body.style.overflow = '';
});

const store = useStore();
const loginCheck = ref(false);

const username = ref('');
const phoneNumber = ref('');
const email = ref('');
const password = ref('');
const checkPassword = ref('');
const loading = ref(false);

// 형식 검사 boolean
const invalidEmail = ref(false);
const invalidPassword = ref(false);
const ischeckPassword = ref(false);

// 연락처 형식 검사
const applyFormat = (event) => {
    let value = event.target.value;
    // 사용자가 입력한 값에서 숫자만 추출
    let numberOnly = value.replace(/\D/g, '');
    // 숫자를 포맷에 맞게 조정
    let formattedNumber = '';
    for (let i = 0; i < numberOnly.length; i++) {
        if (i === 3 || i === 7) {
            formattedNumber += '-';
        }
        formattedNumber += numberOnly[i];
    }

    // 최대 길이 제한을 넘지 않도록
    event.target.value = formattedNumber.substring(0, 13);
    // phoneNumber 상태 업데이트
    phoneNumber.value = formattedNumber;
};

// 이메일 형식 검사
const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    invalidEmail.value = !emailRegex.test(email.value);
};

// 비밀번호 형식 검사
const validatePassword = () => {
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    invalidPassword.value = !passwordRegex.test(password.value);
};

// 비밀번호 동일 확인
const checkSamePassword = () => {
    ischeckPassword.value = password.value !== checkPassword.value;
};


const emit = defineEmits(['close']);
const signupButton = async () => {
    if (!invalidEmail.value && !invalidPassword.value && !ischeckPassword.value && username.value !== '' && phoneNumber.value !== '' && email.value !== '' && password.value !== '') {
        try {
            const res = await store.dispatch('signupAndSignin', {
                username: username.value,
                phoneNumber: phoneNumber.value,
                email: email.value,
                password: password.value,
            });
            if (res) {
                alert(res.message);
                emit('close');
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.error) {
                alert(error.response.data.error);
            } else {
                alert('서버와 통신 중 오류가 발생했습니다.');
            }
        }
    } else {
        alert('올바른 정보 기입이 필요합니다.');
    }
};
const login = async () => {
            loading.value = true;
            try {
                    await store.dispatch('signin', {
                        email: email.value,
                        password: password.value,
                });

                if (!store.state.error) {
                    emit('close');
                } else {
                    alert(store.state.error);
                }
            } catch (error) {
                // 예외 상황이 발생했을 때 처리
                alert('로그인 요청 중 문제가 발생했습니다.');
                console.error(error);
            } finally{
                loading.value = false;
            }
        };
</script>

<style scoped>
.check-signup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    justify-content: flex-end; /* 오른쪽에 정렬 */
}

.box {
    position: relative; /* fixed 대신 relative 사용 */
    width: 40%;
    height: 100%;
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center; /* 수평 가운데 정렬 */
    align-items: center; /* 수직 가운데 정렬 */
    overflow-y: auto;
}

.request {
    /* border: 1px solid red; */
    padding: 2vh 5vw;
    width: fit-content;
    max-width: 600px;
    margin: auto; /* 모든 방향에 자동 마진을 설정하여 중앙에 배치 */
}

.top svg{
    cursor: pointer;
}
.top h4{
    font-size: 24px;
    font-weight: 600;
}
.top p{
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}
.bottom .form{
    margin: 0 auto;
}
.bottom .write{
    width: 326px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    margin-bottom: 20px;
    padding-left: 10px;
}
.bottom .write::placeholder{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-40%);
}
.bottom .write-title{
    margin: 0;
}
.bottom .write-title span{
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}
.bottom .loginBtn{
    width: 326px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--vdcolor);
    color: white;
    border: none;
}
.bottom .move {
    display: flex;
    justify-content: center;
    align-items: center; /* 가운데 정렬 추가 */
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    text-align: center; /* 텍스트를 가운데 정렬 */
    margin-top: 20px; /* 버튼과 구분하기 위한 여백 추가 */
}
.bottom .container {
    display: flex;
    flex-direction: column; /* 세로로 정렬 추가 */
    align-items: center; /* 가운데 정렬 추가 */
    margin: 30px auto;
}
.bottom .container hr{
    width: 138px;
    height: 1px;
    background-color: #C8C8C8;
    margin:0 10px;
}
.bottom .write-title span {
    margin: 0 5px;
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}
.bottom .wrapper {
    position: relative;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center; /* 가운데 정렬 추가 */
}

.bottom .forget{
    color: #808080;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none;
    position: absolute;
    top: -18px;
    right: 0;
}
.bottom .invalid-input {
  border: 2px solid red; /* 유효하지 않은 이메일 형식일 때 빨간색 테두리를 추가합니다. */
}

</style>
