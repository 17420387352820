<template>
    <svg
        id="Line"
        height="32"
        viewBox="0 0 32 32"
        width="32"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
        id="XMLID_41_"
        d="m27.707 26.293-5.969-5.969c1.412-1.724 2.262-3.926 2.262-6.324 0-5.514-4.486-10-10-10s-10 4.486-10 10 4.486 10 10 10c2.398 0 4.6-.85 6.324-2.262l5.969 5.969c.391.391 1.023.391 1.414 0s.391-1.023 0-1.414zm-21.707-12.293c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8-8-3.589-8-8z"
        fill="white"
        />
    </svg>
</template>

<script>
export default {
    name: "Search",
};
</script>

<style scoped>

</style>
