<template>
    <div class="wrapper">
        <Header/>
        <section class="banner">
            <p class="phrases">검증된 40+명의 전문가들과 함께한</p>
            <h3>VD의 <span>{{ videoCount }}개</span>의 포트폴리오를 둘러보세요</h3>
            <p class="explain">일일이 찾아볼 시간이 없으신가요?</p>
            <router-link to="/estimate" class="btn">무료로 견적 받아보기<Arrow class="arrow"/></router-link>
        </section>

        <section class="video">
            <div>
                <div class="tabs">
                    <button v-for="category in categories" :key="category" @click="selectCategory(category)"
                            :class="{ active: selectedCategory === category }">
                        {{ category }}
                    </button>
                </div>
                <div v-if="selectedCategory" class="filter">
                    <div class="tag-buttons">
                        <button 
                        v-for="tag in tags" 
                        :key="tag" 
                        @click="toggleTag(tag)"
                        :class="{ active: selectedTags.includes(tag) }">
                        {{ tag }}
                        </button>
                    </div>
                </div>
                <div class="video-list">
                    <template v-if="videos.length > 0">
                        <div v-for="video in videos" :key="video._id" class="video-item">
                            <router-link :to="`/portfolio/${video._id}`" class="a">
                                <div class="image-wrap">
                                    <img :src="video.thumbnail" alt="thumbnail" class="thumbnail"/>
                                    <div class="video-length">{{ video.video_length }}</div>
                                </div>
                                <div class="video-info">
                                    <h3>{{ video.video_title }}</h3>
                                    <div class="tags">
                                        <span v-for="tag in video.tag" :key="tag" class="tag">{{ tag }}</span>
                                    </div>
                                    <hr>
                                    <p>{{ video.client }}</p>
                                </div>
                            </router-link>
                        </div>
                    </template>
                    <template v-else>
                        <p class="none">아직 등록된 영상이 없습니다!</p>
                    </template>
                </div>
            </div>
        </section>
        <Footer/>
    </div>
</template>

<script>
import Header from '../commons/Header.vue';
import Footer from '../commons/Footer.vue';
import Arrow from '@/assets/svgs/portfolio/Arrow.vue';
import { ref, onMounted } from 'vue';
import axios from 'axios';

export default {
    name : 'Portfolio',
    components :{
        Header,
        Footer,
        Arrow
    },
    setup(){
        const categories = ['전체', '교육', '홍보', '광고/바이럴', '유튜브', '기타'];
        const tags = [
        '2D 모션 그래픽', '3D 모션 그래픽', '인포그래픽', '실사', 'VR', '인터뷰',
        '라이브', '드라마타이즈', '스톡', '웹예능', '스케치'
        ];
        const selectedCategory = ref('전체');
        const selectedTags = ref([]);

        const videos = ref([]);
        const videoCount = ref(0);

        const fetchVideoCount = async ()=>{
            try{
                const { data } = await axios.get('/api/countVideo');
                animateCount(1, data);
            }catch(error){
                console.error(error);
            }
        };
        const animateCount = (start, end) => {
            let current = start;
            const increment = end / 100;
            const duration = 200; // 0.2 초
            const stepTime = Math.floor(duration / 100);
            
            const timer = setInterval(() => {
                current += increment;
                if (current >= end) {
                    clearInterval(timer);
                    current = end;
                }
                videoCount.value = Math.floor(current);
            }, stepTime);
        };

        const fetchVideos = async () => {
            try {
                const { data } = await axios.get('/api/filter-video', {
                    params: {
                        category: selectedCategory.value === '전체' ? '' : selectedCategory.value,
                        tags: selectedTags.value.length === 0 ? '' : selectedTags.value.join(',')
                    }
                });
                videos.value = data;
            } catch (error) {
                console.error('비디오를 찾는 중 오류가 발생했습니다. :', error);
            }
        };

        const selectCategory = (category) => {
            selectedCategory.value = category;
            if (category === '전체') {
                selectedTags.value = [];
            } else {
                selectedTags.value = [];
            }
            fetchVideos();
        };

        const toggleTag = (tag) => {
            if (selectedTags.value.includes(tag)) {
                selectedTags.value = selectedTags.value.filter(t => t !== tag);
            } else {
                selectedTags.value.push(tag);
            }
            fetchVideos();
        };

        onMounted(()=>{
            fetchVideos();
            fetchVideoCount();
        });
        

        return {
            categories,
            tags,
            selectedCategory,
            selectedTags,
            videos,
            selectCategory,
            toggleTag,
            videoCount,
        };
    }
}
</script>

<style scoped>
.wrapper {
    align-items: center;
    justify-content: center;
    position: relative;
}

.banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 380px;
    background: linear-gradient(179deg, rgba(140, 175, 255, 0.3) 0.2%, #FFF 99.3%);
}
.phrases{
    color: #4D4D4D;
    font-size: 18px;
    font-weight: 600;
}

.banner h3 {
    color: #1A1A1A;
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 60px;
}
.banner h3 span{
    background: linear-gradient(90deg, #1C57EF 0%, #a6bcf4 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}
.explain {
    margin: 0;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}
.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    background: var(--vdcolor);
    width: 273px;
    height: 56px;
    color: #FFF;
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    transition: background 0.3s, color 0.3s; /* Transition 추가 */
}

.btn:hover,
.btn:focus {
    background: #1C57EF; /* Hover와 Focus 상태에서의 배경색 유지 */
    color: #FFF; /* Hover와 Focus 상태에서의 글자색 유지 */
    text-decoration: none; /* 텍스트 장식 없애기 */
}

.btn:active {
    background: #1C57EF !important;
    color: #FFF !important; /* Active 상태에서도 글자색 유지 */
}

.arrow{
    margin-left: 15px;
}

.video{
    padding: 50px;
}

.a{
    text-decoration: none;
}
.tabs button {
    margin: 5px;
    padding: 10px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    transition: background-color 0.3s ease;
    width: 121px;
    height: 56px;
}
.tabs button.active {
    background-color: #F9F9F9;;
    color: black;
    font-weight: 700;
    border-radius: 5px;
}
.filter {
    margin: 10px 0;
}
.tag-buttons button {
    margin: 5px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
    width: 150px;
    height: 38px;
    border-radius: 10px;
    border: 1px solid #F9F9F9;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: transparent;
    transition: border 0.3s ease;
}
.tag-buttons .active {
    border: 1px solid black;
    font-weight: 600;
    transition-duration: 0.2s;
}
.video-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* 아이템 간의 간격 */
}

.video-item {
    margin: 20px 0;
    padding: 10px;
    width: calc(25% - 20px); /* 한 줄에 4개씩 배치되도록 */
    height: auto;
}

@media (max-width: 1200px) {
    .video-item {
        width: calc(33.33% - 20px); /* 한 줄에 3개 */
    }
}

@media (max-width: 992px) {
    .video-item {
        width: calc(50% - 20px); /* 한 줄에 2개 */
    }
}

@media (max-width: 576px) {
    .video-item {
        width: 100%; /* 한 줄에 1개 */
    }
}

.image-wrap{
    overflow: hidden;
    position: relative;
    border-radius: 5px;
}
/* 16:9 비율 이미지 크기*/
.thumbnail {
    /* width: 368px;
    height: 207px; */
    width: 100%;
    height: auto;
    transition-duration: 0.2s;
}
.thumbnail:hover{
    transform: scale(1.2, 1.2);
    transition-duration: 0.4s;
}
.video-info {
    margin-top: 10px;
}
.video-info h3 {
    height: 38px;
    color: #1A1A1A;
    font-size: 17px;
    font-weight: 600;
}
.video-length {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 14px;
    z-index: 1; /* 이미지 위에 동영상 시간 올리기 */
    pointer-events: none; /* 이미지가 움직여도 움직이지 않게 하기 */
}
.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px; /* Space between tags */
    margin-top: 5px;
}

.tag {
    background-color: transparent;
    color: #333;
    padding: 4px 9px;
    border-radius: 5px;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #808080;
}
.video-info hr{
    margin: 10px 0;
}
.video-info p{
    color: #4C4949;
    font-size: 14px;
    font-weight: 400;
}
.none{
    margin:40px;
    font-weight: 600;
    font-size: 17px;
    
}
/* 창 너비가 804px 이하일 때 반응 멈추기 */
@media (max-width: 804px) {
    .banner h3{
        font-size: 24px;
    }
}


</style>
