<template>
    <div class="home">
        <AdminHeader />
        <div class="main-content">
            <Sidebar />
            <div class="content">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import AdminHeader from './AdminHeader.vue';
import Sidebar from './Sidebar.vue';

export default {
    name : "AdminHome",
    components:{
        AdminHeader,
        Sidebar,
    }
}
</script>

<style scoped>
.home {
  min-width: 1200px;
}

.main-content {
    display: flex;
}

.content {
    flex: 1;
    padding: 20px;
    min-width: calc(1200px - 273px);
}
</style>
