import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";

import router from "./router.js";
import store from "./store";

//-modules
import VCalendar from "v-calendar";
import ToastPlugin from "vue-toast-notification";
import { quillEditor } from 'vue3-quill'
//-styles
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "v-calendar/style.css";
import "vue-select/dist/vue-select.css";
import "vue-toast-notification/dist/theme-bootstrap.css";

store.dispatch('checkAuth').then(() => {
    const app = createApp(App);

    app.config.globalProperties.$axios = axios;
    app.use(store);
    app.use(VCalendar, {});
    app.use(ToastPlugin);
    app.use(quillEditor);
    app.use(router).mount("#app");
});
