export const categories =[
    {
        link : require("@/assets/video/mainpage/doctor.mp4"),
        title : "닥터마틴 브랜드 홍보 영상",
        client : "중소기업 | 닥터마틴",
        period : "3주",
        price : "1,200만원"
    },
    {
        link : require("@/assets/video/mainpage/settop.mp4"),
        title : "제목",
        client : "XX기업 | 클라이언트 이름",
        period : "X주",
        price : "X,X00만원"
    },
    {
        link : require("@/assets/video/mainpage/baseball.mp4"),
        title : "제목",
        client : "XX기업 | 클라이언트 이름",
        period : "X주",
        price : "X,X00만원"
    },
    {
        link : require("@/assets/video/mainpage/motion.mp4"),
        title : "제목",
        client : "XX기업 | 클라이언트 이름",
        period : "X주",
        price : "X,X00만원"
    },
    {
        link : require("@/assets/video/mainpage/3Dmotion.mp4"),
        title : "제목",
        client : "XX기업 | 클라이언트 이름",
        period : "X주",
        price : "X,X00만원"
    },
];