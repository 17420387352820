<template>
    <div class="home">
        <Header/>
        <div class="inner">
            <div class="title">
                <div class="first">상상 속에만 있던 당신의
                    <div class="slidingVertical">
                        <span class="gradation">교육</span>
                        <span class="gradation">홍보</span>
                        <span class="gradation">광고/바이럴</span>
                        <span class="gradation">유튜브</span>
                        <span class="gradation">기타</span>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    영상
                </div>
                <div>
                    <p class="second">지금 만나보세요</p>
                </div>
                <div>
                    <p class="third">원하는 조건을 입력하고 평균 4개의 영상 제작 견적을 받아보세요.</p>
                </div>
            </div>
        </div>

        <section class="choose ">
            <div ref="sections" class="inner slide-up-enter">
                <h2 class="choose-title">앞서가는 기업들의 이유있는 선택</h2>
                <div class="logo-grid">
                    <div class="logo-item" v-for="(logo, index) in logos" :key="index">
                        <img :src="logo" alt="Compnay Logo">
                    </div>
                </div>
            </div>
        </section>


    <section class="step">
      <div ref="sections" class="step-by slide-up-enter">
        <p>01</p>
        <h3>체계적인 제작과정으로</h3>
        <h3><span style="color: var(--vdcolor);">합리적인 견적</span>과 퀄리티 높은 영상을</h3>
        <h3>전달드립니다</h3>
        <img src="@/assets/svgs/mainpage/step1.svg">
      </div>
      <div ref="sections" class="step-by slide-up-enter">
        <p>02</p>
        <h3>납품까지 안전하게</h3>
        <h3><span style="color : var(--vdcolor)">담당 PD</span>의 1:1 밀착 관리</h3>
        <div class="box">
          <div class="card">
            <img src="@/assets/imgs/mainpage/step2-1.jpg" alt="사전 미팅">
          </div>
          <div class="explain">
            <h4>사전 미팅</h4>
            <span>영상 제작이 처음이어도 걱정할 필요 없이 VD가 적절한 예산 산정을 도와드려요.</span>
          </div>
        </div>
        <div class="box">
          <div class="card">
            <img src="@/assets/imgs/mainpage/step2-2.jpg" alt="일정 관리">
          </div>
          <div class="explain">
            <h4>일정 관리</h4>
            <span>사전 미팅 일정 조율과 납품 일자 관리까지 꼼꼼히 챙겨드려요.</span>
          </div>
        </div>
        <div class="box">
          <div class="card">
            <img src="@/assets/imgs/mainpage/step2-3.jpg" alt="안심 계약">
          </div>
          <div class="explain">
            <h4>안심 계약</h4>
            <span>법무법인의 검토를 마친 안심 계약서를 통해 계약 과정을 안전하고 전문적으로 진행해요.</span>
          </div>
        </div>
      </div>
      <div ref="sections" class="step-by slide-up-enter">
        <p>03</p>
        <h3><span style="color: var(--vdcolor);">하루</span>만에 받아보는</h3>
        <h3><span style="color: var(--vdcolor);">평균 4개</span>의 비교 견적</h3>
        <div class="compare">
          <div class="compare__box">
            <strong class="compare__title">
              상세 견적서부터 <br />
              포트폴리오까지 한눈에
            </strong>
            <a class="compare__link" href="#">견적서 샘플 미리보기 ></a>
            <div class="compare__sample">
              <div class="compare__sample-item">
                <span class="compare__sample-title">4개의 견적 제안이 도착했어요</span>
                <table class="compare__table">
                  <thead>
                  <tr>
                    <th>가이드 견적</th>
                    <th>구성</th>
                    <th>가격</th>
                    <th>제안서 첨부</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <div class="compare__check-area">
                        <div class="compare__checkbox"></div>
                        <span>1안</span>
                      </div>
                    </td>
                    <td>200분 : 소스/인포그래픽 + 믹싱</td>
                    <td>4,940만원</td>
                    <td>
                      <div class="compare__check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="compare__check-area">
                        <div class="compare__checkbox"></div>
                        <span>2안</span>
                      </div>
                    </td>
                    <td>200분 : 소스/인포그래픽 + 믹싱</td>
                    <td>4,940만원</td>
                    <td>
                      <div class="compare__check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="compare__check-area">
                        <div class="compare__checkbox"></div>
                        <span>3안</span>
                      </div>
                    </td>
                    <td>200분 : 소스/인포그래픽 + 믹싱</td>
                    <td>4,940만원</td>
                    <td>
                      <div class="compare__check"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="compare__check-area">
                        <div class="compare__checkbox"></div>
                        <span>4안</span>
                      </div>
                    </td>
                    <td>200분 : 소스/인포그래픽 + 믹싱</td>
                    <td>4,940만원</td>
                    <td>
                      <div class="compare__check"></div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="compare__sample-item">
                <div class="compare__sample-layout-row">
                  <div class="compare__sample-layout1">
                    <div class="compare__sample-layout-box1"></div>
                  </div>
                  <div class="compare__sample-layout2">
                    <div class="compare__sample-layout-box2"></div>
                    <div class="compare__sample-layout-box2"></div>
                  </div>
                </div>
                <div class="compare__sample-desc">
                  안녕하세요, VD 프로덕션입니다.<br /><br />
                  최대한 빠르게 제작이 들어가면 30일 정도 걸릴 것 같습니다.
                  제안드리는 예산견적서는 총 4개입니다.
                </div>
              </div>
            </div>
          </div>
          <div class="compare__row">
            <div class="compare__col">
              <strong class="compare__subtitle">
                받은 견적은<br />
                링크로 쉽게 공유
              </strong>
            </div>
            <div class="compare__col">
              <strong class="compare__subtitle">
                미팅 요청도<br />
                VD에서 간편하게
              </strong>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="review">
      <div ref="sections" class="inner slide-up-enter">
        <div class="review-title">
          <p>💭</p>
          <h3>왜 <strong>VD</strong>일까요? <span style="color: var(--vdcolor);">고객분들의 리뷰</span></h3>
        </div>
        <div class="control">
          <svg @click="prevSlide" style="margin-right: 54px;" xmlns="http://www.w3.org/2000/svg" width="32" height="32"
               viewBox="0 0 32 32" fill="none">
            <path
                d="M20.6211 25.3727C20.8982 25.3788 21.1703 25.2985 21.3995 25.1428C21.6288 24.9871 21.8039 24.7638 21.9004 24.504C21.9969 24.2442 22.0101 23.9608 21.9381 23.6932C21.8661 23.4256 21.7126 23.1871 21.4987 23.0108L13.3399 16.0213L21.4987 9.03414C21.6466 8.92543 21.7705 8.78741 21.8626 8.6286C21.9547 8.46979 22.013 8.29364 22.0338 8.11125C22.0547 7.92887 22.0377 7.74419 21.9839 7.56869C21.93 7.39318 21.8405 7.23064 21.721 7.09132C21.6015 6.95201 21.4544 6.839 21.2891 6.75914C21.1239 6.67928 20.9439 6.63442 20.7604 6.62734C20.577 6.62026 20.3941 6.65103 20.2231 6.71792C20.0522 6.78481 19.8969 6.88628 19.767 7.01597L10.4258 15.0082C10.2791 15.1334 10.1613 15.2888 10.0805 15.4639C9.99978 15.639 9.95795 15.8296 9.95795 16.0225C9.95795 16.2153 9.99978 16.4059 10.0805 16.581C10.1613 16.7562 10.2791 16.9117 10.4258 17.0369L19.767 25.0368C20.0029 25.2464 20.3056 25.3653 20.6211 25.3727Z"
                fill="black"/>
          </svg>
          <svg @click="nextSlide" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"
               fill="none">
            <path
                d="M11.3789 6.62728C11.1018 6.62118 10.8297 6.7015 10.6005 6.85719C10.3712 7.01288 10.1961 7.23625 10.0996 7.49603C10.0031 7.75582 9.98988 8.03918 10.0619 8.30681C10.1339 8.57443 10.2874 8.81287 10.5013 8.98917L18.6601 15.9787L10.5013 22.9659C10.3534 23.0746 10.2295 23.2126 10.1374 23.3714C10.0453 23.5302 9.98703 23.7064 9.96616 23.8887C9.9453 24.0711 9.96231 24.2558 10.0161 24.4313C10.07 24.6068 10.1595 24.7694 10.279 24.9087C10.3985 25.048 10.5456 25.161 10.7109 25.2409C10.8761 25.3207 11.0561 25.3656 11.2396 25.3727C11.423 25.3797 11.6059 25.349 11.7769 25.2821C11.9478 25.2152 12.1031 25.1137 12.233 24.984L21.5742 16.9918C21.7209 16.8666 21.8387 16.7112 21.9195 16.5361C22.0002 16.361 22.0421 16.1704 22.0421 15.9775C22.0421 15.7847 22.0002 15.5941 21.9195 15.419C21.8387 15.2438 21.7209 15.0883 21.5742 14.9631L12.233 6.96315C11.9971 6.75355 11.6944 6.6347 11.3789 6.62728Z"
                fill="black"/>
          </svg>
        </div>
      </div>
      <div ref="sections" class="review-slide slide-up-enter">
        <div class="review-container">
          <div class="review-card" v-for="(review, index) in reviews" :key="index">
            <h4>{{ review.title }}</h4>
            <p>{{ review.content }}</p>
            <div>{{ review.author }}</div>
          </div>
        </div>
      </div>
    </section>

    <section class="represent-video">
      <div class="represent-video__inner">
        <div ref="sections" class="slide-up-enter">
          <div class="re-title">
            <h2>VD에서 완성된</h2>
            <p>고객들의 영상을 만나보세요</p>
          </div>

          <div class="btn-box">
            <button class="title-btn" :class="{'active': index === activeIndex}" v-for="(item, index) in items" :key="index" @click="setActive(index)">
              <img :src="item.icon" class="icon" alt="아이콘"/> {{ item.text }}
            </button>
          </div>
          <svg class="left" @click="prev" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"
               fill="none">
            <path
                d="M41.2417 50.7462C41.7959 50.7584 42.3401 50.5977 42.7986 50.2864C43.2572 49.975 43.6073 49.5282 43.8003 49.0087C43.9934 48.4891 44.0198 47.9224 43.8758 47.3871C43.7318 46.8519 43.4246 46.375 42.997 46.0224L26.6792 32.0432L42.997 18.069C43.2928 17.8516 43.5405 17.5755 43.7247 17.2579C43.9089 16.9403 44.0255 16.588 44.0672 16.2232C44.1089 15.8585 44.0749 15.4891 43.9672 15.1381C43.8596 14.7871 43.6806 14.462 43.4415 14.1834C43.2024 13.9047 42.9084 13.6787 42.5778 13.519C42.2472 13.3593 41.8872 13.2696 41.5204 13.2554C41.1535 13.2413 40.7877 13.3028 40.4458 13.4366C40.1038 13.5704 39.7933 13.7733 39.5334 14.0327L20.8511 30.0172C20.5577 30.2676 20.3222 30.5783 20.1606 30.9286C19.9991 31.2788 19.9154 31.66 19.9154 32.0457C19.9154 32.4314 19.9991 32.8126 20.1606 33.1628C20.3222 33.5131 20.5577 33.8241 20.8511 34.0745L39.5334 50.0744C40.0054 50.4936 40.6107 50.7313 41.2417 50.7462Z"
                fill="white"/>
          </svg>
          <div class="slider">
            <div class="content">
              <div v-for="(category, index) in categories" :key="index" v-show="index === activeIndex"
                   class="slider__area">
                <div class="represent-video__video-wrap">
                  <video
                      class="represent-video__video"
                      :src="category.link"
                      ref="videos"
                      muted
                      controls
                      playsinline
                      height="352px"
                  ></video>
                </div>
                <div class="represent-video__info">
                                <span class="represent-video__tag">
                                  <i></i>
                                  VD 베스트 영상 선정
                                </span>
                  <strong class="represent-video__title">닥터마틴 브랜드 홍보 영상</strong>
                  <div class="represent-video__info-box">
                    <dl class="represent-video__dl">
                      <dt>클라이언트</dt>
                      <dd>중소기업 | 닥터마틴</dd>
                    </dl>
                    <dl class="represent-video__dl">
                      <dt>제작 기간</dt>
                      <dd>3주</dd>
                    </dl>
                    <dl class="represent-video__dl">
                      <dt>적정 제작비</dt>
                      <dd>1,200만원</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <svg class="right" @click="next" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
            <path
                d="M22.7583 13.2538C22.2041 13.2416 21.6599 13.4023 21.2014 13.7136C20.7428 14.025 20.3927 14.4718 20.1997 14.9913C20.0066 15.5109 19.9802 16.0776 20.1242 16.6129C20.2682 17.1481 20.5754 17.625 21.003 17.9776L37.3208 31.9568L21.003 45.931C20.7072 46.1484 20.4595 46.4245 20.2753 46.7421C20.0911 47.0597 19.9745 47.412 19.9328 47.7768C19.8911 48.1415 19.9251 48.5109 20.0328 48.8619C20.1404 49.2129 20.3194 49.538 20.5585 49.8166C20.7976 50.0953 21.0916 50.3213 21.4222 50.481C21.7528 50.6407 22.1128 50.7304 22.4796 50.7446C22.8465 50.7587 23.2123 50.6972 23.5542 50.5634C23.8962 50.4296 24.2067 50.2267 24.4666 49.9673L43.1489 33.9828C43.4423 33.7324 43.6778 33.4217 43.8394 33.0714C44.0009 32.7212 44.0846 32.34 44.0846 31.9543C44.0846 31.5686 44.0009 31.1874 43.8394 30.8372C43.6778 30.4869 43.4423 30.1759 43.1489 29.9255L24.4666 13.9256C23.9946 13.5064 23.3893 13.2687 22.7583 13.2538Z"
                fill="white"/>
          </svg>
        </div>
      </div>
    </section>

    <section class="flow">
      <h2 ref="sections" class="flow__title slide-up-enter">
        영상 제작이 처음이어도
        <span class="flow__title--point">걱</span>
        <span class="flow__title--point">정</span>
        <span class="flow__title--point">없</span>
        <span class="flow__title--point">이</span>
      </h2>
      <div ref="sections" class="flow__content slide-up-enter">
        <div class="flow__step">
          <span class="flow__num">01</span>
          <img class="flow__img" src="../assets/svgs/mainpage/flow_01.svg" alt="img">
          <strong class="flow__step-title">매칭 의뢰 접수</strong>
          <p class="flow__step-desc">클릭 몇 번으로 쉽게<br/>원하는 프로젝트를 요청</p>
        </div>
        <div class="flow__step">
          <span class="flow__num">02</span>
          <img class="flow__img" src="../assets/svgs/mainpage/flow_02.svg" alt="img">
          <strong class="flow__step-title">견적 제안 전달</strong>
          <p class="flow__step-desc">최대 6개 견적까지<br/>한 번에 받아 비교하기</p>
        </div>
        <div class="flow__step">
          <span class="flow__num">03</span>
          <img class="flow__img" src="../assets/svgs/mainpage/flow_03.svg" alt="img">
          <strong class="flow__step-title">미팅 및 제작 확정</strong>
          <p class="flow__step-desc">담당 PD와 미팅 일정<br/>조율 및 안전하게 계약 진행</p>
        </div>
        <div class="flow__step">
          <span class="flow__num">04</span>
          <img class="flow__img" src="../assets/svgs/mainpage/flow_04.svg" alt="img">
          <strong class="flow__step-title">제작 및 납품</strong>
          <p class="flow__step-desc">안전거래 시스템을 통해<br/>납품 완료 확인 후 대금 전달</p>
        </div>
      </div>
    </section>

    <section class="estimate">
      <h2 ref="sections" class="estimate__title slide-up-enter">어떤 영상이 필요하신가요? <br/>지금 견적을 받아보세요 </h2>
      <div ref="sections" class="estimate__content slide-up-enter">
        <a class="estimate__item" href="#">
          <img src="../assets/svgs/mainpage/estimate_01.svg" alt="img">
          <strong class="estimate__item-title">제품 광고</strong>
          <span class="estimate__item-arrow"></span>
        </a>
        <a class="estimate__item" href="#">
          <img src="../assets/svgs/mainpage/estimate_02.svg" alt="img">
          <strong class="estimate__item-title">기업 · 브랜드 홍보</strong>
          <span class="estimate__item-arrow"></span>
        </a>
        <a class="estimate__item" href="#">
          <img src="../assets/svgs/mainpage/estimate_03.svg" alt="img">
          <strong class="estimate__item-title">2D 모션 그래픽</strong>
          <span class="estimate__item-arrow"></span>
        </a>
        <a class="estimate__item" href="#">
          <img src="../assets/svgs/mainpage/estimate_04.svg" alt="img">
          <strong class="estimate__item-title">3D 모션 그래픽</strong>
          <span class="estimate__item-arrow"></span>
        </a>
        <a class="estimate__item" href="#">
          <img src="../assets/svgs/mainpage/estimate_05.svg" alt="img">
          <strong class="estimate__item-title">인포 그래픽</strong>
          <span class="estimate__item-arrow"></span>
        </a>
        <a class="estimate__item" href="#">
          <img src="../assets/svgs/mainpage/estimate_06.svg" alt="img">
          <strong class="estimate__item-title">유튜브 제작</strong>
          <span class="estimate__item-arrow"></span>
        </a>
        <a class="estimate__item" href="#">
          <img src="../assets/svgs/mainpage/estimate_07.svg" alt="img">
          <strong class="estimate__item-title">교육 영상</strong>
          <span class="estimate__item-arrow"></span>
        </a>
        <a class="estimate__item" href="#">
          <img src="../assets/svgs/mainpage/estimate_08.svg" alt="img">
          <strong class="estimate__item-title">기타</strong>
          <span class="estimate__item-arrow"></span>
        </a>
      </div>
    </section>

    <section class="portfolio">
      <h2 ref="sections" class="portfolio__title slide-up-enter">지금 떠오르는 포트폴리오</h2>
      <a ref="sections" class="portfolio__more slide-up-enter" href="#">더보기</a>
      <div ref="sections" class="portfolio__content slide-up-enter">
        <div class="portfolio__row">
          <div class="portfolio__item">
            <div class="portfolio__img">
              <div class="portfolio__img-wrap">
                <img src="../assets/imgs/mainpage/step2-1.jpg" alt="img">
                <button class="portfolio__pick-btn" type="button"></button>
              </div>
            </div>
            <strong class="portfolio__video-title">영상 이름</strong>
            <div class="portfolio__tag-area">
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
            </div>
            <div class="portfolio__company">회사명</div>
          </div>
          <div class="portfolio__item">
            <div class="portfolio__img">
              <div class="portfolio__img-wrap">
                <img src="../assets/imgs/mainpage/step2-1.jpg" alt="img">
                <button class="portfolio__pick-btn" type="button"></button>
              </div>
            </div>
            <strong class="portfolio__video-title">영상 이름</strong>
            <div class="portfolio__tag-area">
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
            </div>
            <div class="portfolio__company">회사명</div>
          </div>
          <div class="portfolio__item">
            <div class="portfolio__img">
              <div class="portfolio__img-wrap">
                <img src="../assets/imgs/mainpage/step2-1.jpg" alt="img">
                <button class="portfolio__pick-btn" type="button"></button>
              </div>
            </div>
            <strong class="portfolio__video-title">영상 이름</strong>
            <div class="portfolio__tag-area">
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
            </div>
            <div class="portfolio__company">회사명</div>
          </div>
          <div class="portfolio__item">
            <div class="portfolio__img">
              <div class="portfolio__img-wrap">
                <img src="../assets/imgs/mainpage/step2-1.jpg" alt="img">
                <button class="portfolio__pick-btn" type="button"></button>
              </div>
            </div>
            <strong class="portfolio__video-title">영상 이름</strong>
            <div class="portfolio__tag-area">
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
            </div>
            <div class="portfolio__company">회사명</div>
          </div>
        </div>
        <div class="portfolio__row">
          <div class="portfolio__item">
            <div class="portfolio__img">
              <div class="portfolio__img-wrap">
                <img src="../assets/imgs/mainpage/step2-1.jpg" alt="img">
                <button class="portfolio__pick-btn" type="button"></button>
              </div>
            </div>
            <strong class="portfolio__video-title">영상 이름</strong>
            <div class="portfolio__tag-area">
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
            </div>
            <div class="portfolio__company">회사명</div>
          </div>
          <div class="portfolio__item">
            <div class="portfolio__img">
              <div class="portfolio__img-wrap">
                <img src="../assets/imgs/mainpage/step2-1.jpg" alt="img">
                <button class="portfolio__pick-btn" type="button"></button>
              </div>
            </div>
            <strong class="portfolio__video-title">영상 이름</strong>
            <div class="portfolio__tag-area">
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
            </div>
            <div class="portfolio__company">회사명</div>
          </div>
          <div class="portfolio__item">
            <div class="portfolio__img">
              <div class="portfolio__img-wrap">
                <img src="../assets/imgs/mainpage/step2-1.jpg" alt="img">
                <button class="portfolio__pick-btn" type="button"></button>
              </div>
            </div>
            <strong class="portfolio__video-title">영상 이름</strong>
            <div class="portfolio__tag-area">
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
              <span class="portfolio__tag">태그</span>
            </div>
            <div class="portfolio__company">회사명</div>
          </div>
        </div>
      </div>
    </section>

    <section class="banner">
      <div ref="sections" class="banner__content slide-up-enter">
        <strong class="banner__title">
          VD와 함께라면 여러분의 상상을<br />
          <span class="banner__title--point">현실</span>로 만들 수 있습니다
        </strong>
        <a class="banner__link" href="#">지금 바로 견적 문의하러 가기</a>
      </div>
    </section>

    <section class="faq">
      <h2 ref="sections" class="faq__title slide-up-enter">
        이런 것들이 궁금하셨나요?
        <em class="faq__title--point">FAQ</em>
      </h2>
      <ul ref="sections" class="faq-list slide-up-enter">
        <li class="faq-item" v-for="(item, index) in qnaList" :key="index">
          <div class="faq-item__title-area">
            <abbr class="faq-item__abbr">Q</abbr>
            <h3 class="faq-item__title">{{ item.title }}</h3>
            <button class="faq-item__title-btn" :class="{ 'open' : item.isOpen }" type="button" @click="onClickFaqItem(index)"></button>
          </div>
          <div class="faq-item__content-area" :class="{ 'open' : item.isOpen }">
            <div class="faq-item__content">
              {{ item.content }}
            </div>
          </div>
        </li>
      </ul>
    </section>

    <section class="consult">
      <h2 class="consult__title">지금 무료로 매칭 받아보세요</h2>
      <div class="consult__content">
        아직 고민되시나요? <span class="consult__content--point">VD 컨설팅 PD와 상담</span>해보세요.<br />
        예산 책정부터 영상제작 과정까지 무료로 상담해드려요.
      </div>
      <button class="vd-btn" @click="$router.push('/estimate')">무료로 견적받기</button>
    </section>

    <Footer/>
  </div>
</template>

<script>
import {ref, onMounted, onBeforeUnmount, reactive} from 'vue';
import {reviews} from '@/assets/data/reviews';
import {categories} from '@/assets/data/categories.js';
import Header from './commons/Header.vue'
import Footer from './commons/Footer.vue'

import buildingIcon from '@/assets/svgs/mainpage/building.svg';
import findIcon from '@/assets/svgs/mainpage/find.svg';
import youtubeIcon from '@/assets/svgs/mainpage/youtube.svg';
import twoDIcon from '@/assets/svgs/mainpage/2d.svg';
import threeDIcon from '@/assets/svgs/mainpage/3d.svg';

export default {
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  setup() {
    const logos = ref([
      require('@/assets/svgs/mainpage/0.svg'),
      require('@/assets/svgs/mainpage/1.svg'),
      require('@/assets/svgs/mainpage/2.svg'),
      require('@/assets/svgs/mainpage/3.svg'),
      require('@/assets/svgs/mainpage/4.svg'),
      require('@/assets/svgs/mainpage/5.svg'),
      require('@/assets/svgs/mainpage/6.svg'),
      require('@/assets/svgs/mainpage/7.svg'),
      require('@/assets/svgs/mainpage/8.svg'),
      require('@/assets/svgs/mainpage/9.svg'),
      require('@/assets/svgs/mainpage/10.svg'),
      require('@/assets/svgs/mainpage/11.svg'),
      require('@/assets/svgs/mainpage/12.svg'),
      require('@/assets/svgs/mainpage/13.svg'),
      require('@/assets/imgs/mainpage/14.png'),
      require('@/assets/svgs/mainpage/15.svg'),
      require('@/assets/svgs/mainpage/16.svg'),
      require('@/assets/svgs/mainpage/17.svg'),
      require('@/assets/svgs/mainpage/18.svg'),
      require('@/assets/imgs/mainpage/19.png'),
      require('@/assets/svgs/mainpage/20.svg'),
      require('@/assets/svgs/mainpage/21.svg'),
      require('@/assets/svgs/mainpage/22.svg'),
      require('@/assets/imgs/mainpage/23.png'),
    ]);

    const sections = ref([]);

    let observer;

    const createObserver = () => {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !entry.target.classList.contains('slide-up-visible')) {
              requestAnimationFrame(() => {
                entry.target.classList.add('slide-up-visible');
              });
              observer.unobserve(entry.target); // 애니메이션이 적용된 후 관찰 해제
            }
          });
        },
        {threshold: [0.1]}
      );

      sections.value.forEach((section) => {
        if (section) observer.observe(section);
      });
    };

    // 견적받기 버튼 플로팅
    const setFloatingBtn = () => {
    const pageElement = document.querySelector('.home');
    const btn = document.querySelector('.vd-btn');
    const footer = document.querySelector('footer');

    if (!pageElement || !btn || !footer) {
      console.error('Required elements not found:', {
        pageElement: !!pageElement,
        btn: !!btn,
        footer: !!footer
      });
      return;
    }

    const pageHeight = pageElement.getBoundingClientRect().height;
    const lastSectionHeight = footer.getBoundingClientRect().height;
    const stopPosition = Math.round(pageHeight - lastSectionHeight - 820);
    const pageYOffset = window.pageYOffset;

    if (pageYOffset > stopPosition) {
      btn.style.position = 'relative';
      btn.style.bottom = '0';
      btn.style.top = '0';
      btn.style.marginTop = '47px';
    } else {
      btn.style.position = 'fixed';
      btn.style.bottom = 'auto';
      btn.style.top = '590px';
    }
  };



    // 영상 재생
    let videoObserver;
    const createVideoObserver = () => {
      videoObserver = new IntersectionObserver((entries) => {

        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.target.classList.contains('represent-video__video')) {
            entry.target.play();
          }
        })
      }, {threshold: [0.1]});

      const video = document.querySelectorAll('.represent-video__video');
      video.forEach(target => {
        videoObserver.observe(target);
      })
    };

    const resetPlayVideo = () => {
      const video = document.querySelectorAll('.represent-video__video');
      video.forEach(video => {
        video.pause();
        video.currentTime = 0;
        video.play();

      })
    }


    // 슬라이드 기능
    const currentIndex = ref(0);
    const totalCards = 15;
    const visibleCards = 5;

    const slideTo = (index) => {
      const cardWidth = 358 + 20; // 카드의 너비와 간격
      const maxIndex = totalCards - visibleCards;
      currentIndex.value = Math.max(0, Math.min(index, maxIndex));
      document.querySelector('.review-container').style.transform = `translateX(-${currentIndex.value * cardWidth}px)`;
    };

    const prevSlide = () => {
      if (currentIndex.value == 0) {
        currentIndex.value = 11;
      }
      slideTo(currentIndex.value - 1);
    };

    const nextSlide = () => {
      slideTo(currentIndex.value + 1);
      if (currentIndex.value == 10) {
        currentIndex.value = 0;
      }
    };

    const items = ref([
      {text: '기업 홍보', icon: buildingIcon},
      {text: '제품 광고', icon: findIcon},
      {text: '유튜브 제작', icon: youtubeIcon},
      {text: '2D 모션', icon: twoDIcon},
      {text: '3D 모션', icon: threeDIcon}
    ]);
    const activeIndex = ref(0);

    const setActive = (index) => {
      resetPlayVideo();
      activeIndex.value = index;
    };

    const next = () => {
      resetPlayVideo();
      activeIndex.value = (activeIndex.value + 1) % items.value.length;
    };

    const prev = () => {
      resetPlayVideo();
      activeIndex.value = (activeIndex.value - 1 + items.value.length) % items.value.length;
    };

    const qnaList = reactive([
      {
        title: '영상 관련 자료를 완벽하게 정리해서 제공해야 하나요?',
        content: '영상 제작 목적, 타켓층과 발신 채널, 영상의 내용을 담은 대략적인 데이터를 제공해주시면 저희 VD의 내부 작가가 자료를 종합/정리하여 스크립트를 만들어 드립니다. 그 과정에서 전문적인 내용에 대한 정확한 확인을 위해 피드백을 요청드릴 수는 있겠지만, 처음부터 자료를 완벽하게 정리해서 주시지 않으셔도 됩니다.',
        isOpen: false
      },
      {
        title: '영상 관련 자료를 완벽하게 정리해서 제공해야 하나요?',
        content: '영상 제작 목적, 타켓층과 발신 채널, 영상의 내용을 담은 대략적인 데이터를 제공해주시면 저희 VD의 내부 작가가 자료를 종합/정리하여 스크립트를 만들어 드립니다. 그 과정에서 전문적인 내용에 대한 정확한 확인을 위해 피드백을 요청드릴 수는 있겠지만, 처음부터 자료를 완벽하게 정리해서 주시지 않으셔도 됩니다.',
        isOpen: false
      },
      {
        title: '영상 관련 자료를 완벽하게 정리해서 제공해야 하나요?',
        content: '영상 제작 목적, 타켓층과 발신 채널, 영상의 내용을 담은 대략적인 데이터를 제공해주시면 저희 VD의 내부 작가가 자료를 종합/정리하여 스크립트를 만들어 드립니다. 그 과정에서 전문적인 내용에 대한 정확한 확인을 위해 피드백을 요청드릴 수는 있겠지만, 처음부터 자료를 완벽하게 정리해서 주시지 않으셔도 됩니다.',
        isOpen: false
      }
    ]);

    const onClickFaqItem = (index) => {
      qnaList[index].isOpen = !qnaList[index].isOpen
    }


    onMounted(() => {
      sections.value = Array.from(document.querySelectorAll('.slide-up-enter'));
      createObserver();
      document.addEventListener('scroll', setFloatingBtn)
      createVideoObserver();
      slideTo(4);
    });

    onBeforeUnmount(() => {
      if (observer) {
        observer.disconnect();
      }
      if (videoObserver) {
        videoObserver.disconnect();
      }
    });

    return {
      logos,
      sections,
      currentIndex,
      prevSlide,
      nextSlide,
      reviews,
      categories,
      items,
      activeIndex,
      setActive,
      next,
      prev,
      qnaList,
      onClickFaqItem
    }
  }
}
</script>

<style scoped>
.home {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 1200px;
}

.vd-btn {
  position: fixed;
  top: 590px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
}

.inner {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  flex: 1;
}

.title {
  background-image: linear-gradient(to top, #434343 0%, black 100%);
  color: white;
  height: 912px;
  opacity: 1;
  text-align: center;
  padding: 200px 0;
}

.first,
.second {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 7px;
  display: inline-block;
  align-content: baseline;
  padding: 5px;
}

.slidingVertical {
  background-color: rgb(101, 101, 101, 0.5);
  width: 300px;
  height: 65px;
  display: inline-flex;
  text-indent: 8px;
  float: left;
  position: absolute;

  border-radius: 10px;
  text-align: center;
  justify-content: center;

}

.gradation {
  background: linear-gradient(to right, #5FE2FF, #a68ff7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.slidingVertical span {
  animation: topToBottom 12.5s linear infinite 0s;
  -ms-animation: topToBottom 12.5s linear infinite 0s;
  -webkit-animation: topToBottom 12.5s linear infinite 0s;
  color: #00abe9;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.slidingVertical span:nth-child(2) {
  animation-delay: 2.5s;
  -ms-animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s;
}

.slidingVertical span:nth-child(3) {
  animation-delay: 5s;
  -ms-animation-delay: 5s;
  -webkit-animation-delay: 5s;
}

.slidingVertical span:nth-child(4) {
  animation-delay: 7.5s;
  -ms-animation-delay: 7.5s;
  -webkit-animation-delay: 7.5s;
}

.slidingVertical span:nth-child(5) {
  animation-delay: 10s;
  -ms-animation-delay: 10s;
  -webkit-animation-delay: 10s;
}

.content-item {
  margin-bottom: 1rem;
}

.third {
  margin: 40px;
  font-size: 17px;
}

.choose {
  height: 1000px;
  background: linear-gradient(243deg, rgba(152, 168, 255, 0.60) 0.59%, #FFF 48.11%, rgba(195, 240, 255, 0.70) 100%);
}

.choose .choose-title {
  color: #000;
  font-family: Inter;
  font-size: 34px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  display: flex;
  justify-content: center;
  padding-top: 200px;
}

.logo-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 고정된 4열 설정 */
  gap: 30px; /* 위 아래 간격 조정 */
  justify-items: center;
  align-items: center;
  margin-top: 70px;
  padding: 0 25%; /* 상대적인 패딩으로 변경 */
}

.logo-item {
  width: 150px;
  height: 60px;
  max-width: 150px; /* 최대 너비를 150px로 설정 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}


.step {
  width: 100%;
  max-width: 720px; /* 최대 너비를 설정 */
  height: 3456px;
  margin: 132px auto 0; /* 수평 가운데 정렬 및 위쪽 마진 설정 */
  padding: 30px 0;
}

.step-by {
  padding-bottom: 70px;
}

.step-by p {
  color: #C9CACB;
  font-size: 24px;
  font-weight: 600;
}

.step-by h3 {
  color: #000;
  font-size: 34px;
  font-weight: 600;
}

.step-by img {
  margin: 30px 0;
}

.box {
  margin: 30px 0;
  width: 720px;
}

.card {
  width: 100%;
  height: 272px;
  border-radius: 20px 20px 0 0;
  background: #F6F6F6;
  border: none;
  overflow: hidden;
  position: relative;
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지가 카드의 크기에 맞게 조정되도록 설정 */
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* 박스 크기 계산에 패딩과 테두리 포함 */
}

.card::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(246, 246, 246, 0.5), rgba(255, 255, 255, 0));
  border-radius: 20px 20px 0 0;
}

.explain {
  width: 100%;
  height: 132px;
  border-radius: 0px 0px 20px 20px;
  background: #F6F6F6;
  padding: 30px;
}

.explain h4 {
  color: #000;
  font-size: 24px;
  font-weight: 700;
}

.explain span {
  color: #333;
  font-size: 16px;
  font-weight: 400;
}

.compare__box {
  width: 100%;
  height: 552px;
  margin-top: 33px;
  border-radius: 20px;
  background: #0041D614;
}

.compare__title {
  display: block;
  padding: 36px 50px 0;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  color: #000;
}

.compare__link {
  display: block;
  width: 238px;
  height: 48px;
  margin: 33px 0 44px 50px;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  color: #fff;
  background: #0041D6;
}

.compare__row {
  display: flex;
  gap: 13px;
  margin-top: 20px;
}

.compare__col {
  flex: 1 1 0;
  height: 212px;
  border-radius: 20px;
  background: #0041D614;
}

.compare__subtitle {
  display: block;
  padding: 20px 33px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}

.compare__sample {
  display: flex;
  gap: 9px;
  height: 333px;
  padding-left: 19px;
}

.compare__sample-item {
  background: #fff;
}

.compare__table {
  width: 100%;
  text-align: center;
}

.compare__sample-item:nth-child(1) {
  flex-shrink: 0;
  width: 386px;
  padding: 25px 0 0 20px;
  border-radius: 10px 10px 0 0;
}

.compare__sample-item:nth-child(2) {
  padding: 25px 0 25px 20px;
  border-radius: 10px 0 0 0;
}

.compare__sample-title {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 18px;
}

.compare__table thead tr {
  height: 21px;
  border-radius: 5px 0 0 5px;
  background: #0041D614;
}

.compare__table th {
  padding: 2px 15px;
  text-align: center;
  line-height: 21px;
  font-size: 10px;
  color: #7C7C7C;
}

.compare__table th:first-child {
  border-radius: 5px 0 0 5px;
}

.compare__table tbody tr:first-child td {
  padding-top: 8px;
}

.compare__table td {
  font-size: 9px;
  color: #000000;
  vertical-align: middle;
}

.compare__check-area {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px 8px;
}

.compare__checkbox {
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  background: #fff;
}

.compare__check {
  width: 28px;
  height: 28px;
  margin: 4px auto;
  background: url(../assets/svgs/mainpage/compare_check.svg) no-repeat center / cover;
}

.compare__sample-layout-row {
  display: flex;
  gap: 5px
}

.compare__sample-layout-box1 {
  width: 226px;
  height: 134px;
  background: url(../assets/svgs/mainpage/compare_box1.svg) no-repeat center / cover;
}

.compare__sample-layout2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.compare__sample-desc {
  width: 285px;
  height: 128px;
  margin-top: 18px;
  padding: 13px 20px;
  border-radius: 10px 0 0 10px;
  font-size: 9px;
  color: #000;
  background: #0041D614;
}

.compare__sample-layout-box2 {
  width: 54px;
  height: 60px;
  background: url(../assets/svgs/mainpage/compare_box2.svg) no-repeat center / cover;
}

.review {
  height: 800px;
  background: rgba(0, 65, 214, 0.08);
  position: relative;
}

.review .inner {
  padding: 100px;
  position: relative;
  z-index: 10;
  padding-top: 15.18vh; /* 126px / 698px * 100 = 18.05vh */
  padding-left: 20.97vw; /* 302px / 1440px * 100 = 20.97vw */
  padding-right: 48.06vw; /* 692px / 1440px * 100 = 48.06vw */
  padding-bottom: 74.79vh; /* 522px / 698px * 100 = 74.79vh */
}

.review-title {
  position: absolute;
  top: 126px;
}

.review p {
  font-size: 30px;
}

.review h3 {
  color: #000;
  font-size: 34px;
  font-weight: 700;
}

.review .control {
  position: absolute;
  top: 210px;
  margin-left: 50vw;
}

.review .control svg {
  cursor: pointer;
  z-index: 10;
}

.review-slide {
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 250px;
}

.review-container {
  position: relative;
  display: flex;
  width: calc((358px + 20px) * 14);
  margin: 90px;
  transition: transform 0.5s ease; /* 전환 효과 추가 */
}

.review-card {
  flex: 0 0 358px;
  height: 314px;
  border-radius: 20px;
  background: #fff;
  margin-right: 20px; /* 카드 간의 간격 조정 */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* 세로로 배치 */
  transition: transform 0.5s ease;
  padding: 40px; /* 내부 여백 추가 */
  box-sizing: border-box;
}

.review-card h4 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 20px;
}

.review-card p {
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 400;
  height: 160px;
}

.review-card div {
  color: #999999;
  font-size: 14px;
  font-weight: 700;
}

.represent-video {
  width: 100%;
  height: 904px;
  position: relative;
  background: #49495A;
  justify-content: center;
  margin: 0 auto;
}

.represent-video__inner {
  position: relative;
  width: 1115px;
  margin: 0 auto;
}

.re-title {
  margin: 170px 0 10px 0;
}

.represent-video .re-title h2 {
  color: #FFF;
  font-size: 42px;
  font-weight: 600;
}

.represent-video .re-title p {
  background: linear-gradient(90deg, #76E6FF 0%, #6A70FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 42px;
  font-weight: 600;
  line-height: 50px;
}

.represent-video .btn-box {
  margin-top: 10px;
}

.represent-video .title-btn {
  width: 208px;
  height: 56px;
  border-radius: 10px;
  background: #6F6F7E;
  color: #CCC;
  font-size: 16px;
  font-weight: 400;
  margin-right: 15px;
  margin-bottom: 10px;
}

.represent-video .title-btn.active {
  background: #fff;
  color: #1A1A1A;
}

.represent-video .title-btn img {
  height: 24px;
  width: 24px;
  margin: 5px;
}

.represent-video .slider {
  margin: 10px 0 10px;
  border-radius: 10px;
  background: #FFF;
  width: 1115px;
  height: 352px;
}

.represent-video__inner .left,
.represent-video__inner .right {
  cursor: pointer;
}

.represent-video__inner .left {
  position: absolute;
  top: 330px;
  left: -90px;
}

.represent-video__inner .right {
  position: absolute;
  top: 330px;
  right: -90px;
}

.represent-video .slider__area {
  display: flex;
}

.represent-video__video-wrap {
  position: relative;
  width: 625px;
  height: 352px;
  overflow: hidden;
  border-radius: 10px 0 0 10px;
}

.represent-video__video {
  position: absolute;
  top: 0;
  left: 0;
}

.represent-video__info {
  flex: 1 1 0;
  padding: 30px;
}

.represent-video__tag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 126px;
  height: 28px;
  border-radius: 5px;
  font-size: 12px;
  color: #fff;
  background: #0041D6;
}

.represent-video__tag i {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: url("../assets/icons/check_blue.svg") #FFFFFFCC no-repeat center center;
}

.represent-video__title {
  display: block;
  margin: 18px 0 63px;
  font-size: 20px;
  font-weight: 600;
}

.represent-video__info-box {
  width: 100%;
  height: 150px;
  padding: 30px 20px 20px;
  border-radius: 10px;
  background: #0041D614;
}

.represent-video__dl {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.represent-video__dl + .represent-video__dl {
  margin-top: 18px;
}

.represent-video__dl dt {
  font-size: 16px;
  font-weight: 500;
  color: #808080;
  line-height: 1.2;
}

.represent-video__dl dd {
  margin: 0;
  line-height: 1;
}

.represent-video__dl:nth-child(1) dd {
  font-size: 16px;
  font-weight: 500;
  color: #1A1A1A;
}

.represent-video__dl:nth-child(2) dd {
  font-size: 24px;
  font-weight: 500;
  color: #000;
}

.represent-video__dl:nth-child(3) dd {
  font-size: 24px;
  font-weight: 600;
  color: #0041D6;
}

.flow {
  width: 100%;
  height: 676px;
  background: #363642;
}

.flow__title {
  font-size: 34px;
  margin: 115px auto 70px;
  text-align: center;
  font-weight: 700;
  color: #fff;
}

.flow__title--point {
  position: relative;
}

.flow__title--point::after {
  content: '';
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #fff;
}

.flow__content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.flow__step {
  width: 228px;
  height: 272px;
  padding: 20px;
  border-radius: 15px;
  background: #575766;
}

.flow__num {
  display: block;
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  color: #C6C6D5;
}

.flow__img {
  display: block;
  width: 55px;
  height: 55px;
  margin: 25px auto 30px;
}

.flow__step-title {
  display: block;
  margin-bottom: 17px;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: #fff;
}

.flow__step-desc {
  text-align: center;
  font-size: 16px;
  line-height: 1.3;
  font-weight: 500;
  color: #CCCCCC;
}

.estimate {
  width: 100%;
  height: 700px;
  background: #0041D60D;
}

.estimate__title {
  width: 1130px;
  margin: 193px auto 45px;
  font-size: 34px;
  font-weight: 600;
  color: #1A1A1A;
}

.estimate__content {
  display: flex;
  flex-wrap: wrap;
  gap: 27px 23px;
  width: 1130px;
  margin: 0 auto;
}

.estimate__item {
  display: flex;
  align-items: center;
  width: 264px;
  height:90px;
  padding: 28px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 4px 0 #00000040;
  text-decoration: none;
  transition: transform 0.4s;
}

.estimate__item img {
  width: 28px;
  height: 28px;
  margin-right: 14px;
}

.estimate__item-title {
  font-size: 18px;
  font-weight: 600;
  color: #1A1A1A;
}

.estimate__item:hover {
  transform: translateY(-4px);
  transition: transform 0.4s;
}

.estimate__item:hover .estimate__item-arrow {
  opacity: 1;
}

.estimate__item-arrow {
  opacity: 0;
  transition: opacity 0.2s;
  width: 20px;
  height: 20px;
  margin-left: auto;
  background: url("../assets/svgs/mainpage/right_arrow.svg") no-repeat center center;
}

.portfolio {
  padding: 0 32px 110px;
}

.portfolio__title {
  margin: 160px 0 10px;
  font-size: 34px;
  font-weight: 600;
  color: #1A1A1A;
}

.portfolio__more {
  display: block;
  width: 66px;
  height: 32px;
  margin: 0 0 35px auto;
  border-radius: 5px;
  text-align: center;
  line-height: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #808080;
  background: #D9D9D9;
}

.portfolio__row {
  display: flex;
  gap: 24px;
  width: 100%;
}

.portfolio__row + .portfolio__row {
  margin-top: 35px;
}

.portfolio__item {
  flex: 1;
  max-width: 24%;
}

.portfolio__img {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.portfolio__img-wrap {
  height: 56.25%;
  padding-top: 56.25%;
}

.portfolio__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.portfolio__img:hover img {
  transform: scale(1.2);
  transition: transform 0.3s;
}

.portfolio__pick-btn {
  position: absolute;
  top: 11px;
  right: 13px;
  width: 18px;
  height: 18px;
  background: url("../assets/svgs/mainpage/heart_white.svg") no-repeat center center;
}

.portfolio__video-title {
  display: block;
  margin: 6px 12px 8px;
  font-size: 15px;
  font-weight: 600;
  color: #1A1A1A;
}

.portfolio__tag-area {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 0 12px 7px;
  margin-bottom: 4px;
  margin-right: 15px;
  border-bottom: 1px solid #D9D9D9;
}

.portfolio__tag {
  width: 60px;
  height: 24px;
  border: 1px solid #808080;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  line-height: 24px;
  font-weight: 300;
  color: #808080;
  cursor: pointer;
}

.portfolio__tag:hover {
  background: #eee;
}

.portfolio__company {
  padding-left: 12px;
  font-size: 12px;
  font-weight: 400;
  color: #4C4949;
}

.banner {
  padding: 80px 0;
}

.banner__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1216px;
  height: 200px;
  margin: 0 auto;
  padding: 0 80px 0 60px;
  border-radius: 10px;
  background: linear-gradient(60deg, rgba(30,95,245,0.7) 0%, rgba(0,65,214,0.7) 48%, rgba(0,65,214,1) 48%, rgba(0,65,214,1) 100%);
}

.banner__title {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  line-height: 1.3;
}

.banner__title--point {
  color: #67FDD0;
}

.banner__link {
  width: 242px;
  height: 64px;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  color: #000;
  font-weight: 600;
  line-height: 64px;
  background: #67FDD0;
  transition: all 0.2s;
}

.banner__link:hover {
  background: #5DE6BD;
  transition: all 0.2s;
}

.faq {
  width: 100%;
  background: #0041D60D;
  padding: 94px 0 137px;
}

.faq__title {
  margin-bottom: 47px;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  color: #000;
}

.faq__title--point {
  margin-left: 8px;
  color: #0041D6;
}

.faq-list {
  width: 1046px;
  margin: 0 auto;
  padding-left: 12px;
  border-top: 1px solid #C8C8C8;
}

.faq-item {
  border-bottom: 1px solid #C8C8C8;
}

.faq-item__title-area {
  display: flex;
  align-items: center;
  padding: 25px 0;
}

.faq-item__abbr {
  margin-right: 15px;
  font-size: 24px;
  font-weight: 600;
  color: #ccc;
}

.faq-item__title {
  padding-right: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #4D4D4D;
}

.faq-item__title-btn {
  width: 24px;
  height: 24px;
  margin-left: auto;
  padding: 10px;
  background: url("../assets/svgs/mainpage/arrow_down_black.svg") no-repeat center / cover;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.faq-item__title-btn.open {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.faq-item__content-area {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
}

.faq-item__content-area.open {
  max-height: 500px;
  transition: max-height 0.5s;
}

.faq-item__content {
  padding: 0 0 26px 34px;
  font-size: 16px;
  font-weight: 400;
  color: #4D4D4D;
}

.consult {
  width: 100%;
  height: 498px;
  padding-top: 124px;
  background: #363642;
}

.consult__title {
  margin-bottom: 47px;
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  color: #fff;
}

.consult__content {
  text-align: center;
  font-size: 18px;
  color: #B3B3B3;
}

.consult__content--point {
  position: relative;
  color: #49F4FF;
}

.consult__content--point::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #49F4FF;
}

a {
  text-decoration: none;
}

ul, li, h3 {
  margin: 0;
  padding: 0;
}

/* 미디어 쿼리 추가 */
@media (max-width: 1400px) {
  .choose .choose-title {
    margin-top: 0;
    padding-top: 200px; /* 원하는 고정 위치 설정 */
  }

  .logo-grid {
    padding: 0 10%; /* 상대적인 패딩으로 변경 */
  }
}

@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}


</style>
