<template>
  <div class="welcome">
    <div ref="videoContainer" class="video-container">
      <img v-if="!videoLoaded" src="@/assets/imgs/placeholder.png" class="video-placeholder" />
      <video
        ref="videoPlayer"
        class="video-player"
        autoplay
        loop
        muted
        playsinline
        preload="auto"
        @loadeddata="onVideoLoaded"
        @click="preventClick"
      >
        <source src="@/assets/video/estimate-welcome.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="inner">
      <button class="exitBtn" @click="exitWelcome">나가기</button>
      <div class="context">
        <h5>상상만 하던 영상을 현실로</h5>
        <p>
          제작을 원하시는 영상에 대해 알려주세요 <br />
          1:1 맞춤형 매칭을 통해 해당 견적에 대해 상세히 알려드립니다
        </p>

        <div class="wrapper">
          <div class="box">
            <img src="@/assets/imgs/estimate0.png" />
            <div class="text">
              <span>견적에 대한 문의는 무료입니다</span><br />
              <p>
                평균 3일 이내에 문의하신 내용에 대해 견적서와 제안서,
                포트폴리오를 받아볼 수 있습니다.
              </p>
            </div>
          </div>
          <div class="box">
            <img src="@/assets/imgs/estimate1.png" />
            <div class="text">
              <span>유선 전화와 홈페이지를 통해 문의 내용이 전달됩니다</span
              ><br />
              <p>
                입력해주신 이메일을 통해 문의에 대한 답을 전달해 드리고
                있습니다.
              </p>
            </div>
          </div>
        </div>

        <button class="startBtn" @click="goStep">시작하기
          <RightArrow />
        </button>
        <p
          style="
            color: #8c8c8c;
            font-size: 15px;
            text-align: center;
            margin-top: 13px;
          "
        >
          평균 2분 소요
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import RightArrow from "@/assets/svgs/RightArrow.vue";

export default {
  name : "Estimate",
  components:{
    RightArrow,
  },
  setup() {
    const videoContainer = ref(null);
    const videoPlayer = ref(null);
    const videoLoaded = ref(false);
    const router = useRouter();

    const initializeVideo = () => {
      const video = videoPlayer.value;
      if (video) {
        video.addEventListener("ended", () => {
          video.play();
        });
      }
    };

    const preventClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const onVideoLoaded = () => {
      videoLoaded.value = true;
    };

    onMounted(() => {
      const video = videoPlayer.value;
      if (video) {
        video.addEventListener('loadeddata', onVideoLoaded);
        initializeVideo();
      }
    });

    const exitWelcome = () => {
      router.push("/");
    };

    const goStep = () => {
      router.push("/estimate/step1");
    };

    return {
      videoContainer,
      videoPlayer,
      preventClick,
      exitWelcome,
      goStep,
      videoLoaded
    };
  },
};
</script>

<style scoped>
.welcome {
  position: relative;
}
.video-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 58vw; /* 전체 화면의 58% */
  height: 100vh; /* 전체 화면 높이 */
  overflow: hidden;
}

.video-placeholder {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-player {
  width: 100%; /* 비디오의 너비를 부모 요소의 100%로 설정 */
  height: 100%; /* 비디오의 높이를 부모 요소의 100%로 설정 */
  object-fit: cover; /* 영상이 전체 영역에 꽉 차도록 설정 */
}

.inner {
  position: absolute;
  left: calc(58vw + 20px); /* 비디오 너비 + 여백 크기만큼 오른쪽으로 이동 */
  top: 0;
  width: calc(42vw - 20px); /* 비디오 영역을 제외한 남은 공간 */
  height: 100vh; /* 전체 화면 높이 */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 469px; /* 최소 너비 설정 */
  min-height: 100px; /* 최소 높이 설정 */
}

.context {
  position: relative;
  text-align: left; /* 왼쪽 정렬 */
  padding: 30px;
}

.context h5 {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.context p {
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
}
.wrapper {
  background: rgba(141, 191, 235, 0.23);
  border-radius: 10px;
  margin-top: 50px;
}

.box {
  display: flex;
  align-items: center;
  width: 100%; /* 부모 요소의 너비에 맞게 설정 */
  height: auto; /* 내용물에 맞게 설정 */
  max-width: 424px; /* 최대 너비 설정 */
  max-height: 312px; /* 최대 높이 설정 */
  padding: 20px;
}
.box img {
  width: 65px;
  height: 65px;
  flex-shrink: 0;
  margin-right: 15px;
  margin-bottom: 20px;
}
.box .text {
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}

.box .text span {
  color: #000;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
}

.box .text p {
  color: #8c8c8c;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
}
.startBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 424px;
  height: 64px;
  border-radius: 10px;
  background: var(--vdcolor);
  border: none;
  color: white;
  margin-top: 50px;
}
.startBtn svg {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}
.exitBtn {
  position: absolute;
  top: 20px; /* 원하는 위치로 조정 */
  right: 20px; /* 원하는 위치로 조정 */
  width: 100px; /* 너비 조정 */
  height: 40px; /* 높이 조정 */
  border: none;
  background-color: var(--vdcolor); /* 나가기 버튼의 배경색 설정 */
  color: white; /* 나가기 버튼의 텍스트 색상 설정 */
  border-radius: 5px; /* 버튼의 테두리를 둥글게 만들기 */
  cursor: pointer; /* 커서를 포인터로 변경하여 클릭 가능하다는 표시 */
}
.exitBtn {
    position: absolute;
    top: 20px; /* 원하는 위치로 조정 */
    right: 20px; /* 원하는 위치로 조정 */
    width: 100px; /* 너비 조정 */
    height: 40px; /* 높이 조정 */
    border: none;
    background-color: var(--vdcolor); /* 나가기 버튼의 배경색 설정 */
    color: white; /* 나가기 버튼의 텍스트 색상 설정 */
    border-radius: 5px; /* 버튼의 테두리를 둥글게 만들기 */
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none; /* 링크의 기본 밑줄 제거 */
    pointer-events: auto; /* 포인터 이벤트 허용 */
    z-index: 1;
}
</style>