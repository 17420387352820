<template>
    <div class="box">
        <div class="profile">
            <img src="@/assets/imgs/user.png" alt="User Image">
            <div class="user-info"> 
                <h3>{{ user ? user.username : '로그인된 유저가 없습니다.' }}</h3>
                <p>일반 회원</p>
            </div>
        </div>
        <div class="links">
            <router-link to="#">찜한 내역</router-link>
            <a href="https://checkered-octopus-ab0.notion.site/VD-6b162c85915749e28c33c135139f88d6?pvs=4">이용 안내</a>
            <p @click="logout" class="logout-link">로그아웃</p>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: "Modal",
    setup() {
        const router = useRouter();
        const store = useStore();
        const user = computed(() => store.state.user);

        const logout = async () => {
            await store.dispatch('logout');
            router.push('/'); // 로그아웃 후 홈 페이지로 리디렉션
        };

        return {
            logout,
            user
        };
    }
};
</script>

<style scoped>
.box {
    width: 200px; /* 넓이를 약간 더 늘림 */
    border-radius: 10px;
    background-color: rgba(110, 110, 110, 0.8);
    position: absolute;
    top: 70px; /* 원하는 위치로 조정 가능 */
    right: 25px; /* 원하는 위치로 조정 가능 */
    z-index: 1000; /* 다른 요소 위에 표시되도록 z-index 설정 */
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 15px; /* 내부 패딩 추가 */
}

.profile {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* 프로필과 링크들 사이에 간격 추가 */
}

.profile img {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    margin-right: 10px;
}

.user-info {
    display: flex;
    flex-direction: column;
    justify-content: left;
    color: white;
}

.user-info h3{
    margin: 0;
    font-size: 20px;
    font-weight: 500;
} 
.user-info p {
    margin: 0;
    color: #C9C9C9;
    font-size: 15px;
    font-weight: 500;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    color: #FFF;
    font-size: 17px;
    font-weight: 500;
}

.links a, .logout-link {
    color: white;
    text-decoration: none;
    margin: 5px 0; /* 링크들 사이에 간격 추가 */
    cursor: pointer;
}
.logout-link:hover{
    color: black;
}

</style>
