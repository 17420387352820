<template>
    <AdminHeader />
    <div class="container">
        <div class="portfolio-form">
            <div class="title">포트폴리오 영상 등록하기</div>
            <form @submit.prevent="submitForm">
                <!-- Thumbnail Image Upload -->
                <div class="box">
                    <label>영상 썸네일 이미지 등록:</label>
                    <div class="format">
                        <label class="file-upload-button">
                            <input type="file" @change="handleThumbnailUpload" />
                            <img v-if="thumbnailUrl" :src="thumbnailUrl" alt="thumbnail" class="thumbnail-preview"/>
                            <span v-else><img alt="썸네일 이미지 등록" src="@/assets/imgs/download.png"></span>
                        </label>
                    </div>
                </div>
                <div v-if="errors.thumbnail" class="error">{{ errors.thumbnail }}</div>
                
                <!-- Vimeo Link -->
                <div class="box">
                    <label>비메오 링크:</label>
                    <input class="block" type="text" v-model="vimeo_link" />
                </div>
                <div v-if="errors.vimeo_link" class="error">{{ errors.vimeo_link }}</div>

                <!-- Video Title -->
                <div class="box">
                    <label>영상 제목:</label>
                    <input class="block" type="text" v-model="video_title" maxlength="30" />
                </div>
                <div v-if="errors.video_title" class="error">{{ errors.video_title }}</div>

                <!-- Category -->
                <div class="box">
                    <label>카테고리:</label>
                    <div v-for="cat in categories" :key="cat">
                        <input type="radio" :value="cat" v-model="category" /> {{ cat }}
                    </div>
                </div>
                <div v-if="errors.category" class="error">{{ errors.category }}</div>

                <!-- Tags -->
                <div class="box2">
                    <label>태그:</label>
                    <div class="tags-container">
                        <div class="tag" v-for="(t, index) in tags" :key="t">
                            <input type="checkbox" :id="'tag-' + index" :value="t" @change="handleTags($event, t)" />
                            <label :for="'tag-' + index">{{ t }}</label>
                        </div>
                    </div>
                </div>
                <div v-if="errors.tags" class="error">{{ errors.tags }}</div>

                <!-- Video Length -->
                <div class="box">
                    <label>영상 길이:</label>
                    <div class="time-input">
                        <input class="time" type="number" placeholder="00" v-model.number="hours" @input="validateHours"/> <span>시간</span>
                        <input class="time" type="number" placeholder="00" v-model.number="minutes" @input="validateMinutes"/> <span>분</span>
                        <input class="time" type="number" placeholder="00" v-model.number="seconds" @input="validateSeconds"/> <span>초</span>
                    </div>
                    <!-- <input class="block" type="text" v-model="video_length" placeholder="ex) 3분 20초"/> -->
                </div>
                <div v-if="errors.video_length" class="error">{{ errors.video_length }}</div>

                <!-- Production Date -->
                <div class="box">
                    <label>제작 일자:</label>
                    <input class="day" type="date" v-model="production_date" @change="updateProductionDate" />
                    <input class="day" type="text" v-model="formattedProductionDate" @input="updateProductionDate" />
                </div>
                <div v-if="errors.production_date" class="error">{{ errors.production_date }}</div>

                <!-- Production Information -->
                <div class="box">
                    <label>제작 정보:</label>
                    <input class="block" type="text" v-model="production_information" placeholder="ex) 구성 + 촬영 + 소스 편집 + 사운드"/>
                </div>
                <div v-if="errors.production_information" class="error">{{ errors.production_information }}</div>

                <!-- Production Period -->
                <div class="box">
                    <label>제작 기간:</label>
                    <input class="block" type="text" v-model="production_period" />
                </div>
                <div v-if="errors.production_period" class="error">{{ errors.production_period }}</div>

                <!-- Introduction Content -->
                <div class="box">
                    <label>영상 소개 내용:</label>
                    <textarea class="block" style="height: 178px;" v-model="introduction_content"></textarea>
                </div>
                <div v-if="errors.introduction_content" class="error">{{ errors.introduction_content }}</div>

                <div class="box">
                    <label>영상 제작 과정:</label>

                    <svg xmlns="http://www.w3.org/2000/svg" width="639" height="12" viewBox="0 0 639 12" fill="none">
                        <path d="M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6ZM6 7H212.5V5H6V7Z" fill="#C8C8C8"/>
                        <path d="M212.5 6C212.5 8.94552 214.888 11.3333 217.833 11.3333C220.778 11.3333 223.167 8.94552 223.167 6C223.167 3.05448 220.778 0.666667 217.833 0.666667C214.888 0.666667 212.5 3.05448 212.5 6ZM217.833 7H421.333V5H217.833V7Z" fill="#C8C8C8"/>
                        <path d="M424.333 6C424.333 8.94552 426.721 11.3333 429.667 11.3333C432.612 11.3333 435 8.94552 435 6C435 3.05448 432.612 0.666667 429.667 0.666667C426.721 0.666667 424.333 3.05448 424.333 6ZM429.667 7H636.167V5H429.667V7Z" fill="#C8C8C8" transform="translate(-5, 0)"/>
                    </svg>
                </div>
                <div class="box special">
                    <!-- Procedure 1 -->
                    <div>
                        <label>기획</label>
                        <div class="choose" v-for="(p, index) in procedures1" :key="index">
                            {{ p }} <input type="checkbox" :value="index" v-model="procedure_1" /> 
                        </div>
                    </div>
                    
                    <!-- Procedure 2 -->
                    <div>
                        <label>촬영/디자인</label>
                        <div class="choose" v-for="(p, index) in procedures2" :key="index">
                            {{ p }} <input type="checkbox" :value="index" v-model="procedure_2" /> 
                        </div>
                    </div>
                    
                    <!-- Procedure 3 -->
                    <div>
                        <label>편집</label>
                        <div class="choose" v-for="(p, index) in procedures3" :key="index">
                            {{ p }} <input type="checkbox" :value="index" v-model="procedure_3" /> 
                        </div>
                    </div>
                </div>
                

                <!-- Client Information -->
                <div class="box">
                    <label>클라이언트:</label>
                    <input class="block" type="text" v-model="client" placeholder="ex) 트루빈스"/>
                </div>
                <div v-if="errors.client" class="error">{{ errors.client }}</div>

                <div class="box">
                    <label>기업 정보:</label>
                    <input class="block" type="text" v-model="client_corporate_information" placeholder="ex) 트루빈스/vd studio"/>
                </div>
                <div v-if="errors.client_corporate_information" class="error">{{ errors.client_corporate_information }}</div>

                <div class="box">
                    <label>주요 사업:</label>
                    <input class="block" type="text" v-model="client_major_bussiness" placeholder="ex) 영상 제작"/>
                </div>
                <div v-if="errors.client_major_bussiness" class="error">{{ errors.client_major_bussiness }}</div>

                <div class="box">
                    <label>클라이언트 간략 정보:</label>
                    <textarea style="height: 88px;" class="block" v-model="client_about" placeholder="ex) 영상제작 회사입니다."></textarea>
                </div>
                <div v-if="errors.client_about" class="error">{{ errors.client_about }}</div>

                <button type="submit">저장</button>
            </form>
        </div>  
    </div>
    <p class="exit" @click="exit">
    <img alt="화살표" src="@/assets/imgs/left-arrow.png">나가기</p>
</template>

<script>
import { ref, watch, provide } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import AdminHeader from './AdminHeader.vue';

export default {
    name : "VideoRegister",
    components : {
        AdminHeader
    },
    setup() {
        const router = useRouter();

        const thumbnailUrl = ref(null);
        const previousThumbnailKey = ref(null);
        const loading = ref(false);

        const vimeo_link = ref('');
        const video_title = ref('');
        const category = ref('');
        const selectedTags = ref([]);
        const video_length = ref('');
        const hours = ref(0);
        const minutes = ref(0);
        const seconds = ref(0);
        const production_date = ref('');
        const formattedProductionDate = ref('');
        const production_information = ref('');
        const production_period = ref('');
        const introduction_content = ref('');
        const procedure_1 = ref([]);
        const procedure_2 = ref([]);
        const procedure_3 = ref([]);
        const client = ref('');
        const client_corporate_information = ref('');
        const client_major_bussiness = ref('');
        const client_about = ref('');

        const categories = ['교육', '홍보', '광고/바이럴', '유튜브', '기타'];
        const tags = [
        '2D 모션 그래픽', '3D 모션 그래픽', '인포그래픽', '실사', 'VR', '인터뷰',
        '라이브', '드라마타이즈', '스톡', '웹예능', '스케치'
        ];
        const procedures1 = ['아이디어제안', '장면 구성', '배우 섭외', '모델 섭외', '성우 섭외', '장소 섭외'];
        const procedures2 = ['현장 촬영', '드론 촬영', '캐릭터/그래픽 디자인'];
        const procedures3 = ['종합 편집', '모션그래픽', '3D/특수효과'];

        const errors = ref({});

        const handleTags = (event, tag) => {
            if (event.target.checked) {
                if (selectedTags.value.length < 3) {
                    selectedTags.value.push(tag);
                } else {
                    event.target.checked = false;
                }
            } else {
                selectedTags.value = selectedTags.value.filter(t => t !== tag);
            }
        };

        const handleThumbnailUpload = async (event) => {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('file', file);

            loading.value = true;

            if (previousThumbnailKey.value) {
                try {
                    await axios.post('/api/admin/video/delete-thumbnail', { key: previousThumbnailKey.value });
                } catch (error) {
                    console.error('이전에 올리려 했던 썸네일 이미지를 삭제하다 오류가 발생했습니다. :', error);
                }
            }

            try {
                const response = await axios.post('/api/admin/video/upload-thumbnail', formData, {
                        headers: {
                        'Content-Type': 'multipart/form-data',
                        },
                    });
                    thumbnailUrl.value = response.data.url;
                    previousThumbnailKey.value = response.data.key;
            } catch (error) {
                console.error('Thumbnail upload error:', error);
                alert('이미지 업로드 중 오류가 발생했습니다.');
            } finally {
                loading.value = false;
            }
        };

        // 영상 길이
        const validateHours = () => {
            if(hours.value < 0){
                hours.value = 0;
            }
            if(hours.value > 24){
                hours.value = 24;
            }
        };
        const validateMinutes = () => {
            if(minutes.value < 0){
                minutes.value = 0;
            }
            if(minutes.value >=60){
                minutes.value = 59;
            }
        };
        const validateSeconds = () => {
            if(seconds.value < 0){
                seconds.value = 0;
            }
            if(seconds.value >=60){
                seconds.value = 59;
            }
        };

        watch([hours, minutes, seconds], () =>{
            const hh = String(hours.value).padStart(2, '0');
            const mm = String(minutes.value).padStart(2, '0');
            const ss = String(seconds.value).padStart(2, '0');

            if(hours.value === 0 || hours.value === null){
                video_length.value = `${mm}:${ss}`;
            }else{
                video_length.value = `${hh}:${mm}:${ss}`;
            }
        });


        const updateProductionDate = () => {
            const date = new Date(production_date.value);
            const dayNames = ['일', '월', '화', '수', '목', '금', '토'];
            formattedProductionDate.value = `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')} (${dayNames[date.getDay()]})`;
        };

        const validateForm = () => {
            errors.value = {};

            if (!thumbnailUrl.value) {
                errors.value.thumbnail = "썸네일 이미지가 등록되지 않았습니다.";
            }
            if (!vimeo_link.value) {
                errors.value.vimeo_link = "비메오 링크가 입력되지 않았습니다.";
            }
            if (!video_title.value) {
                errors.value.video_title = "영상 제목이 입력되지 않았습니다.";
            }
            if (!category.value) {
                errors.value.category = "카테고리가 선택되지 않았습니다.";
            }
            if (selectedTags.value.length === 0) {
                errors.value.tags = "태그가 선택되지 않았습니다.";
            }
            if (!video_length.value || (seconds.value === 0 && minutes.value === 0)) {
                errors.value.video_length = "영상 길이가 입력되지 않았습니다.";
            }
            if (!production_date.value) {
                errors.value.production_date = "제작 일자가 입력되지 않았습니다.";
            }
            if (!production_information.value) {
                errors.value.production_information = "제작 정보가 입력되지 않았습니다.";
            }
            if (!production_period.value) {
                errors.value.production_period = "제작 기간이 입력되지 않았습니다.";
            }
            if (!introduction_content.value) {
                errors.value.introduction_content = "영상 소개 내용이 입력되지 않았습니다.";
            }
            if (!client.value) {
                errors.value.client = "클라이언트 회사명이 입력되지 않았습니다.";
            }
            if (!client_corporate_information.value) {
                errors.value.client_corporate_information = "기업 정보가 입력되지 않았습니다.";
            }
            if (!client_major_bussiness.value) {
                errors.value.client_major_bussiness = "주요 사업이 입력되지 않았습니다.";
            }
            if (!client_about.value) {
                errors.value.client_about = "클라이언트에대한 간략 정보가 입력되지 않았습니다.";
            }

            return Object.keys(errors.value).length === 0;
        };

        const submitForm = async () => {
            if (!validateForm()) {
                return;
            }

            const video = {
                thumbnail: thumbnailUrl.value,
                vimeo_link: vimeo_link.value,
                video_title: video_title.value,
                category: category.value,
                tag: selectedTags.value,
                video_length: video_length.value,
                production_date: formattedProductionDate.value,
                production_information: production_information.value,
                production_period: production_period.value,
                introduction_content: introduction_content.value,
                procedure_1: procedure_1.value,
                procedure_2: procedure_2.value,
                procedure_3: procedure_3.value,
                client: client.value,
                client_corporate_information: client_corporate_information.value,
                client_major_bussiness: client_major_bussiness.value,
                client_about: client_about.value
            };

            try {
                const response = await axios.post('/api/admin/video/register-portfolio', video);
                alert(response.data.message);
                router.push('/admin/videomanage');
            } catch (error) {
                alert('서버 에러: ' + error.response.data.error);
                router.push('/admin/videomanage/register');
            }
        };

        // 헤더의 나가기에도 적용할 수 있도록 provide 사용
        // provide로 상태 제공
        provide('previousThumbnailKey', previousThumbnailKey);

        const exit = async() =>{
            if(previousThumbnailKey.value){
                try{
                    await axios.post('/api/admin/video/delete-thumbnail', { key : previousThumbnailKey.value });
                }catch(error){
                    console.error('올리려 했던 썸네일 이미지를 삭제하다 오류가 발생했습니다. :', error);
                }
            }
            router.push('/admin/videomanage');
        };

        return {
            thumbnailUrl,
            vimeo_link,
            video_title,
            category,
            selectedTags,
            hours,
            minutes,
            seconds,
            validateHours,
            validateMinutes,
            validateSeconds,
            video_length,
            production_date,
            formattedProductionDate,
            production_information,
            production_period,
            introduction_content,
            procedure_1,
            procedure_2,
            procedure_3,
            client,
            client_corporate_information,
            client_major_bussiness,
            client_about,
            categories,
            tags,
            procedures1,
            procedures2,
            procedures3,
            handleTags,
            handleThumbnailUpload,
            updateProductionDate,
            submitForm,
            errors,
            exit
        };
    }
};
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
    padding-left: 210px;
}
.title {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin-top: 60px;
}

label {
    font-size: 17px;
    font-weight: 400;
    margin-right: 20px;
    width: 170px;
}

.portfolio-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.box {
    display: flex;
    align-items: flex-start; /* 왼쪽 정렬 */
    gap: 1rem;
    margin: 30px 0;
}
.upload-label {
    white-space: nowrap;
}

.thumbnail-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.file-upload-button {
    position: relative;
    display: inline-block;
    width: 480px;
    height: 270px;
    cursor: pointer;
    background: #F2F2F2;
    border-radius: 4px;
    text-align: center;
    line-height: 270px;
    overflow: hidden;
}

.file-upload-button input[type="file"] {
    display: none;
}

.file-upload-button span {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 270px;
    pointer-events: none;
}
.file-upload-button span img{
    width: 30px;
    height: 30px;
}
button {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background: var(--vdcolor);
    color: white;
    border: none;
    width: 257px;
    height: 49px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    margin: 75px 216px 38px 645px;
}

button:hover {
    background-color: var(--vdhovercolor);
}
.block{
    width: 685px;
    height: 37px;
    border-radius: 5px;
    border: 1px solid #000;
    padding: 10px;
}
.time-input{
    display: flex;
    align-items: center;
}
.time{
    width: 75px;
    height: 37px;
    border-radius: 5px;
    border : 1px solid black;
    padding: 10px;
    margin-right: 5px;
}
.time-input span{
    margin-right: 10px;
}
.day{
    width: 280px;
    height: 37px;
    border-radius: 5px;
    border: 1px solid #000;
    padding: 10px;
    text-align: center;
}
.choose{
    margin: 10px 5px;
}
.choose input{
    margin-left: 6px;
    border: 1px solid #000;
    width: 15px;
    height: 15px;
}
.tag input{
    margin-right: 6px;
    border: 1px solid #000;
    width: 15px;
    height: 15px;
}

.box2 {
    display: flex;
    align-items: center; /* 가운데 정렬 */
    gap: 1rem;
    margin: 30px 0;
}

.box2 label {
    flex-shrink: 0; /* 레이블의 너비 고정 */
    width: 170px; /* 다른 요소들과 동일한 너비 */
}

.tags-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* 조정된 너비 */
    gap: 5px; /* 태그 사이의 간격 */
    width: 70%;
}

.tags-container .tag {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.tags-container .tag input[type="checkbox"] {
    margin-right: 5px;
}


.line-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* 선 사이의 간격 조정 */
}
.box svg{
    height: fit-content;
    margin-top: 8px;
}
.special{
    margin-left: 203px;
}
.special label{
    font-weight: 600;
    font-size: 17px;
}
.error{
    color: red;
    font-size: 14px;
    display: flex;
    margin-left: 203px;
}
.exit{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    display: flex;
    margin-left: 65px;
    margin-bottom: 35px;
}
.exit img{
    width: 25px;
    height: 25px;
    margin: 14px;
}

/* 고정된 레이아웃 */
@media (max-width: 991px) {
    .portfolio-form {
        width: 991px; /* 고정된 너비 */
    }
    
    .box {
        width: 991px; /* 고정된 너비 */
    }

    .file-upload-button {
        width: 480px; /* 고정된 너비 */
        height: 270px; /* 고정된 높이 */
    }

    .thumbnail-preview {
        width: 480px; /* 고정된 너비 */
        height: 270px; /* 고정된 높이 */
    }
}

</style>