<template>
  <div class="inner">
    <div class="box">
      <h2 class="title">문의 현황</h2>
      <div class="inquire">
        <div class="inquire-item">
          <div class="inquire-item__title-area">
            <img class="inquire-item__img" src="../../assets/svgs/admin/step1.svg" alt="img">
            <strong class="inquire-item__title">미확인</strong>
          </div>
          <a class="inquire__link" href="#">5건</a>
        </div>
        <div class="inquire-item">
          <div class="inquire-item__title-area">
            <img class="inquire-item__img" src="../../assets/svgs/admin/step2.svg" alt="img">
            <strong class="inquire-item__title">견적 전송 완료</strong>
          </div>
          <a class="inquire__link" href="#">5건</a>
        </div>
        <div class="inquire-item">
          <div class="inquire-item__title-area">
            <img class="inquire-item__img" src="../../assets/svgs/admin/step3.svg" alt="img">
            <strong class="inquire-item__title">미팅 진행</strong>
          </div>
          <a class="inquire__link" href="#">5건</a>
        </div>
        <div class="inquire-item">
          <div class="inquire-item__title-area">
            <img class="inquire-item__img" src="../../assets/svgs/admin/step4.svg" alt="img">
            <strong class="inquire-item__title">계약 완료</strong>
          </div>
          <a class="inquire__link" href="#">5건</a>
        </div>
      </div>
    </div>
    <div class="box flex second">
      <div class="left visitor">
        <h2 class="title">방문자 현황</h2>
        <div class="visitor__content">
          <Chart
              :size="{ width: 350, height: 280 }"
              :data="data"
              :margin="margin"
              :direction="direction"
              :axis="axis"
          >

            <template #layers>
              <Grid strokeDasharray="2,2" />
              <Line :dataKeys="['name', 'pl']" :lineStyle="{ stroke: '#7F00FF'}" />
              <Line :dataKeys="['name', 'avg']" :lineStyle="{ stroke: '#00B25C' }" ></Line>
            </template>

          </Chart>
        </div>
      </div>
      <div class="right summary">
        <h2 class="title">일자별 요약</h2>
        <table class="summary-table">
          <caption class="hide">일자별 요약 표</caption>
          <thead>
          <tr>
            <th>일자</th>
            <th>문의 수</th>
            <th>미팅 완료 수</th>
            <th>계약 완료 수</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>2024-06-12</td>
            <td>99999</td>
            <td>99999</td>
            <td>99999</td>
          </tr>
          <tr>
            <td>2024-06-12</td>
            <td>99999</td>
            <td>99999</td>
            <td>99999</td>
          </tr>
          <tr>
            <td>2024-06-12</td>
            <td>99999</td>
            <td>99999</td>
            <td>99999</td>
          </tr>
          <tr>
            <td>2024-06-12</td>
            <td>99999</td>
            <td>99999</td>
            <td>99999</td>
          </tr>
          <tr>
            <td>2024-06-12</td>
            <td>99999</td>
            <td>99999</td>
            <td>99999</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="box">
      <h2 class="title">포트폴리오</h2>
      <div class="portfolio__box">
        <table class="portfolio__table">
          <caption class="hide">포트폴리오 표</caption>
          <thead>
          <tr>
            <th>목록</th>
            <th>포트폴리오 제목</th>
            <th>등록일</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="portfolio__index">01.</td>
            <td class="portfolio__project">
              <div class="portfolio__project-area">
                <div class="portfolio__project-img"></div>
                <span class="portfolio__project-name">프로젝트 명</span>
              </div>
            </td>
            <td class="portfolio__date">2024-06-12</td>
          </tr>
          <tr>
            <td class="portfolio__index">02.</td>
            <td class="portfolio__project">
              <div class="portfolio__project-area">
                <div class="portfolio__project-img"></div>
                <span class="portfolio__project-name">프로젝트 명</span>
              </div>
            </td>
            <td class="portfolio__date">2024-06-12</td>
          </tr>
          <tr>
            <td class="portfolio__index">03.</td>
            <td class="portfolio__project">
              <div class="portfolio__project-area">
                <div class="portfolio__project-img"></div>
                <span class="portfolio__project-name">프로젝트 명</span>
              </div>
            </td>
            <td class="portfolio__date">2024-06-12</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Chart, Grid, Line } from 'vue3-charts'

const data = ref([
  { name: '04-29', pl: 340, avg: 50 },
  { name: '04-30', pl: 250, avg: 30 },
  { name: '05-01', pl: 340, avg: 70 },
  { name: '05-02', pl: 280, avg: 130 },
  { name: '05-03', pl: 340, avg: 120 },
])
const direction = ref('horizontal')
const margin = ref({
  left: 0,
  top: 20,
  right: 20,
  bottom: 0
})

const axis = ref({
  primary: {
    type: 'band',
    format: (val) => {
      return val
    }
  },
  secondary: {
    domain: ['dataMin', 'dataMax + 100'],
    type: 'linear',
    ticks: 8
  }
})
</script>

<style scoped>
.inner {
  box-sizing: border-box;
  margin: 40px auto;
  padding: 30px 0;
}
.box {
  width: fit-content;
  margin: 0 auto;
}
.title {
  margin-bottom: 35px;
  color: #000;
  font-size: 28px;
  font-weight: 600;
}
.inquire {
  display: flex;
  gap: 40px;
}
.inquire-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  height: 260px;
  padding-top: 64px;
  margin-bottom: 70px;
  border-radius: 20px;
  background: #0041D61A;
}
.inquire-item__title-area {
  display: flex;
  align-items: center;
  gap: 10px;
}
.inquire-item__img {
  width: 50px;
  height: 50px;
}
.inquire-item__title {
  font-size: 23px;
  font-weight: 600;
  color: #000;
}
.inquire__link {
  margin-top: 36px;
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-decoration: none;
}
.box.flex {
  display: flex;
  justify-content: space-between;
  width: 1080px;
}
.box.second {
  margin-bottom: 110px;
}
.summary {
  padding-right: 20px;
}
.summary-table {
  width: 100%;
  min-width: 520px;
  border: 1px solid #000;
}
.summary-table th {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #D9D9D9;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  color: #808080;
}
.summary-table td {
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: #000;
}
.summary-table tr {
  border-bottom: 1px solid #D9D9D9;
}
.summary-table tr:last-child {
  border-bottom: 0;
}
.portfolio__box {
  width: fit-content;
  padding: 40px;
  border-radius: 20px;
  background: #0041D61A;
}
.portfolio__table {
  width: 1000px;
  max-width: 100%;
}
.portfolio__table th {
  text-align: center;
  padding: 10px;
  border-bottom: 2px solid #000;
  font-size: 20px;
  font-weight: 400;
  color: #000;
}
.portfolio__table td {
  padding: 12px;
  font-size: 20px;
  font-weight: 400;
  color: #000;
  vertical-align: middle;
}
.portfolio__index {
  text-align: center;
}
.portfolio__project {
  text-align: center;
}
.portfolio__project-area {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 46px;
}
.portfolio__project-img {
  width: 150px;
  height: 70px;
  background: #D9D9D9;
}
.portfolio__date {
  text-align: center;
}
.hide {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  line-height: 0;
  text-indent: -9999px;
}
@media (min-width: 1200px) {
  .inner {
    margin: 0 5vw;
  }
  .box.flex {
    gap: 50px; /* Adjusted gap for larger screens */
  }
}
</style>
