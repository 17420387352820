<template>
    <AdminHeader />
    <div class="container">
        <div class="portfolio-form">
            <div class="title">포트폴리오 영상 세부 내용</div>
            <!-- Thumbnail Image Upload -->
            <div class="box">
                <label>영상 썸네일 이미지:</label>
                <div class="format">
                    <label class="file-upload-button">
                        <img :src="thumbnailUrl" alt="thumbnail" class="thumbnail-preview"/>
                    </label>
                </div>
            </div>

            <!-- Vimeo Link -->
            <div class="box">
                <label>비메오 링크:</label>
                <div class="block">{{ vimeo_link }}</div>
            </div>
            
            <!-- Video Title -->
            <div class="box">
                <label>영상 제목:</label>
                <div class="block">{{ video_title }}</div>
            </div>

            <!-- Category -->
            <div class="box">
                <label>카테고리:</label>
                <div class="block">{{ category }}</div>
            </div>

            <!-- Tags -->
            <div class="box">
                <label>태그:</label>
                <div class="tag" v-for="t in selectedTags" :key="t">
                    <div class="deco">{{ t }}</div>
                </div>
            </div>

            <!-- Video Length -->
            <div class="box">
                <label>영상 길이:</label>
                <div class="block">{{ video_length }}</div>
            </div>

            <!-- Production Date -->
            <div class="box">
                <label>제작 일자:</label>
                <div class="block">{{ production_date }}</div>
            </div>

            <!-- Production Information -->
            <div class="box">
                <label>제작 정보:</label>
                <div class="block">{{ production_information }}</div>
            </div>

            <!-- Production Period -->
            <div class="box">
                <label>제작 기간:</label>
                <div class="block">{{ production_period }}</div>
            </div>

            <!-- Introduction Content -->
            <div class="box">
                <label>영상 소개 내용:</label>
                <div class="block">{{ introduction_content }}</div>
            </div>

            <div class="box">
                <label>영상 제작 과정:</label>
                <svg xmlns="http://www.w3.org/2000/svg" width="639" height="12" viewBox="0 0 639 12" fill="none">
                    <path d="M0.666667 6C0.666667 8.94552 3.05448 11.3333 6 11.3333C8.94552 11.3333 11.3333 8.94552 11.3333 6C11.3333 3.05448 8.94552 0.666667 6 0.666667C3.05448 0.666667 0.666667 3.05448 0.666667 6ZM6 7H212.5V5H6V7Z" fill="#C8C8C8"/>
                    <path d="M212.5 6C212.5 8.94552 214.888 11.3333 217.833 11.3333C220.778 11.3333 223.167 8.94552 223.167 6C223.167 3.05448 220.778 0.666667 217.833 0.666667C214.888 0.666667 212.5 3.05448 212.5 6ZM217.833 7H421.333V5H217.833V7Z" fill="#C8C8C8"/>
                    <path d="M424.333 6C424.333 8.94552 426.721 11.3333 429.667 11.3333C432.612 11.3333 435 8.94552 435 6C435 3.05448 432.612 0.666667 429.667 0.666667C426.721 0.666667 424.333 3.05448 424.333 6ZM429.667 7H636.167V5H429.667V7Z" fill="#C8C8C8" transform="translate(-5, 0)"/>
                </svg>
            </div>
            <div class="box special">
                <!-- Procedure 1 -->
                <div>
                    <label>기획</label>
                    <div v-for="(item, index) in procedure1Labels" :key="index">
                        <span v-if="procedure_1.includes(index)">{{ item }}</span>
                    </div>
                </div>
                <!-- Procedure 2 -->
                <div>
                    <label>촬영/디자인</label>
                    <div v-for="(item, index) in procedure2Labels" :key="index">
                        <span v-if="procedure_2.includes(index)">{{ item }}</span>
                    </div>
                </div>
                <!-- Procedure 3 -->
                <div>
                    <label>편집</label>
                    <div v-for="(item, index) in procedure3Labels" :key="index">
                        <span v-if="procedure_3.includes(index)">{{ item }}</span>
                    </div>
                </div>
            </div>

            <!-- Client Information -->
            <div class="box">
                <label>클라이언트:</label>
                <div class="block">{{ client }}</div>
            </div>
            
            <div class="box">
                <label>기업 정보:</label>
                <div class="block">{{ client_corporate_information }}</div>
            </div>
            
            <div class="box">
                <label>주요 사업:</label>
                <div class="block">{{ client_major_bussiness }}</div>
            </div>
            
            <div class="box">
                <label>클라이언트 간략 정보:</label>
                <div class="block">{{ client_about }}</div>
            </div>

        </div>
        <div class="edit-delete">
            <router-link :to="`/admin/videomanage/edit/${video_id}`" class="edit">수정</router-link>
            <button @click="deleteVideo" class="delete">삭제</button>
        </div>
    </div>
    <p class="exit" @click="exit">
    <img alt="화살표" src="@/assets/imgs/left-arrow.png">나가기</p>
</template>

<script>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
import AdminHeader from './AdminHeader.vue';

export default {
    name : "VideoShow",
    components : {
        AdminHeader
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const video_id = route.params.video_id;

        const thumbnailUrl = ref(null);
        const vimeo_link = ref('');
        const video_title = ref('');
        const category = ref('');
        const selectedTags = ref([]);
        const video_length = ref('');
        const production_date = ref('');
        const formattedProductionDate = ref('');
        const production_information = ref('');
        const production_period = ref('');
        const introduction_content = ref('');
        const procedure_1 = ref([]);
        const procedure_2 = ref([]);
        const procedure_3 = ref([]);
        const client = ref('');
        const client_corporate_information = ref('');
        const client_major_bussiness = ref('');
        const client_about = ref('');

        const procedure1Labels = ['아이디어 제안', '장면 구성', '배우 섭외', '모델 섭외', '성우 섭외', '장소 섭외'];
        const procedure2Labels = ['현장 촬영', '드론 촬영', '캐릭터/그래픽 디자인', '3D 모델링'];
        const procedure3Labels = ['종합 편집', '모션그래픽', '3D/특수효과'];

        onMounted(async () => {
            try {
                const { data } = await axios.get(`/api/admin/video/show-portfolio/${video_id}`);
                thumbnailUrl.value = data.thumbnail;
                vimeo_link.value = data.vimeo_link;
                video_title.value = data.video_title;
                category.value = data.category;
                selectedTags.value = data.tag;
                video_length.value = data.video_length;
                production_date.value = data.production_date;
                formattedProductionDate.value = data.production_date;
                production_information.value = data.production_information;
                production_period.value = data.production_period;
                introduction_content.value = data.introduction_content;
                procedure_1.value = data.procedure_1;
                procedure_2.value = data.procedure_2;
                procedure_3.value = data.procedure_3;
                client.value = data.client;
                client_corporate_information.value = data.client_corporate_information;
                client_major_bussiness.value = data.client_major_bussiness;
                client_about.value = data.client_about;
            } catch (error) {
                console.error('영상에 대한 데이터를 가지고 오지 못했습니다. :', error);
                router.push('/admin/videomanage');
            }
        });
        
        const exit = () =>{
            router.push('/admin/videomanage');
        };

        const deleteVideo = async () => {
            try {
                await axios.delete(`/api/admin/video/delete-portfolio/${video_id}?thumbnailUrl=${encodeURIComponent(thumbnailUrl.value)}`);
                alert('비디오가 성공적으로 삭제되었습니다.');
                router.push('/admin/videomanage');
            } catch (error) {
                console.error('비디오 삭제 중 오류가 발생했습니다:', error);
                alert('비디오 삭제 중 오류가 발생했습니다.');
            }
        };

        return {
            thumbnailUrl,
            vimeo_link,
            video_title,
            category,
            selectedTags,
            video_length,
            production_date,
            formattedProductionDate,
            production_information,
            production_period,
            introduction_content,
            procedure_1,
            procedure_2,
            procedure_3,
            procedure1Labels,
            procedure2Labels,
            procedure3Labels,
            client,
            client_corporate_information,
            client_major_bussiness,
            client_about,
            deleteVideo,
            video_id,
            exit
        };
    }
};
</script>

<style scoped>
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
}
.title {
    color: #000;
    font-size: 24px;
    font-weight: 600;
    margin-top: 60px;
}

label {
    font-size: 17px;
    font-weight: 600;
    margin-right: 20px;
    width: 170px;
}

.portfolio-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.box {
    display: flex;
    align-items: flex-start; /* 왼쪽 정렬 */
    gap: 1rem;
    margin: 30px 0;
}
.upload-label {
    white-space: nowrap;
}

.thumbnail-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.file-upload-button {
    position: relative;
    display: inline-block;
    width: 480px;
    height: 270px;
    background: #F2F2F2;
    border-radius: 4px;
    text-align: center;
    line-height: 270px;
    overflow: hidden;
}

.deco{
    text-decoration: underline;
    text-underline-position: under;
}
.block{
    width: 685px;
    height: 37px;
    border-radius: 5px;
    padding: 10px;
    border-bottom: 1px solid black;
}
.time-input{
    display: flex;
    align-items: center;
}
.time{
    width: 75px;
    height: 37px;
    border-radius: 5px;
    border : 1px solid black;
    padding: 10px;
    margin-right: 5px;
}
.time-input span{
    margin-right: 10px;
}
.day{
    width: 280px;
    height: 37px;
    border-radius: 5px;
    border: 1px solid #000;
    padding: 10px;
    text-align: center;
}
.choose{
    margin: 10px 5px;
}
.choose input{
    margin-left: 6px;
    border: 1px solid #000;
    width: 15px;
    height: 15px;
}
.tag input{
    margin-right: 6px;
    border: 1px solid #000;
    width: 15px;
    height: 15px;
}
.tags-container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
}
.line-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* 선 사이의 간격 조정 */
}
.box svg{
    height: fit-content;
    margin-top: 8px;
    margin-bottom: 0;
}
.special{
    margin-left: 203px;
    margin-top: 0;
}
.special label{
    font-weight: 600;
    font-size: 17px;
}
.error{
    color: red;
    font-size: 14px;
    display: flex;
    margin-left: 203px;
}
.exit{
    color: #000;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    align-items: center;
    display: flex;
    margin-left: 65px;
    margin-bottom: 35px;
}
.exit img{
    width: 25px;
    height: 25px;
    margin: 14px;
}
.edit-delete{
    display: flex;
    justify-content: flex-end; /* 버튼을 중앙에 정렬 */
    align-items: center; /* 버튼을 수직으로 중앙에 정렬 */
    width: 70%;
    gap: 10px; /* 버튼 사이의 간격 */
    text-align: center;
    margin-top: 20px;
}
.edit{
    padding: 10px 40px ;
    border-radius: 5px;
    background: var(--vdcolor);
    color: white;
    border: none;
    text-decoration: none;
    font-weight: 600;
    font-size: 17px;
}
.delete {
    width: 110px;
    height: 46px;
    border-radius: 5px;
    background: red;
    color: white;
    border: none;
    text-decoration: none;
    font-weight: 600;
    font-size: 17px;
}


/* 고정된 레이아웃 */
@media (max-width: 991px) {
    .portfolio-form {
        width: 991px; /* 고정된 너비 */
    }
    
    .box {
        width: 991px; /* 고정된 너비 */
    }

    .file-upload-button {
        width: 480px; /* 고정된 너비 */
        height: 270px; /* 고정된 높이 */
    }

    .thumbnail-preview {
        width: 480px; /* 고정된 너비 */
        height: 270px; /* 고정된 높이 */
    }
}

</style>