<template>
    <header>
        <nav class="navbar navbar-expand-lg bgcolor">
            <div class="container-fluid">
                <router-link class="navbar-brand logo" to="/">VD studio</router-link>

                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link class="nav-link menu" :class = "{ 'active' : $route.path === '/estimate'}" to="/estimate">견적받기</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link menu" :class = "{ 'active' : $route.path === '/portfolio'}" to="/portfolio">포트폴리오</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link menu" :class = "{ 'active' : $route.path === '/blog'}" to="/blog">블로그</router-link>
                        </li>
                    </ul>

                    <span class="navbar-text" v-if="!isAuthenticated">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link menu" href="https://checkered-octopus-ab0.notion.site/VD-6b162c85915749e28c33c135139f88d6?pvs=4">VD에 대해서</a>
                            </li>
                            <li class="nav-item">
                                <router-link class="nav-link menu" :class = "{ 'active' : $route.path === '/login'}" to="/login">로그인</router-link>
                            </li>
                        </ul>
                    </span>

                    <span class="navbar-text" v-else>
                        <li class="nav-item" v-if="user.role == 'admin'">
                            <router-link class="go" to="/admin">관리자 페이지 이동</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="my-project-button" :to="`/${userId}/project-list`">내 프로젝트</router-link>
                        </li>
                        <li class="nav-item">
                            <p class="m-0 p-0 interval"> | </p>
                        </li>
                        <li class="nav-item">
                            <button class="search-button">
                                <Search />
                            </button>
                        </li>
                        <li class="nav-item">
                            <UserImg @click="showModal"/>
                            <Modal v-if="show == true"/>
                        </li>
                    </span>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import Search from '@/assets/svgs/Search.vue';
import UserImg from '@/assets/svgs/UserImg.vue';
import Modal from './Modal.vue';

export default {
    name: 'Header',
    components :{
        Search,
        UserImg,
        Modal,
    },
    setup() {
        const store = useStore();
        const show = ref(false);

        const isAuthenticated = computed(() => store.getters.isAuthenticated);
        const user = computed(() => store.getters.user);

        const userId = ref('');
        onMounted(()=>{
            userId.value = user.value ? user.value._id : '';
        })

        const showModal = () => {
            show.value = !show.value;
        };

        return {
            isAuthenticated,
            user,
            userId,
            show,
            showModal,
        };
    },
};
</script>

<style scoped>
.bgcolor{
    background-color: black;
}

.logo {
    font-weight: 900;
    color: white !important;
    font-size: 24px;
}
.menu,
.menu:after,
.menu::before{
    transition: all 0.3s;
}

.menu{
    color: #B3B3B3;
    font-size: 18px;
    font-weight: 600;
    position: relative;
}
.menu:hover{
    color: white;
}
.menu:hover::after{
    width: 100%;
}
.menu::after{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #B3B3B3;
    height: 1px;
}
.menu.active{
    color: white !important;
}

@media (max-width: 991px) {
    .navbar-toggler {
        background-color: #B3B3B3;
    }
    .navbar-text {
    display: none !important; /* 991px 이하일 때 숨기기 */
    }
}
.my-project-button {
    padding: 5px 25px;
    border-radius: 20px;
    border: 1px solid #fff;
    color: #fff;
    font-size: 18px;
    font-weight: 200;
    text-decoration: none;
    transition: background-color 0.3s ease;
}
.my-project-button:hover {
    background-color: rgba(229, 229, 229, 0.4);
    color: white;
}
.my-project-button:active,
.my-project-button:focus,
.my-project-button.router-link-exact-active {
  color: white; /* 클릭 및 포커스 상태에서도 글씨색을 하얀색으로 유지 */
}
.interval {
    color: white;
    margin: 0 20px; /* 양쪽에 간격 추가 */
}
.search-button {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}
.navbar-text {
    display: flex;
    align-items: center; /* 수직 정렬 */
}
.nav-item {
    list-style: none; /* 기본 li 스타일 제거 */
    margin-right: 20px; /* 각 li 사이에 간격 추가 */
}
.go{
    color: #FFF;
    text-decoration: none;
}
.go:hover{
    color: #FFF;
}
</style>