<template>
    <div class="inner">
        <div class="left">
            <h3>VD studio</h3>
            <router-link to="/admin" class="home">관리자 페이지</router-link>
        </div>
        <div class="box">
            <button class="exit" @click="exit">나가기</button>
        </div>
    </div>
</template>

<script setup>
    import { inject } from 'vue';
    import { useRouter } from 'vue-router';
    import axios from 'axios';

    const router = useRouter();
    const previousThumbnailKey = inject('previousThumbnailKey');

    const exit = async()=>{
        if(previousThumbnailKey && previousThumbnailKey.value){
            try {
                    await axios.post('/api/admin/video/delete-thumbnail', { key: previousThumbnailKey.value });
            } catch (error) {
                    console.error('올리려 했던 썸네일 이미지를 삭제하다 오류가 발생했습니다. :', error);
            }
        }
        router.push('/');
    };
</script>

<style scoped>
.inner {
    background-color: black;
    height: 68px;
    width: 100vw;
    display: flex;
    justify-content: space-between; /* 요소를 양 끝으로 배치 */
    align-items: center; /* 요소를 수직 가운데로 정렬 */
    padding: 0 50px;
    box-sizing: border-box;
}

.left {
    display: flex;
    align-items: center; /* 요소를 수직 가운데로 정렬 */
}

.left h3 {
    color: #FFF;
    font-weight: 800;
    margin: 0; /* 여백 제거 */
}

.home {
    color: #FFF;
    font-size: 17px;
    font-weight: 500;
    padding-left: 20px;
    text-decoration: none;
}

.box {
    display: flex;
    align-items: center; /* 수직 가운데 정렬 */
    justify-content: center; /* 수평 가운데 정렬 */
    border: 1px solid white;
    border-radius: 5px;
    padding: 5px 10px;
    width: 156px;
    height: 36px;
    text-align: center;
}

.exit {
    text-decoration: none;
    color: #FFF;
    font-size: 16px;
}

</style>
